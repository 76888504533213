import { createApp } from 'vue'
import App from './App.vue'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import VueGeolocation from "vue3-geolocation";
import VueGtag from "vue-gtag";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

let app = null;
//Vue.config.productionTip = false;

//createApp(App).mount('#app')
import { FirebaseAuth } from "@/firebase";
FirebaseAuth.onAuthStateChanged(() => { // Mount app only after firebase auth initialized
    if (!app) {
        app = createApp(App);

        app.component('EasyDataTable', Vue3EasyDataTable);

        app.use(mdiVue, {
                icons: mdijs
            }).use(router).use(Notifications).use(VueGeolocation).use(VueGoogleMaps, {
                load: {
                    key: 'AIzaSyDPOTuP9waQYCayI0TJ30Bk94an6DSJHTU',
                    libraries: "places",
                    language: 'it'
                },
            })
            .use(VueGtag, {
                config: { id: "G-PPLDN4FZQP" }
            }).mount('#app');
    }
});