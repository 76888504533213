<template>
    <div class="container-fluid categories-main-full">
        <div class="container categories-main">
            <h2>Cerchi qualcos'altro?</h2>
            <ul class="categories-list">
               <li v-for="c in categoriesList" v-bind:key="c.slug">
                  <router-link :to="'/categories/' + c.slug">#{{c.categoryName}}</router-link>
              </li>
            </ul>
        </div>
    </div>
</template>

<script>

import {FirebaseDatabase} from "@/firebase";

export default {
  name: 'CategoriesPage',
  data() {
    return{
      categoriesList: [],
    }
  },
  components: {
  },
  methods: {
    getCategories(){
        var categoriesRef = FirebaseDatabase.ref("/categories");

        this.categoriesList.length = 0;

        categoriesRef
        .orderByChild("isVisible")
        .equalTo(true)
        .on('value' , snapshot => {
            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
              snapshot.forEach(category => {
                  this.categoriesList.push(category.val());
              });
            }

            this.categoriesList = this.shuffle(this.categoriesList);
        });
    },
    shuffle(array) {
      const newArray = [...array]
      const length = newArray.length

      for (let start = 0; start < length; start++) {
        const randomPosition = Math.floor((newArray.length - start) * Math.random())
        const randomItem = newArray.splice(randomPosition, 1)

        newArray.push(...randomItem)
      }

      return newArray
    }
  },
  created: function(){
    this.getCategories();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .categories-main-full{
      padding: 20px 0px;
      background-color: #f6edd9;
    }
    .categories-main h2{
      font-size: 40px;
      font-weight: 700;
      text-align: left;
    }

    .categories-list{
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    .categories-list li{
      border-radius: 5px;
      background-color: #f9fafa;
      display: inline-flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;
      padding: 5px 10px;
      margin-right: 16px;
      margin-top: 16px;
    }
    .categories-list li a{
      font-size: 28px;
      font-weight: 600;
      text-decoration: none;
      color: #000;
    }
</style>
