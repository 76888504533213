<template>
    <div class="ps-card search">
         <h4>Inserisci il tuo indirizzo per trovare allevamenti nei dintorni</h4>
         <div class="row">
            <div class="col-12 col-md-5 position-div">
                <GMapAutocomplete id="addressInput" class="form-control form-control-lg" 
                                  placeholder="Inserisci il tuo indirizzo es. Via Rossi, 14"
                                  @place_changed="setPlace" @change="placeOnInputDigit" 
                                  ref="autocompleteInputRef" :value="searchAddress"
                                  :options="{
                                    types: ['geocode'],
                                    componentRestrictions: {country: 'it'}
                                  }">
                </GMapAutocomplete>
                <button class="calculate-position-btn" type="button" @click="getCurrentPosition">
                  <mdicon name="crosshairs" class="calculate-position"/>
                </button>
                <div id="validationAddress" class="invalid-feedback">
                   Inserire un indirizzo valido.
                </div>
            </div>
            <div class="col-12 col-md-2 animal-type-div">
                <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchAnimalType"
                        @change="animalTypeChanged">
                      <option selected>Tipologia</option>
                      <option value="Cane">Cane</option>
                      <option value="Gatto">Gatto</option>
                      <option value="Entrambi">Entrambi</option>              
                </select>
            </div>       
            <div class="col-12 col-md-3 animal-type-div">
                <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchAnimalRace">
                    <option selected>Razza</option>
                    <option v-for="(r,index) in raceList" v-bind:key="index">{{ r.raceName }}</option>            
                </select>
            </div>       
            <div class="col-12 col-md-1 search-btn-div">
                <button class="btn" type="button" @click="doSearch">
                    Cerca
                </button>
            </div>                        
         </div> 
         <p>Non sai di cosa ci occupiamo?<span @click="goToMoreInfoSection" class="link-span"> Scopri di più</span>.</p>
    </div>
</template>

<script>

import {FirebaseDatabase} from "@/firebase";

import VueCookies from 'vue-cookies';

export default {
  
  name: 'SearchBusinessesNearYouView',
  data() {
    return{
        searchAddress: null,
        searchAnimalType: "Tipologia",
        searchAnimalRace: "Razza",
        lat: null,
        lng: null,
        gmapsApiKey: "AIzaSyDPOTuP9waQYCayI0TJ30Bk94an6DSJHTU",
        raceList: []
    }
  },
  components: {
  },
  methods: {
    getCurrentPosition(){
      this.$getLocation()
      .then((coordinates) => {
        //console.log(coordinates);
        this.lat = coordinates.lat;
        this.lng = coordinates.lng;
        this.reverseGeocodeCurrentAddress(coordinates.lat,coordinates.lng);           
      })
      .catch((error) => {
        console.log(error);
      });  
      this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');     
    },
    reverseGeocodeCurrentAddress(lat,lng){
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng='+ lat +','+ lng +'&key=' + this.gmapsApiKey)
        .then(response => response.json())
        .then(data => (
            this.searchAddress = data.results[0].formatted_address
        ));        
    },
    goToMoreInfoSection(){
        const element = document.getElementById('mission');
        element.scrollIntoView({ behavior: 'smooth' });
    },
    goToWorkWithUs(){
        const element = document.getElementById('workWithUs');
        element.scrollIntoView({ behavior: 'smooth' });
    },
    placeOnInputDigit(){
        this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');
        this.searchAddress = this.$refs.autocompleteInputRef.$refs.input.value;
    },
    setPlace(){
        this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');
        this.searchAddress = this.$refs.autocompleteInputRef.$refs.input.value;

        if(this.searchAddress != ""){
            fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+ this.searchAddress +'&key=' + this.gmapsApiKey)
            .then(response => response.json())
            .then(data => (
                this.lat = data.results[0].geometry.location.lat,
                this.lng = data.results[0].geometry.location.lng
            ));
        }           
    },
    doSearch(){
      if(this.searchAddress != null && this.searchAddress != ""){
        this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');
        VueCookies.set('srch_address_lat' , this.lat, "1d");
        VueCookies.set('srch_address_lng' , this.lng, "1d");
        VueCookies.set('srch_address_name' , this.searchAddress, "1d");
        VueCookies.set('srch_animal_type' , this.searchAnimalType, "1d");
        VueCookies.set('srch_animal_race' , this.searchAnimalRace, "1d");

        var searchToken = Math.random().toString(16).slice(2);
        this.$router.replace('/search/' + searchToken);
      }else{
        this.$refs.autocompleteInputRef.$el.classList.add('is-invalid');
      }
    },
    animalTypeChanged(){
        var animalRacesRef = FirebaseDatabase.ref("/animalRaces");
        this.raceList.length = 0;

        animalRacesRef
        .orderByChild("animalType")
        .equalTo(this.searchAnimalType)
        .on('value' , snapshot => {
          if(snapshot.hasChildren() && snapshot.numChildren() > 0){
              snapshot.forEach(race => {
                  this.raceList.push(race.val());              
              });
              this.raceList.sort(function (a, b) {
                                    if (a.raceName < b.raceName) {
                                        return -1;
                                    }
                                    if (a.raceName > b.raceName) {
                                        return 1;
                                    }
                                    return 0;
                                    });
          }     
        });
    }
  },
  created: function(){
    
  },
  computed:{
    
  }
}
</script>

<style>
    .ps-card.search {
        background-color: #fff;
        border-radius: 4px;
        z-index: 102;
        -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
        box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
        width: 1000px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        max-height: 180px; /*270px on mobile*/
        height: 100%;
    }
    .ps-card.search h4{
        text-align: left;
    }
    .ps-card.search p{
        text-align: left;
        margin-top: 10px;
    }
    .ps-card.search .link-span{
      cursor: pointer;
      color: #b85b2d;
      text-decoration: none;
    }
    .ps-card.search .btn{
      height: 48px;
    }
    .ps-card.search .calculate-position-btn{
        bottom: 24px;
    }
    .ps-card.search .form-control{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 48px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px;
      border: 1px solid #e8ebeb;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      -webkit-transition-property: -webkit-box-shadow;
      transition-property: -webkit-box-shadow;
      transition-property: box-shadow;
      transition-property: box-shadow,-webkit-box-shadow;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .ps-card.search .form-control.is-invalid{
      position: relative;
      border: 2px solid #ff483b;
      padding: 11px 39px 11px 15px;
      caret-color: #ff483b;
      -webkit-transition-property: -webkit-box-shadow;
      transition-property: -webkit-box-shadow;
      transition-property: box-shadow;
      transition-property: box-shadow,-webkit-box-shadow;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .invalid-feedback{
      color: #ff483b;
      font-weight: 400;
    }
    .ps-card.search .form-control.is-invalid + .calculate-position-btn{
      bottom: 34px
    }
    .ps-card.search .form-control,.ps-card.search .form-select{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 48px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px;
      border: 1px solid #e8ebeb;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      -webkit-transition-property: -webkit-box-shadow;
      transition-property: -webkit-box-shadow;
      transition-property: box-shadow;
      transition-property: box-shadow,-webkit-box-shadow;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    /*MAPS DROPDOWN STYLE*/
    .pac-container{
        padding: 0px;
        background-color: #fff;
    }
    .pac-item {
        min-height: 48px;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: none;
        border: none;
        border-radius: 3px;
        padding: 8px 16px;
        text-align: left;
        -webkit-transition-property: -webkit-box-shadow;
        transition-property: -webkit-box-shadow;
        transition-property: box-shadow,-webkit-box-shadow;
        -webkit-transition-duration: .15s;
        -webkit-transition-timing-function: ease-out;
        transition-property: box-shadow;
        transition-duration: .15s;
        transition-timing-function: ease-out;
        position: relative;
    }
    .pac-item:hover{
        background-color: #f9fafa;
    }
    .pac-icon {
        background: url('https://cdn-icons-png.flaticon.com/24/5100/5100596.png') no-repeat center;
        background-size: 20px;
        margin: 5px 6px 6px 0;
        vertical-align: middle;
    }
    .pac-logo::after{
        min-height: 48px;
        margin: 0px 10px;
        border-top: 1px solid #f1f1f1;
    }

    .pac-item-query {
        font-size: 14px;
        line-height: 19px;
    }
    .pac-matched{
        font-size: 12px;
        line-height: 16px;
    }

    /* Extra small devices (phones, 480px and down) */
    @media only screen and (max-width: 480px) {
        .ps-card.search{
            min-height: 380px;
            width: auto;
            position: initial;
        }

        .animal-type-div{
            margin-top: 10px;
        }

        .ps-card.search .btn{
            margin-top: 10px;
            width: 100%;
        }

        .ps-card.search .calculate-position-btn{
            bottom: 8px;
        }
    }

    @media only screen and (max-width: 767px) {

        .animal-type-div{
            margin-top: 10px;
        }

        .ps-card.search .btn{
            margin-top: 10px;
            width: 100%;
        }
    }


    @media only screen and (max-width: 768px) {
        .ps-card.search{
            min-height: 405px;
            width: auto;
            top: 0;
        }

        .ps-card.search .calculate-position-btn{
            bottom: 8px;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .ps-card.search{
            min-height: 180px;
            width: auto;
            bottom: 250px;
        }

        .ps-card.search .calculate-position-btn{
            bottom: 8px;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .ps-card.search{
            min-height: 180px;
            width: 1000px;
            bottom: 0;
            top: 0;
        }

        .ps-card.search .calculate-position-btn{
            bottom: 8px;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {}
</style>