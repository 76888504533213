<template>
        <div class="row mt-3">
            <div class="col-12 col-md-12">
                <input type="text" class="form-control" name="email" placeholder="Email" v-model="email" 
                :class="[isEmailValid == null ? inputDefaultClass : (isEmailValid ? invalidInputClass : validInputClass)]" 
                @focusout="validateEmail(email)" @change="validateEmail(email)">
                <div class="invalid-feedback">
                    Inserire un indirizzo email valido.
                </div>
            </div>
            <div class="col-12 col-md-12 mt-3 password-div">
                <input :type="passwordType" class="form-control" name="password" placeholder="Password" v-model="password"
                :class="[isPasswordValid == null ? inputDefaultClass : (isPasswordValid ? invalidInputClass : validInputClass)]"
                @input="validatePassword(password)" >
                <mdicon name="eye" class="showPassword" v-if="!isPasswordVisible" @click="switchPasswordVisibility()"/>
                <mdicon name="eye-off" class="showPassword" v-else @click="switchPasswordVisibility()"/>
                <div class="invalid-feedback">
                    Inserire la password.
                </div>
            </div>
            <router-link to="/ripristino-password-richiesta" class="passwordForget noselect">Hai dimenticato la password?</router-link>
            <div class="col-12 col-md-12 mt-3 mb-3">
                <button type="button" class="btn btn-primary" @click="doLoginWithCredentials"
                :disabled="((isEmailValid == null || isEmailValid == false) || (isPasswordValid == null || isPasswordValid == false))">Accedi</button>
            </div>                        
        </div>
    <span>o</span>
    <br/>
    <span>accedi con</span>
    <br/>
    <br/>
    <div class="login-social-buttons">
            <div class="method">
                <div class="method-control google">
                    <a href="#" class="method-action">
                        <img src="../../assets/g-logo.png" alt="Google-logo" width="18">
                        <span class="login-google-span">Accedi con Google</span>
                    </a>
                </div>
                <div class="method-control facebook">
                    <a href="#" class="method-action">
                        <mdicon name="facebook" class="ion ion-logo-facebook" />
                        <span>Accedi con Facebook</span>
                    </a>
                </div>
                <div class="method-control apple">
                    <a href="#" class="method-action">
                        <mdicon name="apple" class="ion ion-logo-apple" />
                        <span>Accedi con Apple</span>
                    </a>
                </div>
            </div>
    </div>
    <p>Non hai un account? <router-link to="/registrati" class="passwordForget">Iscriviti</router-link></p>
</template>

<script>

import {FirebaseAuth} from "@/firebase";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: 'AccountLoginPage',
  components: {
  },
  data() {
    return{
        passwordType: "password",
        isPasswordVisible: false,
        email: "",
		password: "",
        notificationTitle: "",
		notificationMessage: "",
        isEmailValid: null,
        isPasswordValid: null,
        inputDefaultClass: 'form-control',
        validInputClass: 'form-control is-invalid',
        invalidInputClass: 'form-control is-valid'
    }
  },
  created: function() {
    let suggestedEmail = this.email;
    this.validateEmail(suggestedEmail);
    this.validatePassword(this.password);
  },
  methods: {
    switchPasswordVisibility(){
        this.isPasswordVisible = !this.isPasswordVisible;
        if(this.isPasswordVisible){
            this.passwordType = "text";
        }else{
            this.passwordType = "password";
        }
    },
    validateEmail(emailToCheck) {
        var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
        

        if (reg.test(emailToCheck)) {
          this.isEmailValid = true;
        } else {
          this.isEmailValid = false;
        }
    },
    validatePassword(passwordToCheck) {
        var reg = /^[a-zA-Z0-9!@#$&()-`.+,/\"]{8,}$/; //eslint-disable-line

        if (reg.test(passwordToCheck)) {
          this.isPasswordValid = true;
        } else {
          this.isPasswordValid = false;
        }
    },
    doLoginWithCredentials: function () {
			let _this = this;
			FirebaseAuth.signInWithEmailAndPassword(this.email, this.password).then(() => {
                ////if(FirebaseAuth.currentUser.emailVerified){ //SIMONE COMMENTATO MOMENTANEAMENTE
                    this.password = "";
                    _this.notificationTitle = "Successo";
                    _this.notificationMessage = "Operazione avvenuta con successo!";

                    notify({
                        type: 'success',
                        title: _this.notificationTitle,
                        text: _this.notificationMessage,
                    });

                    this.$router.replace('/dashboard'); // User logged
                /*}else{
                    _this.notificationTitle = "Qualcosa è andato storto";
                    _this.notificationMessage = "L'email non è stata ancora verificata!";

                    notify({
                        type: 'error',
                        title: _this.notificationTitle,
                        text: _this.notificationMessage,
                    });
                }*/ //SIMONE COMMENTATO MOMENTANEAMENTE
			}).catch((error) => { //error
                if(error.code === 'auth/too-many-requests'){
                    _this.notificationTitle = "Qualcosa è andato storto";
                    _this.notificationMessage = "Sono stati eseguiti troppi tentativi di accesso per questo account, ed è stato disabilitato! " +
                    "Contattare l'amministratore di sistema per riabilitarlo.";
                }else{
                    _this.notificationTitle = "Qualcosa è andato storto";
                    _this.notificationMessage = "Controllare che email e password siano corretti!";
                }

                notify({
                        type: 'error',
                        title: _this.notificationTitle,
                        text: _this.notificationMessage,
                    });
			});
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .ion-logo-apple {
        /*font-size: 1.65rem;*/
        line-height: inherit;
        margin-right: 0.5rem;
        /*color: var(--color-black);*/
    }
    .ion-logo-facebook {
        /*font-size: 1.65rem;*/
        line-height: inherit;
        margin-right: 0.5rem;
        color: #1a73e8;
        /*color: var(--color-blue);*/
    }

    .login-social-buttons .method-control {
        margin-bottom: 1rem;
    }
    .login-social-buttons .method-action {
        font-family: inherit;
        /*font-size: 0.95rem;*/
        font-weight: 500;
        line-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0.35rem 1.25rem;
        outline: none;
        border: 2px solid black;
        border-radius: 2rem;
        color: var(--color-black);
        background: var(--color-white);
        text-rendering: optimizeLegibility;
        transition: all 0.35s ease;
        background-clip: padding-box;
        box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
        height: 40px;
    }
    .method-action{
        text-decoration: none;
    }
    .method-control.google{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.facebook{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.apple{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .login-google-span{
        margin-left: 24px;
    }
    .login-social-buttons .method-action:hover {
        background: var(--color-light);
    }


    .wrapper{
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 15px;
    }
    .wrapper .option{
        background: #fff;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px 10px;
        border: 2px solid lightgrey;
        transition: all 0.3s ease;
    }
    .wrapper .option .dot{
        height: 20px;
        width: 20px;
        background: #d9d9d9;
        border-radius: 50%;
        position: relative;
    }
    .wrapper .option .dot::before{
        position: absolute;
        content: "";
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        background: #b85b2d;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.5);
        transition: all 0.3s ease;
    }
    input[type="radio"]{
        display: none;
    }
    #option-1:checked:checked ~ .option-1,
    #option-2:checked:checked ~ .option-2{
        border-color: #b85b2d;
        background: #b85b2d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }
    #option-1:checked:checked ~ .option-1 .dot,
    #option-2:checked:checked ~ .option-2 .dot{
        background: #fff;
    }
    #option-1:checked:checked ~ .option-1 .dot::before,
    #option-2:checked:checked ~ .option-2 .dot::before{
        opacity: 1;
        transform: scale(1);
    }
    .wrapper .option span{
        font-size: 20px;
        color: #808080;
        margin-right: 5px;
    }
    #option-1:checked:checked ~ .option-1 span,
    #option-2:checked:checked ~ .option-2 span{
        color: #fff;
    }

    .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

    .showPassword{
        position: absolute;
        right: 45px;
        bottom: 10px;
        color: #837575;
        cursor: pointer;
    }
    .form-control.is-invalid + .showPassword{
        position: absolute;
        right: 45px;
        bottom: 35px;
        color: #837575;
        cursor: pointer;
    }
    .password-div{
        position: relative;
    }
</style>
