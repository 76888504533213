<template>

    <div class="conatiner-fluid mb-3 result-not-satisfied" v-if="businessSearchList.length != 0">
        <h4> Non hai trovato quello che cercavi? </h4>
        <label> Clicca <a @click="goToFilters">qui</a> e cambia i filtri.</label>
        <label> Clicca <a @click="goToSendRequest">qui</a> e affidati a noi! Porteremo avanti al posto tuo la ricerca. </label>
    </div>

    <p class="search-result">{{ businessSearchList.length }} risultat{{ (businessSearchList.length > 0 && businessSearchList.length == 1) ? "o" : "i" }}</p>

    <!--NO RESULT-->
    <div class="col-12 col-md-12 mb-2 mt-5 no-result-col" v-if="businessSearchList.length == 0">
        <div class="no-result">
            <div class="content-div">
                <h4>Nessun risultato al momento...</h4>
                <label>Prova a cambiare i <a @click="goToFilters"><b>filtri</b></a>, o possiamo cercare noi al posto tuo.</label>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-12 contact-us" v-if="businessSearchList.length == 0 || noResultStep == 1">
        <div class="no-result">
            <div class="content-div">
                <label>Lascia qui sotto la mail alla quale vuoi essere ricontattato e non appena avremo trovato dei match alla tua ricerca, ti avviseremo.</label>
                <label>Ci invierai automaticamente tutti i filtri impostati e cercheremo noi al posto tuo.</label>
                <div class="row">
                            <div class="col-12 col-md-12">
                                <input type="email" class="form-control form-control-lg mt-2" placeholder="es: simone.rossi@example.it" v-model="alertMail"
                                    @change="validateAlertMail" @input="validateAlertMail" ref="alertMail"/>
                                <div id="validationAlertMail" class="invalid-feedback">
                                        Inserire un indirizzo email valido.
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <button class="btn" type="button" @click="sendEmailRequest()">
                                    <mdicon :size="30" name="email"/> <span class="btn-text">Inviaci la richiesta</span>
                                </button>
                            </div>
                            <label class="mt-2">Oppure tramite</label>
                            <div class="col-12 col-md-12">
                                <button class="btn whatsapp" type="button" @click="sendWhatsAppRequest()">
                                    <mdicon :size="30" name="whatsapp" class="w-icon"/> <span class="btn-text">WhatsApp</span>
                                </button>
                            </div>
                </div>     
            </div>
        </div>           
    </div>

    <div class="response-div" v-if="noResultStep == 2">
        <div :class="alert.type" role="alert">
            {{ alert.message }}
        </div>
    </div>

    <div v-for="(b,index) in businessSearchList" v-bind:key="b.userUid" class="col-12 col-md-12 mb-2">
        <div class="ps-card" v-if="noResultStep == 0">
             <div class="ps-card-container">
                <div class="image-container">
                    <div class="trend-badge" v-if="diffDays(partnerPersonalInfoList[index].verificationCompleteDate, new Date()) <= 7">
                          <mdicon name="fire-circle"/> <span>Novità</span>
                    </div>
                    <img src="https://images.pexels.com/photos/617278/pexels-photo-617278.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" height="200" v-if="b.buisnessAnimalType=='Gatto'"/>
                    <img src="https://images.pexels.com/photos/12328872/pexels-photo-12328872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" height="200" v-else/>
                </div>
                <div class="info-container">
                    <div class="title-div">
                        <h2>{{ b.businessName }}</h2>
                        <button class="favourite-btn" type="button" v-if="currentUser == null || currentUser.uid != b.userUid">
                            <mdicon name="cards-heart-outline" class="calculate-position"/> <!--cards-heart-->
                        </button>
                    </div>
                    <p><span class="badge bg-warning">{{ b.buisnessAnimalType }}</span> <span class="badge bg-secondary">{{ b.buisnessAnimalRace }}</span></p>
                    <p><mdicon :size="18" name="map-marker-radius" /> {{ b.businessAddress }} - A {{ Math.round(distanceList[index]) }} Km da te</p>
                    <!--<div class="contact-buttons">
                        <button class="btn" type="button">
                            <mdicon name="email" /> Contatta via Email
                        </button>
                        <button class="btn ml-2" type="button">
                            <mdicon name="phone" /> Contatta via telefono
                        </button>
                    </div>-->
                </div>
             </div>
        </div>              
    </div>
</template>

<script>

    import {FirebaseAuth, FirebaseDatabase} from "@/firebase";
    import VueCookies from 'vue-cookies';
    import emailjs from '@emailjs/browser';

    export default {
        name: 'SearchResultPage',
        components: {
        },
        data() {
            return{
                currentUser: null,
                distanceList: [], // momentanea
                partnerPersonalInfoList: [], //momentanea
                businessSearchList: [],
                showModal: false,
                alertMail: null,
                isEmailValid: null,
                templateParams : {
                    to_name: null,
                    message: null
                },
                noResultStep: 0,
                alert: {
                    message: null,
                    type: "alert alert-success"
                }
            }
        },    
        created: function () {
            this.noResultStep = 0;
            var partnerListRef = FirebaseDatabase.ref("/usersBusinessInfo");
            var userRef = FirebaseDatabase.ref("/users");
            var searchLatParam = VueCookies.get('srch_address_lat');
            var searchLngParam = VueCookies.get('srch_address_lng');
            var searchRadius = VueCookies.get('srch_radius');
            var searchAnimalType = VueCookies.get('srch_animal_type');
            var searchAnimalRace = VueCookies.get('srch_animal_race');

            var distance = null;
            var matchRace = false;

            this.currentUser = FirebaseAuth.currentUser;
            this.businessSearchList.length = 0;

            partnerListRef
            .on('value' , snapshot => {
                    if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                        snapshot.forEach(user => {
                            distance = this.calcCrow(searchLatParam,searchLngParam,user.val().businessLat,user.val().businessLng);
                            if(distance <= searchRadius){
                                if(user.val().buisnessAnimalType == searchAnimalType || (searchAnimalType === null) || (searchAnimalType == "Entrambi")){

                                    searchAnimalRace = (searchAnimalRace === null ? "Razza" : searchAnimalRace);

                                    if(searchAnimalRace == "Razza"){
                                        matchRace = true;
                                    }else{
                                        matchRace = false;
                                    }
                                    
                                    if(!matchRace){
                                        const races = user.val().buisnessAnimalRace.split(' · ');
                                        races.forEach(function(r) {
                                            if(r == searchAnimalRace || matchRace){
                                                matchRace = true;
                                            }else{
                                                matchRace = false;
                                            }
                                        });
                                    }

                                    if(matchRace){
                                        this.distanceList.push(distance);
                                        this.businessSearchList.push(user.val());

                                        userRef
                                        .orderByChild('userUid')
                                        .equalTo(user.val().userUid)
                                        .on('value' , snapshot => {
                                            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                                                snapshot.forEach(userInfo => {
                                                    this.partnerPersonalInfoList.push(userInfo.val());
                                                    //console.log(this.partnerPersonalInfoList[0]);
                                                });
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    }
                    
                    const element = document.getElementById('search-view');
                    element.scrollIntoView({ behavior: 'smooth' });
            });     
        },
        methods:{
            goToSendRequest(){
                this.noResultStep = 1;
                const element = document.getElementById('search-view');
                element.scrollIntoView({ behavior: 'smooth' });
            },
            sendEmailRequest(){
                this.templateParams.message = `Salve, vi invio i dati della mia ricerca:
                                            • Tipologia animale : ${VueCookies.get('srch_animal_type')}
                                            • Razza : ${VueCookies.get('srch_animal_race')}
                                            • Importo da - a : ${VueCookies.get('srch_fromImport')} - ${VueCookies.get('srch_toImport')}
                                            • Mia posizione : ${VueCookies.get('srch_address_name')}
                                            • Raggio : ${VueCookies.get('srch_radius')} Km
                                            • Età : ${VueCookies.get('srch_animal_eta')}`;
                this.templateParams.to_name = this.alertMail;

                emailjs.send('service_xhan5gl','template_6f06wnw', this.templateParams, 'RJbyy_59ZMZN0janP')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    this.alert.message = "La tua richiesta è stata inviata con successo!";
                    this.alert.type = "alert alert-success";
                }, (err) => {
                    console.log('FAILED...', err);
                    this.alert.message = "C'è stato un errore nell' invio della richiesta!";
                    this.alert.type = "alert alert-danger";
                });

                this.noResultStep = 2;
            },
            sendWhatsAppRequest(){
                this.noResultStep = 2;
                var messageToSend = `Salve, vi invio i dati della mia ricerca:
                                            • Tipologia animale : ${VueCookies.get('srch_animal_type')}
                                            • Razza : ${VueCookies.get('srch_animal_race')}
                                            • Importo da - a : ${VueCookies.get('srch_fromImport')} - ${VueCookies.get('srch_toImport')}
                                            • Mia posizione : ${VueCookies.get('srch_address_name')}
                                            • Raggio : ${VueCookies.get('srch_radius')} Km
                                            • Età : ${VueCookies.get('srch_animal_eta')}`;

                window.open("https://wa.me/+393920833493?text=" + encodeURIComponent(messageToSend), '_blank');

                this.alert.message = "La tua richiesta è stata inviata con successo!";
                this.alert.type = "alert alert-success";
            },
            goToFilters(){
                const element = document.getElementById('filters');
                element.scrollIntoView({ behavior: 'smooth' });
            },
            diffDays(date, otherDate){
                var dateStart = new Date(date);
                return Math.ceil(Math.abs(otherDate - dateStart) / (1000 * 60 * 60 * 24));
            },
            validateAlertMail(){
                if(this.alertMail != null && this.alertMail != "" && this.validateEmail(this.alertMail)){
                    this.$refs.alertMail.classList.remove('is-invalid');
                }else{
                    this.$refs.alertMail.classList.add('is-invalid');
                }
            },
            validateEmail(emailToCheck) {
                var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
                

                if (reg.test(emailToCheck)) {
                    this.isEmailValid = true;
                } else {
                    this.isEmailValid = false;
                }

                return this.isEmailValid;
            }, 
            closeModal(){
                this.showModal = false;
            },
            calcCrow(searchLat, searchLng, bussinessLat, businessLng) 
            {
                var R = 6371; // km
                var dLat = this.toRad(bussinessLat-searchLat);
                var dLon = this.toRad(businessLng-searchLng);
                var lat1 = this.toRad(searchLat);
                var lat2 = this.toRad(searchLng);

                var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                var d = R * c;
                return d;
            },
            toRad(Value) 
            {
                return Value * Math.PI / 180;
            }
        }   
    }
</script>

<style scoped>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .ps-card img{
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      width: 100%;
      height: 100%;
    }
    .ps-card h2{
      text-align: left;
    }
    .ps-card p{
      text-align: left;
    }
    .search-result{
        text-align: right;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #6a6d72;
    }
    .title-div{
        position: relative;
    }
    .favourite-btn.focus, .favourite-btn:focus, .favourite-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .favourite-btn {
        position: absolute;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #b85b2d;
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
        right: 0;
        margin: auto;
        bottom: 0;
    }
    .no-result a{
        color: #b85b2d;
        text-decoration: none;
        cursor: pointer;
    }
    .no-result .btn {
        margin-top: 10px;
        color: #fff;
        background: #b85b2d;
        min-height: 48px;
        padding: 12px 24px;
        border: none;
        text-decoration: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 4px;
        font-weight: 600;
        font-family: plex-sans,sans-serif;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        line-height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        transition-property: background;
        transition: 0.5s;
    }
    .no-result .btn.whatsapp {
        margin-top: 10px;
        background: #4bc759;
    }
    .contact-buttons{
        display: flex;
        gap: 10px;
    }

    .email-div .form-control, .no-result .form-control{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 48px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px;
      border: 1px solid #e8ebeb;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      -webkit-transition-property: -webkit-box-shadow;
      transition-property: -webkit-box-shadow;
      transition-property: box-shadow;
      transition-property: box-shadow,-webkit-box-shadow;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    .email-div .form-control.is-invalid{
      position: relative;
      border: 2px solid #ff483b;
      padding: 11px 39px 11px 15px;
      caret-color: #ff483b;
      -webkit-transition-property: -webkit-box-shadow;
      transition-property: -webkit-box-shadow;
      transition-property: box-shadow;
      transition-property: box-shadow,-webkit-box-shadow;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    .trend-badge{
      position: absolute;
      color: #fff;
      background-color: #642afb;
      border-radius: 15px;
      padding: 2px 10px;
      top: 5px;
      left: 10px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
      transition: 0.3s;
      font-weight: bold;
    }
    .ps-card .image-container{
        position: relative;
    }

    html{
        background-color: #edeef0;
    }



    /* */
    .ps-card{
        padding: 15px 18px;
        min-height: 120px;
        height: 100%;
    }
    .ps-card-container{
        display: flex;
    }
    .ps-card-container h2{
        font-size: 20px;
        font-weight: 600;
    }
    .ps-card-container p{
        font-size: 14px;
    }
    .ps-card-container .badge{
        font-size: 15px;
    }

    .image-container{
        height: 130px;
        width: 155px;
        position: relative;
    }
    .info-container{
        flex: 1 1 auto;
        margin-left: 10px;
    }
    .info-container{
        flex: 1 1 auto;
        margin-left: 10px;
    }

    .no-result{
        position: relative;
    }
    .no-result h4{
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
    }
    .no-result label{
        font-size: 14px;
        line-height: 21px;
    }
    .no-result .content-div{
        margin: 0;
    }

    .result-not-satisfied{
        background: #f6edd9;
        padding: 10px 20px;
        border: 1px solid #d7d7d7;
    }
    .result-not-satisfied h4{
        font-size: 18px;
        font-weight: 600;
    }
    .result-not-satisfied a{
        text-decoration: none;
        color: #b85b2d;
        font-weight: 600;
        cursor: pointer;
    }
    .result-not-satisfied label{
        font-size: 14px;
        line-height: 21px;
        display: block;
    }

    @media (max-width: 647px){
        .col-12{
          padding: 0px;
        }

        .no-result-col{
            padding: 0px 20px;
        }
        .contact-us{
            padding: 0px 20px;
        }

        .ps-card{
            border-radius: 0px;
        }
        .ps-card-container h2{
            font-size: 14px;
            line-height: 21px;
        }
        .ps-card-container .badge{
            font-size: 12px;
        }
        .ps-card-container p{
            font-size: 12px;
        }
    }

    @media (max-width: 768px){
        .contact-buttons{
            display: inline-grid;
            gap: 10px;
        }

        .no-result label{
            margin-top: 20px;
        }

        .no-result .btn{
            width: 100%;
        }
        .no-result .btn.whatsapp{
            width: 100%;
        }
    }
</style>