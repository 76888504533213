<template>
      <footer class="bd-footer py-5">
            <div class="container py-5">
                <div class="row">
                    <div class="col-lg-3 mb-3">
                        <div class="footer-container">
                            <a class="navbar-brand" href="/"><img src="../assets/logo.jpg" height="35" alt="petshelter-logo"/> <span class="logo-span">PetShelter</span></a>
                            <div class="footer-container-body mt-3">
                                <ul class="list-unstyled small text-muted">
                                    <li class="mb-2">Made with ❤️ by Simone Besana.</li>
                                    <li class="mb-2"></li>
                                    <!--<li class="mb-2">Currently v5.1.3.</li>
                                    <li class="mb-2">Analytics by <a href="https://usefathom.com/ref/ADZSBE" target="_blank" rel="noopener">Fathom</a>.</li>-->
                                    <li class="mb-2"><a href="https://it.freepik.com/vettori-gratuito/carino-astronauta-volare-aereo-di-carta-nello-spazio-cartoon-vettore-icona-illustrazione-icona-della-tecnologia-della-scienza_31789059.htm#query=paper%20plane%20cartoon&position=5&from_view=search&track=sph">Immagini di catalyststuff</a> su Freepik</li>
                                    <li class="mb-2"><a href="https://it.freepik.com/foto-gratuito/messaggio-della-campana-di-notifica-del-rendering-3d-e-pin-della-mappa_32138695.htm#query=mail&position=13&from_view=search&track=sph">Immagini di upklyak</a> su Freepik</li>
                                    <li><a href="https://www.pexels.com/it-it/@shvetsa/">Immagini di shvetsa</a> su Pexels</li>
                                    
                                    <div class="social-ft-div">
                                        <div id="fb-share-button">
                                            <a href="https://www.facebook.com/profile.php?id=100088851945477" target="_blank">
                                            <svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet">
                                                <path class="svg-icon-path" d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z"></path>
                                            </svg>
                                            <span>Visita la nostra pagina</span>
                                            </a>
                                        </div>
                                        <div id="ig-share-button">
                                            <a href="https://www.instagram.com/petshelter_official/" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" id="mainIconPathAttribute" fill="#ffffff"></path> 
                                            </svg>
                                            <span>Visita la nostra pagina</span>
                                            </a>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mb-3">
                        <div class="footer-container">
                            <h4>Informazioni legali</h4>
                            <div class="footer-container-body mt-3">
                                <ul class="list-unstyled small text-muted">
                                    <li><router-link to="/informazioni-legali/termini-e-condizioni" target="_blank">Termini & Condizioni</router-link></li>
                                    <li><a href="https://www.iubenda.com/privacy-policy/23470730" target="_blank">Informativa sulla privacy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mb-3"></div>
                    <div class="col-lg-3 mb-3"></div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mb-3 copyright-div">
                    <p>©{{new Date().getFullYear()}} Simone Besana. Tutti i diritti riservati.</p> 
                    </div>
                </div>
            </div>

        </footer>  
</template>

<script>
    export default {
        name: 'FooterPage',
    }
</script>

<style>

    #fb-share-button {
        background: #3b5998;
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 8px;
        display: inline-block;
        position: static;
        margin-top: 10px;
    }

    #ig-share-button {
        background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 8px;
        display: inline-block;
        position: static;
        margin-top: 10px;
    }

    #ig-share-button:hover {
        cursor: pointer;
        background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
    }

    #fb-share-button:hover {
        cursor: pointer;
        background: #213A6F
    }

    #ig-share-button svg {
        width: 18px;
        fill: white;
        vertical-align: middle;
        border-radius: 2px
    }

    #fb-share-button svg {
        width: 18px;
        fill: white;
        vertical-align: middle;
        border-radius: 2px
    }

    #ig-share-button span {
        vertical-align: middle;
        color: white;
        font-size: 14px;
        padding: 0 3px
    }

    #ig-share-button a {
        text-decoration: none;
    }

    #fb-share-button a {
        text-decoration: none;
    }

    #fb-share-button span {
        vertical-align: middle;
        color: white;
        font-size: 14px;
        padding: 0 3px
    }

    .bd-footer{
        background-color: #232044; /*2e3333*/
        color: #fff;
    }
    .footer-container{
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 224px;
        background: hsla(0,0%,100%,.1);
        border-radius: 3px;
        padding-top: 10px;
        width: 100%;
        height: 100%;
    }
    .footer-container a{
        text-decoration: none;
        color: #fff;
    }
    .footer-container a:hover,.footer-container a:active,.footer-container a:focus{
        text-decoration: none;
        color: #b85b2d;
    }
    .footer-container .navbar-brand:hover,.footer-container .navbar-brand:active,.footer-container .navbar-brand:focus{
        color: #fff;
    }
    .footer-container h4{
       font-weight: 700;
    }
    .footer-container .text-muted {
        color: #dadadb !important;
    }

    .copyright-div{
        text-align: left;
    }
    .copyright-div p{
        font-size: 15px;
        color: #fff;
    }

    .social-ft-div{
        display: inline-grid;
    }

    /* Extra small devices (phones, 480px and down) */
    @media only screen and (max-width: 480px) {}

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {}

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {}

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {}

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {}

</style>