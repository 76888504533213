<template>
        <div class="page-header" :style="{'background': 'linear-gradient(0deg, #b85b2dd4, #f6edd930),url(' + require(`../../${currentCategory.imageUrl}`) + ')'}">
            <div class="container">
                <h2>{{currentCategory.categoryName}}</h2>
                <div class="image-quote">
                    <span>{{ currentCategory.imageQuote }}</span>
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="ps-card">
                            <div v-for="(t,index) in currentCategory.titlesList" v-bind:key="index" class="mt-5">
                                <h2 >{{t.text}}</h2>

                                <div v-for="(p,index) in currentCategory.paragraphsList" v-bind:key="index">
                                    <p v-if="p.titleId==t.id">{{p.text}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

    import {FirebaseDatabase} from "@/firebase";

    export default {
        name: 'SingleCategoryPage',
        components:{
        },
        data() {
            return{
                currentCategory:{
                    categoryName: "",
                    categorySlug: "",
                    imageUrl: "",
                    imageQuote: null,
                    titlesList: [],
                    paragraphsList: [],
                }
            }
        },
        created: function(){
            var categoriesRef = FirebaseDatabase.ref("/categories");

            this.currentCategory.categorySlug = this.$route.params.categoryslug;

            categoriesRef
            .orderByChild('slug')
            .equalTo(this.currentCategory.categorySlug)
            .on('value' , snapshot => {
                if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                    snapshot.forEach(category => {
                        this.currentCategory.categoryName = category.val().categoryName;
                        this.currentCategory.imageUrl = category.val().imageUrl == null ? "assets/404.jpg" : category.val().imageUrl;
                        this.currentCategory.imageQuote = category.val().imageSource;

                        var uniqueFirebaseKey = Object.keys(snapshot.val())[0];
                        var titlesRef = FirebaseDatabase.ref("/categories/" + uniqueFirebaseKey + "/titles");

                        titlesRef
                        .orderByChild('id')
                        .on('value' , snapshot => {
                            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                                snapshot.forEach(title => {
                                    //console.log(title.val());
                                    this.currentCategory.titlesList.push(title.val());
                                });
                            }
                        });

                        var paragraphsRef = FirebaseDatabase.ref("/categories/" + uniqueFirebaseKey + "/paragraphs");

                        paragraphsRef.on('value' , snapshot => {
                            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                                snapshot.forEach(paragraph => {
                                    //console.log(title.val());
                                    this.currentCategory.paragraphsList.push(paragraph.val());
                                });
                            }
                        });
                    });
                }
            });
        },
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .page-header{
        padding: 251px 20px;
        background-size: cover !important;
        background-position: center !important;
    }
    .page-header h2{
        font-size: 60px;
        line-height: 48px;
        color: #fff;
        text-align: left;
        font-weight: 700;
    }

    .page-body{
        background-color: #edeef0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
    }
    .page-body h2{
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body h4{
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }

    .image-quote{
        position: absolute;
        color: #fff;
    }

    html{
        background-color: #edeef0;
    }

    @media (max-width: 768px){
        .page-header h2{
            font-size: 30px;
        }
        .page-header{
            padding: 60px 20px;
        }
    }
</style>