<template>
        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>     

        <div class="row">
            <div class="col-12 col-lg-6">
                <h2>Che cos'è?</h2>
                <p>L'Ente nazionale cinofilia italiana, in acronimo ENCI, è un'associazione italiana per la gestione della cinofilia italiana che si occupa della catalogazione delle razze canine, di tutti i cani presenti in Italia e dell'organizzazione 
                    e gestione di eventi nazionali e internazionali di sport cinofili.</p>
            </div>
            <div class="col-12 col-lg-6 text-center">
                <a href="https://www.enci.it"><img src="https://www.enci.it/media/1022/logo-enci-2x.png" alt="enci-logo"></a>
            </div>
        </div>

        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>

        <h2>Le Razze Italiane</h2>
        <p>L'ENCI riserva una particolare attenzione alla valorizzazione e promozione delle razze italiane. 
           Le razze autoctone e di antica origine sono 16 (cane da pastore bergamasco, maltese, bolognese, pastore maremmano-abruzzese, bracco italiano, mastino napoletano, cirneco dell'Etna, segugio italiano a pelo raso, segugio italiano a pelo forte, segugio dell'Appennino e segugio maremmano, cane corso, lagotto romagnolo, spinone, piccolo levriero italiano, volpino italiano) mentre altre sono in via di riconoscimento. La selezione ed il loro miglioramento assumono un carattere preminente 
           nell'attività tecnica dell'ente che ne vuole favorire la conoscenza e la diffusione sia in Italia che all'estero.</p>

        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>
</template>

<script>

    export default {
        name: 'EnciPage',
        components:{
        },
        data() {
            return{
            }
        },
        created: function(){
        },
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .page-header{
        padding: 251px 20px;
        background-size: cover !important;
        background-position: center !important;
    }
    .page-header h2{
        font-size: 60px;
        line-height: 48px;
        color: #fff;
        text-align: left;
        font-weight: 700;
    }

    .page-body{
        background-color: #edeef0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
    }
    .page-body h2{
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body h4{
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body a{ 
        color: #b85b2d;
        text-decoration: none;
    }

    .image-quote{
        position: absolute;
        color: #fff;
    }

    html{
        background-color: #edeef0;
    }

    @media (max-width: 768px){
        .page-header h2{
            font-size: 30px;
        }
        .page-header{
            padding: 60px 20px;
        }
    }
</style>