<template>
    <div class="container business-slider" v-if="partnerList.length > 0">
        <div class="container">
          <h2>I nostri partner</h2>
          <!-- Slideshow of business activities (canili/gattili,allevatori etc..) -->
          <carousel :settings="settings" :breakpoints="breakpoints" :autoplay="isMobile() ? 5000 : 0" :wrap-around="isMobile()"> <!--:autoplay="5000" :wrap-around="true"-->
            <slide v-for="(partner,index) in partnerList" :key="partner.userUid">
              <div class="ps-card bsn" @click="goToBusinessProfile">
                  <div class="business-card">
                      <div class="trend-badge" v-if="diffDays(partnerPersonalInfoList[index].verificationCompleteDate, new Date()) <= 7">
                          <mdicon name="fire-circle"/> <span>Novità</span>
                      </div>
                      <img src="https://images.pexels.com/photos/617278/pexels-photo-617278.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" height="200" v-if="partner.buisnessAnimalType=='Gatto'"/>
                      <img src="https://images.pexels.com/photos/12328872/pexels-photo-12328872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" height="200" v-else/>
                      <h4> {{ partner.businessName }} </h4> <!--<mdicon name="cat" class="account-verified"/>-->
                      <p><span class="badge bg-warning">{{ partner.buisnessAnimalType }}</span> <span class="badge bg-secondary">{{ partner.buisnessAnimalRace }}</span></p>
                      <p><mdicon :size="18" name="map-marker-radius" /> {{ partner.businessAddress }} </p>
                  </div>    
              </div>
            </slide>
            <template #addons v-if="partnerList.length > 4">
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
    </div>
    <!-- Modal -->
    <div>
      <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title">Accesso negato.</h1>
            <button class="close-btn" type="button" @click="closeModal">
                  <mdicon name="window-close" class="calculate-position"/>
            </button>
          </div>
          <div class="container modal-body">
              <div class="row">
                <div class="col-12 col-md-12 search-btn-div">
                    <label>Per accedere a questo contenuto è necessario eseguire la login.</label>  
                </div>   
              </div>
              <div class="row mt-5">
                <div class="col-12 col-md-12 search-btn-div">
                    <button class="btn" type="button">
                            Accedi
                    </button>   
                </div>   
              </div>
          </div>        
      </vue-final-modal>
    </div>
</template>

<script>

import {FirebaseAuth, FirebaseDatabase} from "@/firebase";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { VueFinalModal } from 'vue-final-modal';

export default {
  name: 'BusinessSliderPage',
  data() {
    return{
      // carousel settings
			settings: {
				itemsToShow: 4,
				snapAlign: "center"
			},
			// breakpoints are mobile first
			// any settings not specified will fallback to the carousel settings
			breakpoints: {
        // 350px and up
				350: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 700px and up
				700: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				// 1024 and up
				1024: {
					itemsToShow: 4,
					snapAlign: "start"
				}
			},
      partnerList: [],
      partnerPersonalInfoList: [],
      showModal: false
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    VueFinalModal
  },
  methods: {
    diffDays(date, otherDate){
      var dateStart = new Date(date);
      return Math.ceil(Math.abs(otherDate - dateStart) / (1000 * 60 * 60 * 24));
    },
    closeModal(){
      this.showModal = false;
    },
    loadBusinessPartners(){
        var partnerListRef = FirebaseDatabase.ref("/usersBusinessInfo");
        var userRef = FirebaseDatabase.ref("/users");
        this.partnerList.length = 0;

        partnerListRef
        .on('value' , snapshot => {
          if(snapshot.hasChildren() && snapshot.numChildren() > 0){
              snapshot.forEach(user => {
                
                userRef
                .orderByChild('userUid')
                .equalTo(user.val().userUid)
                .on('value' , snapshot => {
                    if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                        snapshot.forEach(userInfo => {
                            //console.log(userInfo.val())
                            if(userInfo.val().enabled == true && userInfo.val().isEmailConfirmed == true){
                                this.partnerList.push(user.val());

                                userRef
                                .orderByChild('userUid')
                                .equalTo(user.val().userUid)
                                .on('value' , snapshot => {
                                    if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                                        snapshot.forEach(userInfo => {
                                            this.partnerPersonalInfoList.push(userInfo.val());
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
                //console.log(this.partnerList);                      
              });
          }     
        });
    },
    goToBusinessProfile(){
        let currentUser = FirebaseAuth.currentUser;

        if(currentUser == null || currentUser.emailVerified == false){
            this.showModal = true;
        }else{
          alert("OK");
        }
    },
    isMobile() {
        if( screen.width <= 760 ) {
          return true;
        }
        else {
          return false;
        }
    }
  },
  created: function(){
    this.loadBusinessPartners();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .carousel__item {
      min-height: 200px;
      width: 100%;
      background-color: #642afb;
      color: white;
      font-size: 20px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .carousel__slide {
      padding: 10px;
    }
    .business-card img{
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
    }
    .business-card h4{
        margin-top: 5px;
        text-align: left;
    }
    .business-card p{
        
        text-align: left;
    }
    .business-card .btn{
        height: 45px;
        border: 2px solid #b85b2d;
        background-color: #b85b2d;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        border-radius: 15px;
        padding: 0px 25px;
        transition: .3s all ease;
        line-height: 41px;
        width: 100%;
    }
    .business-card .btn:hover,.registration-form .btn:active{
      background-color: transparent;
      color: #b85b2d;
      border: 1px solid #b85b2d;
      border-radius: 15px;
    }
    .business-card .btn:disabled{
      pointer-events: auto;
      cursor: not-allowed;
    }
    .business-slider h2{
      text-align: left;
      font-weight: 700;
      font-size: 40px;
    }
    .business-slider{
        max-width: 1915px;
        /*background-color: #f9fafa;*/
        padding: 15px 25px;
        /*box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
        transition: 0.3s;*/
    }

    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .ps-card.bsn{
        border: 1px solid #e8e8e8b8;
        padding: 1px 15px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
        cursor: pointer;
    }

    .trend-badge{
      position: absolute;
      color: #fff;
      background-color: #642afb;
      border-radius: 15px;
      padding: 2px 10px;
      top: 20px;
      left: 35px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
      transition: 0.3s;
      font-weight: bold;
    }

    ::v-deep .modal-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ::v-deep .modal-content{
      display: flex;
      flex-direction: column;
      margin: 0 1rem;
      /*padding: 1rem;*/
      border: 1px solid #e2e8f0;
      border-radius: 0.25rem;
      background: #fff;
      max-width: 560px;
    }

    .close-btn.focus, .close-btn:focus, .close-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #b85b2d;
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .modal-title{
        font-size: 16px;
        line-height: 22px;
        color: #2e3333;
        font-weight: bold;
        margin: auto;
    }
    .modal-header{
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 10px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-color: rgba(0,0,0,.08);
    }
    .modal-body{
        padding: 20px 20px;
    }

    .modal-content .btn{
      color: #fff;
      background: #b85b2d;
      min-height: 48px;
      padding: 12px 24px;
      border: none;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 4px;
      font-weight: 600;
      font-family: plex-sans,sans-serif;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      line-height: 24px;
      margin: 0;
      display: inline-block;
      cursor: pointer;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      -webkit-transition-property: background;
      transition-property: background;
      transition: 0.5s;
      width: 100%;
    }
    .modal-content .btn:hover{
      filter: brightness(85%);
      transition: 0.3s ease-in;
    }
</style>
