<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->

  <nav class="navbar bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img src="../assets/logo.jpg" height="35" alt="petshelter-logo"/> <span class="logo-span">PetShelter</span></a>
      <!--<UserboxView></UserboxView>-->
    </div>
  </nav>

  <router-view />

  <FooterView></FooterView>

</template>

<script>

//import UserboxView from '../components/account/UserBoxView.vue';
import FooterView from '../components/FooterPage.vue';

export default {
  name: 'IndexApp',
  data() {
  },
  components: {
    //UserboxView
    FooterView
  },
  methods: {
  },
  created: function(){
  },
  computed:{
  }
}
</script>

<style>
    #app {
      font-family: 'Quicksand', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      /*margin-top: 60px;*/
    }
    .logo-span{
      font-family: 'Lobster', cursive;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      vertical-align: middle;
      font-weight: normal;
    }
    .navbar{
      z-index: 1000;
      border-bottom: 1px solid #c7c7c7;
      background-color: #fff !important;
    }

    .carousel__prev,
    .carousel__next {
      background-color: #fff7 !important;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .btn-login{
      background-color: transparent;
    }
    .btn-login:hover{
      /*border: 1px solid #c7c7c7;*/
      border: 1px solid #e7e7e7;
      background-color: #f1f1f1;
      transition: all 0.5s ease-out;
    }

    /* USER NAV BAR INFO */

    .top-bar-item-right {
        margin-left: auto;
    }
    @media (min-width: 992px){
      .top-bar-item {
          padding-left: 2rem;
      }
    }
    @media (min-width: 768px){
      .top-bar-item {
          padding-right: 1rem;
          padding-left: 1rem;
      }
    }
    .top-bar-item {
        padding: 0;
    }
    .top-bar-item, .top-bar-list {
        display: flex;
        align-items: center;
    }
    .dropdown, .dropleft, .dropright, .dropup {
        position: relative;
    }
    .btn-account {
        color: #fff;
        border-left: 1px solid rgba(34,34,48,.12) !important;
    }
    .app-header .btn-account {
        padding: 0 1rem;
        min-width: 12.5rem;
        min-height: 3.5rem;
    }
    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
        cursor: pointer;
    }
    .btn-account {
        position: relative;
        display: flex;
        margin: 0;
        border: 0;
        flex-wrap: none;
        align-items: center;
        background: none;
        color: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        transition: background-color .15s;
        outline: 0;
    }
    button, select {
        text-transform: none;
    }
    button, input {
        overflow: visible;
    }
    button, input, optgroup, select, textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button {
        border-radius: 0;
    }

    .btn-account>.has-badge, .btn-account>.user-avatar {
        margin-right: 0.75rem;
    }
    .user-avatar-md {
        width: 2.25rem;
        height: 2.25rem;
        font-size: 2.25rem;
    }
    .user-avatar {
        position: relative;
        margin-bottom: 0;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        vertical-align: middle;
        border-radius: 4rem;
    }

    .user-avatar audio, .user-avatar canvas, .user-avatar img, .user-avatar video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4rem;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
    }
    img, svg {
        vertical-align: middle;
    }

    img {
        border-style: none;
    }

    .btn-account .account-summary {
        max-width: 10rem;
    }
    .btn-account .account-summary {
        margin-right: 0.75rem;
        display: block;
        text-align: left;
        flex: 1;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
    }

    .btn-account .account-description, .btn-account .account-name {
        margin: 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        line-height: 1rem;
    }

    .btn-account .account-description {
        font-size: .75rem;
        font-weight: 400;
        opacity: .7;
    }
    .btn-account .account-description, .btn-account .account-name {
        margin: 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        line-height: 1rem;
    }

    .btn-account:hover {
        background-color: #f6edd92e;
    }
    .btn-account.hamburger{
        padding: 0 1rem;
        min-width: 1.5rem;
        min-height: 3.5rem
    }
    .btn-account.hamburger {
        border-left: 1px solid rgba(34,34,48,.12) !important;
        border-right: 1px solid rgba(34,34,48,.12) !important;
    }
    .btn-account {
        padding: 0 1rem;
        min-width: 12.5rem;
        min-height: 3.5rem
    }

    .btn-account:focus, .btn-account:hover {
        color: inherit;
        text-decoration: none;
    }

    .navbar{
          padding: 0px !important;
          border: none;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;
    }
    .navbar .container-fluid{
          padding-right: 0px;
    }

    /* USER MENU */

    .dropdown-menu.show {
        -webkit-animation-name: showDropdown;
        animation-name: showDropdown;
    }
    .dropdown-menu.show {
        display: block;
    }
    .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
        right: auto;
        bottom: auto;
    }
    @media (min-width: 768px){
      .dropdown-menu {
          font-size: .875rem;
      }
    }
    .dropdown-menu {
        margin-top: 10px !important;
        border: 0;
        font-weight: 400;
        box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 2px 7px 1px rgb(20 20 31 / 16%);
        -webkit-animation-duration: .2s;
        animation-duration: .2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #363642;
        text-align: left;
        list-style: none;
        background-color: hsla(0,0%,100%,.98);
        background-clip: padding-box;
        border: 1px solid rgba(20,20,31,.15);
        border-radius: 0.25rem;
    }

    .dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
        color: #fff;
        text-decoration: none;
        background-color: #346cb0;
    }
    .navbar a.router-link-exact-active:focus,.navbar a.router-link-exact-active:hover{
        color: #fff;
    }
    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        clear: both;
        font-weight: 400;
        /*color: #191927;*/
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        color: #a6abbd;
    }
    .dropdown-arrow, .dropdown-arrow:before {
        display: block;
        position: absolute;
        left: 0.5rem;
        pointer-events: none;
    }
    .dropdown-arrow {
        top: 0;
        overflow: hidden;
        height: 1.25rem;
        width: 1.25rem;
        z-index: 1001;
        transform: translate3d(0,-100%,0);
    }
    .dropdown-arrow:before {
        content: "";
        bottom: -0.375rem;
        transform: rotate(45deg);
        height: 0.75rem;
        width: 0.75rem;
        margin-left: -5px;
        background-color: hsla(0,0%,100%,.98);
        box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 2px 7px 1px rgb(20 20 31 / 16%);
    }
    .dropdown-arrow, .dropdown-arrow:before {
        display: block;
        position: absolute;
        left: 0.5rem;
        pointer-events: none;
    }
</style>
