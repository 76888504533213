<template>
    <div class="main-container">
      <div class="main-bg-image">
      </div>
      <div class="bg-fixed-center">
        <div class="bg-fixed-top">
            <h1><span class="highlight-span">Petshelter</span>, la prima piattaforma Italiana 
            <br> che mette in contatto gli animali con le persone.</h1>
        </div>
        <SearchView></SearchView>
      </div>
      <!--<div class="bg-fixed-bottom d-none d-lg-block">
            <h4>Vuoi saperne di più?</h4>
            <img src="../assets/scroll-down.gif" height="70"/>
      </div>-->
    </div>

    <BusinessSliderPage></BusinessSliderPage>

    <br/>
    <br/>

    <CategoriesView></CategoriesView>

    <br/>
    <br/>
    
    <div class="container mt-3" id="mission">
      <span>I nostri punti di forza</span>
      <h2>LA NOSTRA MISSION</h2>
    </div>

    <div class="container-fluid mt-3 white-color">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <img src="../assets/mission-image.jpg" alt="mission-img" height="230" />
            </div>
            <div class="col-12 col-md-6 text-col">
                <p><strong>Petshelter</strong>, nasce dalla convinzione che tutt'oggi, almeno nel panorama Italiano, non esista una piattaforma specializzata 
                  nella compravendita/adozione di animali domestici.</p>
                <p>Come per la ristorazione o altri tipi di esercizi commerciali, esistono piattaforme/servizi, che mettono in contatto l'utente con l'attività specifica, 
                  ciò non avviene in questo settore. Si utilizzano spesso per colmare questo gap, <strong>piattaforme/servizi</strong> che non sono state disegnate e sviluppate per questo compito, 
                  il che risulta in un processo che tutt'oggi è molto complesso e macchinoso in molti aspetti</p>
            </div>        
          </div>
        </div>
    </div>

    <div class="container-fluid mt-3 colored">
        <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 order-2 order-lg-2 text-col">
                  <h4>Una community solida</h4>
                  <p>Vogliamo riunire tramite la nostra piattaforma, la grossa community che ora risulta divisa 
                    in una molteplicità di gruppi Facebook che spesso parlano o si riferiscono allo stesso argomento e risultano ridondanti e confusionari.</p>
                  <p>Offrire così l'opportunità ad allevamenti\attività di rehoming\canili e gattili di usare un unico canale per rivolgersi al loro publico, 
                    o persone, che magari prima non li conoscevano neanche. Attività che ora viene svolta postando molteplici post su molteplici gruppi Facebook, un qualcosa di veramente macchinoso 
                    e poco comodo.</p>
                  <p>D'altro lato fornire all'utente un vasto network di attività vicino a se e non, che esaudiscano la sua richiesta.</p>
              </div>  
              <div class="col-12 col-md-6 order-1 order-lg-2">
                <img src="../assets/community-image.jpg" alt="community-img" height="230" />
              </div>      
            </div>
        </div>
    </div>

    <div class="container-fluid mt-3 white-color">
        <div class="container mt-3">
            <div class="row">
              <div class="col-12 col-md-6">
                <img src="../assets/bradipo-image.jpg" alt="bradipo-img" height="230" />
              </div>  
              <div class="col-12 col-md-6 text-col">
                  <h4>Sviluppato da persone pigre per persone pigre</h4>
                  <p>Miriamo a offrire una piattaforma e un servizio, che con pochi click di mouse o touch di smartphone, 
                    venga incontro alle esigenze di tutti.</p>
                  <p>Vogliamo aiutare le persone a trovare i loro futuri animali tramite l' utilizzo di comodi filtri, quali: vicinanza alla propria posizione, età, razza, tipologia, etc...
                    e allo stesso modo offrire strumenti innovativi per allevamenti/canili/gattili e rehoming, atti a semplificare la propria attività.
                  </p>
                  <p>Saremo sempre in continua evoluzione per esaudire i desideri e le necessità di tutta la community, o almeno ci proveremo 😊.</p>
              </div>      
            </div>
        </div>
    </div>

    <div class="container-fluid mt-3 colored">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-2 order-lg-2 text-col">
                <p>Hai qualche dubbio o qualcosa non ti risulta chiaro? Non esitare a contattarci tramite il pulsante qui sotto, all'indirizzo email <strong>info.petshelter@gmail.com</strong>, saremo felici di spiegarti ogni cosa.</p>
                <div id="mail-share-button">
                      <a href="mailto:info.petshelter@gmail.com" target="_blank">
                        <svg style="color: white" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Minutemailer</title>
                          <path d="M17.187 19.181L24 4.755 0 12.386l9.196 1.963.043 4.896 2.759-2.617-2.147-2.076 7.336 4.63z" fill="white"></path>
                        </svg>
                        <span>Inviaci un' E-Mail</span>
                      </a>
                </div>
            </div>
            <div class="col-12 col-md-6 order-1 order-lg-2">
              <img src="../assets/FAQ-image.jpg" alt="faq-img" height="230" />
            </div>       
          </div>
        </div>
    </div>

    <WorkWithUsView></WorkWithUsView>

    <div class="registration-form mt-3">
        <div class="wrap">
            <div class="row">
              <div class="d-none d-lg-block col-lg-3">
                <div class="wrap text-center">
                  <img src="../assets/registration/3D-bell.png">
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="wrap text-center">
                  <form>
                    <h3>Vuoi supportarci?</h3>
                    <div class="content">Registrati alla nostra newsletter per rimanere aggiornato, ed essere il primo a sapere quando il nostro servizio entrerà in funzione.</div>
                    <div class="row">
                      <div class="col-12 col-md-9">
                        <input type="text" :class="[isNewsletterEmailValid == null ? inputDefaultClass : (isNewsletterEmailValid ? invalidInputClass : validInputClass)]" placeholder="Email" v-model="newsletterEmail" @focusout="validateEmail(newsletterEmail)">
                        <div class="invalid-feedback">
                            Inserire un indirizzo email valido.
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <button type="button" class="btn btn-primary" @click="submitNewsletter" :disabled="((privacyAccepted == null || privacyAccepted == false) || (isNewsletterEmailValid == null || isNewsletterEmailValid == false))">Iscriviti</button>
                      </div>
                      
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                          <input :class="[privacyAccepted == null ? checkDefaultClass : (privacyAccepted ? invalidCheckClass : validCheckClass)]" type="checkbox" value="" id="privacyCheck" v-model="privacyAccepted">
                          <label class="form-check-label" for="privacyCheck">
                            Consenso al trattamento dei dati personali. <a href="https://www.iubenda.com/privacy-policy/23470730" target="_blank">Leggi la privacy</a>
                          </label>
                          <div class="invalid-feedback">
                            Devi dare il consenso alla privacy per poter procedere.
                          </div>
                      </div>
                    </div>
                    <div :class="[messageNewsletterClass]" role="alert" v-if="messageNewsletterVisible">
                       {{ messageNewsletter }}
                    </div>           
                  </form>
                </div>
              </div>
              <div class="d-none d-lg-block col-lg-3">
                <div class="wrap text-center">
                  <img src="../assets/registration/3D-message.png">
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

import {FirebaseDatabase} from "@/firebase";
import BusinessSliderPage from './BusinessSliderPage.vue';
import SearchView from './SearchBusinessesNearYouView.vue';
import CategoriesView from './categories/CategoriesPage.vue';
import WorkWithUsView from './WorkWithUsPage.vue';

export default {
  name: 'HomePage',
  components: {
    BusinessSliderPage,
    CategoriesView,
    SearchView,
    WorkWithUsView
  },
  data() {
    return{
      messageNewsletterVisible: false,
      messageNewsletter: "",
      messageNewsletterClass: "alert alert-success mt-5",
      newsletterEmail: null,
      privacyAccepted: null,
      isNewsletterEmailValid: null,
      inputDefaultClass: 'form-control',
      checkDefaultClass: 'form-check-input',
      validInputClass: 'form-control is-invalid',
      invalidInputClass: 'form-control is-valid',
      validCheckClass: 'form-check-input is-invalid',
      invalidCheckClass: 'form-check-input is-valid'
    }
  },
  methods: {
    submitNewsletter() {
      var newsletterRef = FirebaseDatabase.ref("/newsletter");

      if(this.privacyAccepted && this.isNewsletterEmailValid){
        //seraching if email is already in the db
        newsletterRef
        .orderByChild('email')
        .equalTo(this.newsletterEmail)
        .on('value' , snapshot => {
            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
              this.messageNewsletterVisible = true;
              this.messageNewsletterClass = "alert alert-danger mt-5";
              this.messageNewsletter = "Email già registrata!";
            }else{
              //adding record to firebase realtime db

              var today = new Date();
              var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
              var time = today.getHours() + ":" + today.getMinutes() + ":" + 
              today.getSeconds();
              var dateTime = date+' '+time;

              newsletterRef.push({
                email: this.newsletterEmail,
                type: "Not handled for now",
                privacyAccepted: this.privacyAccepted,
                privacyAcceptanceDate: dateTime
              });
              this.messageNewsletterVisible = true;
              this.messageNewsletterClass = "alert alert-success mt-5";
              this.messageNewsletter = "Registrazione avvenuta con successo!";
            }

            this.privacyAccepted = null;
            this.isNewsletterEmailValid = null;
            this.newsletterEmail = "";

            setTimeout(() => this.messageNewsletterVisible = false, 5000);
          });
      }
    },
    validateEmail(emailToCheck) {
        var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line

        if (reg.test(emailToCheck)) {
          this.isNewsletterEmailValid = true;
        } else {
          this.isNewsletterEmailValid = false;
        }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .registration-form{
        position: relative;
        background-color: #f6edd9;
    }
    .registration-form .wrap{
        position: relative;
    }
    .registration-form .wrap img{
        height: 180px;
        width: 180px;
    }
    .registration-form>.wrap{
        max-width: 1200px;
        padding: 80px 30px;
        margin: 0 auto;
    }
    .registration-form h3{
        font-size: 40px;
        margin-bottom: 10px;
    }
    .registration-form .content{
        position: relative;
        margin-bottom: 30px;
        font-size: 15px;
    }
    .registration-form .btn{
        height: 45px;
        border: 2px solid #b85b2d;
        background-color: #b85b2d;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        border-radius: 22.5px;
        padding: 0px 25px;
        transition: .3s all ease;
        line-height: 41px;
    }
    .registration-form .btn:hover,.registration-form .btn:active{
      background-color: transparent;
      color: #b85b2d;
      border: 1px solid #b85b2d;
      border-radius: 20px;
    }
    .registration-form .btn:disabled{
      pointer-events: auto;
      cursor: not-allowed;
    }
    .registration-form .form-control{
        position: relative;
        outline: 0;
        border: none;
        border-radius: 25px;
        line-height: 45px;
        padding: 0 25px;
        height: 45px;
        width: 100%;
    }
    .registration-form a{ 
        color: #b85b2d;
    }
    .main-bg-image{
      background: url(../assets/bho.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 900px;
      width: 100%;
      -webkit-filter: blur(1px);
      -moz-filter: blur(1px);
      -o-filter: blur(1px);
      -ms-filter: blur(1px);
      filter: blur(1px);
    }
    .main-container{
      border-bottom: 1px solid #c7c7c7;
    }
    .bg-fixed-top{
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .bg-fixed-bottom{
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .highlight-span{
      background-color: #b85b2d;
      color: #fff;
      font-weight: bold;
      padding: 5px;
    }
    #privacyCheck{
      margin-right: 2px;
    }
    #mail-share-button {
        background: #de4032;
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 8px;
        display: inline-block;
        position: static;
        margin-top: 10px;
        transition: .3s all ease;
    }
    #mail-share-button:hover {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: .3s all ease;
    }
    #mail-share-button svg {
        width: 18px;
        fill: white;
        vertical-align: middle;
        border-radius: 2px
    }
    #mail-share-button span {
        vertical-align: middle;
        color: white;
        font-size: 14px;
        padding: 0 3px
    }

    #mail-share-button a {
        text-decoration: none;
    }

    #mission h2{
      font-weight: 700;
      font-size: 40px;
      text-align: left;
    }

    #mission span{
      border-radius: 5px;
      background-color: #b85b2d;
      /* display: inline-flex; */
      /* justify-content: center; */
      /* text-align: center; */
      /* flex-direction: column; */
      /* align-items: center; */
      padding: 5px 10px;
      margin-right: 16px;
      margin-top: 16px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
      transition: 0.3s;
      color: #fff;
      font-family: 'Lobster', cursive;
      -webkit-font-smoothing: antialiased;
      font-size: 22px;
      display: flex;
      width: 200px;
    }

    .bg-fixed-center{
      position: absolute;
      left: 0;
      right: 0;
      top: 30%;
      bottom: 0;
      margin: auto;
    }
    /*.adsbygoogle {
      border: 1px solid red;
      height: 90px;
    }*/

    .colored{
      padding: 120px 0px;
      background-color: #f6edd9;
    }
    .colored h4{
      font-weight: 700;
      text-align: left;
      font-size: 35px;
      margin-bottom: 50px;
    }
    .colored p{
      text-align: left;
    }
    .colored img{
      border-radius: 50%;
    }

    .white-color{
      padding: 120px 0px;
    }
    .white-color h4{
      font-weight: 700;
      text-align: left;
      font-size: 35px;
      margin-bottom: 50px;
    }
    .white-color p{
      text-align: left;
    }

    @media only screen and (max-width: 480px) {
      #mission h2{
        font-weight: 700;
        font-size: 30px;
        text-align: left;
      }

      #mission span{
        font-size: 18px;
        width: 170px;
      }
      .white-color{
          padding: 50px 0px;
          margin-top: 0px;
      }
      .colored{
          padding: 50px 0px;
          margin-top: 0px;
      }
      .text-col{
        margin-top: 50px;
      }
    } 

    @media (max-width: 767.98px) {
      .registration-form .btn{
        margin-top: 20px;
      }
      .main-bg-image{
        background-size: cover;
        height: 680px;
      }
      .bg-fixed-center{
            top: 15% !important;
      }
    }

</style>
