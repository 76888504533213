<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->

  <nav class="navbar bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img src="../../assets/logo.jpg" height="35" alt="petshelter-logo"/> <span class="logo-span">PetShelter</span></a>
      <!--<UserboxView></UserboxView>-->
    </div>
  </nav>

  <div class="page-header" :style="{'background': 'linear-gradient(0deg, #b85b2dd4, #f6edd930),url(' + require(`../../${currentCategory.imageUrl}`) + ')'}">
        <div class="container">
            <h2>{{currentCategory.categoryName}}</h2>
            <div class="image-quote">
                <span>{{ currentCategory.imageQuote }}</span>
            </div>
        </div>       
  </div>
  <div class="page-body">
        <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="ps-card">
                            <router-view />
                        </div>
                    </div>
                </div>
        </div>
    </div>
  

  <footer class="bd-footer py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-3 mb-3">
            <div class="footer-container">
                <a class="navbar-brand" href="/"><img src="../../assets/logo.jpg" height="35" alt="petshelter-logo"/> <span class="logo-span">PetShelter</span></a>
                <div class="footer-container-body mt-3">
                    <ul class="list-unstyled small text-muted">
                        <li class="mb-2">Made with ❤️ by Simone Besana.</li>
                        <li class="mb-2"></li>
                        <!--<li class="mb-2">Currently v5.1.3.</li>
                        <li class="mb-2">Analytics by <a href="https://usefathom.com/ref/ADZSBE" target="_blank" rel="noopener">Fathom</a>.</li>-->
                        <li class="mb-2"><a href="https://it.freepik.com/vettori-gratuito/carino-astronauta-volare-aereo-di-carta-nello-spazio-cartoon-vettore-icona-illustrazione-icona-della-tecnologia-della-scienza_31789059.htm#query=paper%20plane%20cartoon&position=5&from_view=search&track=sph">Immagini di catalyststuff</a> su Freepik</li>
                        <li class="mb-2"><a href="https://it.freepik.com/foto-gratuito/messaggio-della-campana-di-notifica-del-rendering-3d-e-pin-della-mappa_32138695.htm#query=mail&position=13&from_view=search&track=sph">Immagini di upklyak</a> su Freepik</li>
                        <li><a href="https://www.pexels.com/it-it/@shvetsa/">Immagini di shvetsa</a> su Pexels</li>

                        <div id="fb-share-button">
                            <a href="https://www.facebook.com/profile.php?id=100088851945477" target="_blank">
                            <svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet">
                                <path class="svg-icon-path" d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z"></path>
                            </svg>
                            <span>Visita la nostra pagina</span>
                            </a>
                        </div>
                        <div id="ig-share-button">
                            <a href="https://www.instagram.com/petshelter_official/" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" id="mainIconPathAttribute" fill="#ffffff"></path> 
                            </svg>
                            <span>Visita la nostra pagina</span>
                            </a>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-3 mb-3">
            <div class="footer-container">
                <h4>Informazioni legali</h4>
                <div class="footer-container-body mt-3">
                    <ul class="list-unstyled small text-muted">
                        <li><router-link to="/informazioni-legali/termini-e-condizioni" target="_blank">Termini & Condizioni</router-link></li>
                        <li><a href="https://www.iubenda.com/privacy-policy/23470730" target="_blank">Informativa sulla privacy</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-3 mb-3"></div>
        <div class="col-lg-3 mb-3"></div>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-3 copyright-div">
           <p>©{{new Date().getFullYear()}} Simone Besana. Tutti i diritti riservati.</p> 
        </div>
      </div>
    </div>

  </footer>
</template>

<script>

//import UserboxView from '../../components/account/UserBoxView.vue';
import {FirebaseDatabase} from "@/firebase";

export default {
  name: 'CategoryIndexPage',
  data() {
     return{
        currentCategory:{
            categoryName: "",
            categorySlug: "",
            imageUrl: "",
            imageQuote: null,
        }
     }
  },
  components: {
    //UserboxView
  },
  methods: {
  },
  created: function(){
    var categoriesRef = FirebaseDatabase.ref("/categories");
    
    this.currentCategory.categorySlug = this.$route.name;

    categoriesRef
    .orderByChild('slug')
    .equalTo(this.currentCategory.categorySlug)
    .on('value' , snapshot => {
        if(snapshot.hasChildren() && snapshot.numChildren() > 0){
            snapshot.forEach(category => {
                this.currentCategory.categoryName = category.val().categoryName;
                this.currentCategory.imageUrl = category.val().imageUrl == null ? "assets/404.jpg" : category.val().imageUrl;
                this.currentCategory.imageQuote = category.val().imageSource;
            });
        }
    });
  },
  computed:{
  }
}
</script>

<style>
    #app {
      font-family: 'Quicksand', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      /*margin-top: 60px;*/
    }
    .logo-span{
      font-family: 'Lobster', cursive;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      vertical-align: middle;
      font-weight: normal;
    }
    .navbar{
      z-index: 1000;
      border-bottom: 1px solid #c7c7c7;
      background-color: #fff !important;
    }
    #fb-share-button {
        background: #3b5998;
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 8px;
        display: inline-block;
        position: static;
        margin-top: 10px;
    }

    #ig-share-button {
        background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 8px;
        display: inline-block;
        position: static;
        margin-top: 10px;
    }

    #ig-share-button:hover {
        cursor: pointer;
        background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
    }

    #fb-share-button:hover {
        cursor: pointer;
        background: #213A6F
    }

    #ig-share-button svg {
        width: 18px;
        fill: white;
        vertical-align: middle;
        border-radius: 2px
    }

    #fb-share-button svg {
        width: 18px;
        fill: white;
        vertical-align: middle;
        border-radius: 2px
    }

    #ig-share-button span {
        vertical-align: middle;
        color: white;
        font-size: 14px;
        padding: 0 3px
    }

    #ig-share-button a {
        text-decoration: none;
    }

    #fb-share-button a {
        text-decoration: none;
    }

    #fb-share-button span {
        vertical-align: middle;
        color: white;
        font-size: 14px;
        padding: 0 3px
    }

    .carousel__prev,
    .carousel__next {
      background-color: #fff7 !important;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .btn-login{
      background-color: transparent;
    }
    .btn-login:hover{
      /*border: 1px solid #c7c7c7;*/
      border: 1px solid #e7e7e7;
      background-color: #f1f1f1;
      transition: all 0.5s ease-out;
    }

    /* USER NAV BAR INFO */

    .top-bar-item-right {
        margin-left: auto;
    }
    @media (min-width: 992px){
      .top-bar-item {
          padding-left: 2rem;
      }
    }
    @media (min-width: 768px){
      .top-bar-item {
          padding-right: 1rem;
          padding-left: 1rem;
      }
    }
    .top-bar-item {
        padding: 0;
    }
    .top-bar-item, .top-bar-list {
        display: flex;
        align-items: center;
    }
    .dropdown, .dropleft, .dropright, .dropup {
        position: relative;
    }
    .btn-account {
        color: #fff;
        border-left: 1px solid rgba(34,34,48,.12) !important;
    }
    .app-header .btn-account {
        padding: 0 1rem;
        min-width: 12.5rem;
        min-height: 3.5rem;
    }
    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
        cursor: pointer;
    }
    .btn-account {
        position: relative;
        display: flex;
        margin: 0;
        border: 0;
        flex-wrap: none;
        align-items: center;
        background: none;
        color: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        transition: background-color .15s;
        outline: 0;
    }
    button, select {
        text-transform: none;
    }
    button, input {
        overflow: visible;
    }
    button, input, optgroup, select, textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button {
        border-radius: 0;
    }

    .btn-account>.has-badge, .btn-account>.user-avatar {
        margin-right: 0.75rem;
    }
    .user-avatar-md {
        width: 2.25rem;
        height: 2.25rem;
        font-size: 2.25rem;
    }
    .user-avatar {
        position: relative;
        margin-bottom: 0;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        vertical-align: middle;
        border-radius: 4rem;
    }

    .user-avatar audio, .user-avatar canvas, .user-avatar img, .user-avatar video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4rem;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
    }
    img, svg {
        vertical-align: middle;
    }

    img {
        border-style: none;
    }

    .btn-account .account-summary {
        max-width: 10rem;
    }
    .btn-account .account-summary {
        margin-right: 0.75rem;
        display: block;
        text-align: left;
        flex: 1;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
    }

    .btn-account .account-description, .btn-account .account-name {
        margin: 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        line-height: 1rem;
    }

    .btn-account .account-description {
        font-size: .75rem;
        font-weight: 400;
        opacity: .7;
    }
    .btn-account .account-description, .btn-account .account-name {
        margin: 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        line-height: 1rem;
    }

    .btn-account:hover {
        background-color: #f6edd92e;
    }
    .btn-account.hamburger{
        padding: 0 1rem;
        min-width: 1.5rem;
        min-height: 3.5rem
    }
    .btn-account.hamburger {
        border-left: 1px solid rgba(34,34,48,.12) !important;
        border-right: 1px solid rgba(34,34,48,.12) !important;
    }
    .btn-account {
        padding: 0 1rem;
        min-width: 12.5rem;
        min-height: 3.5rem
    }

    .btn-account:focus, .btn-account:hover {
        color: inherit;
        text-decoration: none;
    }

    .navbar{
          padding: 0px !important;
          border: none;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;
    }
    .navbar .container-fluid{
          padding-right: 0px;
    }

    /* USER MENU */

    .dropdown-menu.show {
        -webkit-animation-name: showDropdown;
        animation-name: showDropdown;
    }
    .dropdown-menu.show {
        display: block;
    }
    .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
        right: auto;
        bottom: auto;
    }
    @media (min-width: 768px){
      .dropdown-menu {
          font-size: .875rem;
      }
    }
    .dropdown-menu {
        margin-top: 10px !important;
        border: 0;
        font-weight: 400;
        box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 2px 7px 1px rgb(20 20 31 / 16%);
        -webkit-animation-duration: .2s;
        animation-duration: .2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #363642;
        text-align: left;
        list-style: none;
        background-color: hsla(0,0%,100%,.98);
        background-clip: padding-box;
        border: 1px solid rgba(20,20,31,.15);
        border-radius: 0.25rem;
    }

    .dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
        color: #fff;
        text-decoration: none;
        background-color: #346cb0;
    }
    .navbar a.router-link-exact-active:focus,.navbar a.router-link-exact-active:hover{
        color: #fff;
    }
    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        clear: both;
        font-weight: 400;
        /*color: #191927;*/
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        color: #a6abbd;
    }
    .dropdown-arrow, .dropdown-arrow:before {
        display: block;
        position: absolute;
        left: 0.5rem;
        pointer-events: none;
    }
    .dropdown-arrow {
        top: 0;
        overflow: hidden;
        height: 1.25rem;
        width: 1.25rem;
        z-index: 1001;
        transform: translate3d(0,-100%,0);
    }
    .dropdown-arrow:before {
        content: "";
        bottom: -0.375rem;
        transform: rotate(45deg);
        height: 0.75rem;
        width: 0.75rem;
        margin-left: -5px;
        background-color: hsla(0,0%,100%,.98);
        box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 2px 7px 1px rgb(20 20 31 / 16%);
    }
    .dropdown-arrow, .dropdown-arrow:before {
        display: block;
        position: absolute;
        left: 0.5rem;
        pointer-events: none;
    }

    .bd-footer{
        background-color: #232044; /*2e3333*/
        color: #fff;
    }
    .footer-container{
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 224px;
        background: hsla(0,0%,100%,.1);
        border-radius: 3px;
        padding-top: 10px;
        width: 100%;
        height: 100%;
    }
    .footer-container a{
        text-decoration: none;
        color: #fff;
    }
    .footer-container a:hover,.footer-container a:active,.footer-container a:focus{
        text-decoration: none;
        color: #b85b2d;
    }
    .footer-container .navbar-brand:hover,.footer-container .navbar-brand:active,.footer-container .navbar-brand:focus{
        color: #fff;
    }
    .footer-container h4{
       font-weight: 700;
    }
    .footer-container .text-muted {
        color: #dadadb !important;
    }

    .copyright-div{
        text-align: left;
    }
    .copyright-div p{
        font-size: 15px;
        color: #fff;
    }
    @media (max-width: 768px){
        .copyright-div{
            text-align: center;
        }
    }
</style>
