<template>
  <notifications width="380px"/>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar {
  padding: 30px;
}

/*.navbar a {
  font-weight: bold;
  color: #2c3e50;
}*/

.navbar a.router-link-exact-active {
  color: #346cb0;
}

.vue-notification{
  font-size: 15px !important;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}
</style>
