<template>
    <div class="ps-card">
        <EasyDataTable :headers="headers" :items="newsletterList" :loading="loading" alternating>
            <template #loading>
                <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif" style="width: 100px; height: 80px;"/>
            </template>
            <template #item-enabled="{  }">
                <div class="email-not-verified-wrapper" v-if="!enabled">
                    <button class="user-status-btn not-verified" type="button" @click="verifyUser(userUid,enabled)">
                        <mdicon name="check-decagram" class="calculate-position"/>
                    </button>
                </div>
                <div class="email-not-verified-wrapper" v-else>
                    <button class="user-status-btn verified" type="button" @click="verifyUser(userUid,enabled)">
                        <mdicon name="check-decagram" class="calculate-position"/>
                    </button>
                </div>
            </template>
        </EasyDataTable>
    </div>
</template>

<script>
    import { FirebaseDatabase } from '@/firebase'
    //import { FirebaseAuth } from '@/firebase';
    //import { notify } from "@kyvg/vue3-notification";

    export default {
        name: 'UserListPage',
        data() {
            return{
                headers: [
                    { text: "EMAIL", value: "email" },
                    { text: "TIPOLOGIA", value: "type", sortable: true},
                    { text: "DATA REGISTRAZIONE", value: "privacyAcceptanceDate", sortable: true},
                    { text: "PRIVACY ACCETTATA", value: "privacyAccepted", sortable: true}
                ],
                newsletterList: []
            }
        },
        created: function(){
            var newsletterListRef = FirebaseDatabase.ref("/newsletter");
            this.newsletterList.length = 0;

            newsletterListRef
            .once('value', snapshot => {
                if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                    this.newsletterList.length = 0;
                    snapshot.forEach(newsletterRecord => {
                        this.newsletterList.push(newsletterRecord.val());      
                    });
                }     
            });
        },
        methods:{
        }
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    html{
        background-color: #edeef0;
    }

    .player-wrapper {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-items: center;
    }
    .avator {
        margin-right: 10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    .profile-link{
      cursor: pointer;
      color: #b85b2d;
      text-decoration: none;
    }
    .profile-link:hover,.profile-link:active,.profile-link:focus{
      cursor: pointer;
      color: #b85b2d;
      text-decoration: underline;
    }

    .email-not-verified-btn:active, .email-not-verified-btn:focus, .email-not-verified-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .email-not-verified-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #ababab; /*#1c9cea*/ 
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .email-not-verified-wrapper{
        display: flex;
        justify-content: center;
    }
    .email-verified{
        color: #1c9cea;
        display: flex;
        justify-content: center;
    }

    .user-status-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #ababab; /*#1c9cea*/ 
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .user-status-btn:active, .user-status-btn:focus, .user-status-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .user-status-btn.verified{
        color: #f2740d;
    }
</style>