<template>

        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>    

        <h2>Che cos'è?</h2>
        <p>Il pedigree o certificato genealogico è un documento che attesta la genealogia di un animale, 
            in quanto presenta un elenco completo degli ascendenti paterni e materni, ovvero genitori, nonni, bisnonni e trisnonni.</p>
        <p>In questo documento, oltre all'albero genealogico, sono riportati i i suoi dati anagrafici e morfologici, la razza e le 
            informazioni relative al suo proprietario o allevatore, ed eventuali passaggi di proprietà.</p>

        <h2>Pedigree del cane in Italia</h2>
        <p>In Italia può essere esclusivamente rilasciato dall'<a href="https://www.enci.it/">ENCI</a>, Ente Nazionale Cinofilia Italiana, 
            un'associazione fondata nel lontano 1882 con lo scopo di valorizzare e catalogare le varie razze di cani e organizzare e promuovere eventi relativi agli sport cinofili.</p>
        <p>L'<a href="https://www.enci.it/">ENCI</a> a sua volta è affiliata alla FCI, Federazione Cinofila Internazionale, che raggruppa associazioni cinofile di oltre 88 paesi del mondo. 
            L'associazione riconosce ben 339 razze canine, definite da standard.</p>

        <h2>Pedigree del gatto in Italia</h2>
        <p>Il gatti, proprio come i cani, possono ottenere un certificato genealogico, a patto di appartenere a una razza riconosciuta.</p>
        <p>In Italia, il Ministero delle Politiche Agricole Alimentari e Forestali ha riconosciuto l'<a href="https://www.anfitalia.it/site/">ANFI</a>, Associazione Nazionale Felina Italiana, 
            come ente preposto al rilascio di questo documento.</p>
        <p>Il pedigree del gatto contiene tutte le informazioni circa la genealogia dell'animale (4 o più generazioni) e tutti i suoi dati anagrafici e morfologici.</p>
        
        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>    

        <h2>Cosa comporta un cane senza pedigree?</h2>
        <p>Secondo la legge italiana, Decreto Legislativo n. 529 del 30 dicembre 1992, è vietata la vendita di cani di razza senza l'attestazione di un pedigree.</p>
        <p>Ne consegue che tutti i cani venduti senza documento genealogico, anche se ne presentano la morfologia tipica, non possono essere considerati di razza, ma sono a tutti gli effetti dei meticci.</p>
        <p>Questi cani non possono, quindi, partecipare a manifestazioni ufficiali <a href="https://www.enci.it/">ENCI</a>. Nel caso si accoppino con un soggetto con pedigree, 
            i cuccioli non possono essere iscritti nel libro genealogico in maniera automatica.</p>

        <h2>Richiedere il pedegree di un cucciolo</h2>
        <p>Il pedigree di un cucciolo, i cui genitori sono soggetti registrati, deve essere richiesto entro 25 giorni dalla sua nascita. 
            Si fa tramite una dichiarazione di avvenuta monta e nascita scaricabile dal sito stesso dell'<a href="https://www.enci.it/">ENCI</a>.</p>
        <p>Su questo documento devono essere indicati i dati della madre, del padre e il numero di cuccioli nati divisi in maschi e femmine. In caso vi siano dei cuccioli morti, 
            va inserito anche questo dato.</p>
        <p>Se la presentazione di questa domanda avviene dopo 25 giorni dal parto sarà necessario pagare una maggiorazione.</p>
        
        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>    

        <h2>Richiedere il pedegree di un cane adulto</h2>
        <p>In alcuni casi, e solo per un ristretto numero di razze canine, è possibile richiedere l'iscrizione di un cane adulto di cui non si conosce la genealogia al Registro Supplementare 
            Riconosciuti, tenuto sempre dall'<a href="https://www.enci.it/">ENCI</a>.</p>
        <p>L'iscrizione è riservata a cani che presentano caratteristiche tipiche di una razza e a cani iscritti a un libro genealogico straniero riconosciuto dall'<a href="https://www.enci.it/">ENCI</a>.</p>

        <h2>Quanto costa?</h2>
        <p>Spesso si crede, erroneamente, che il certificato genealogico abbia un costo notevole. Questo non è affatto vero.</p>
        <p>Sul sito dell'<a href="https://www.enci.it/">ENCI</a> sono riportati in maniera trasparente ed esaustiva tutti i costi relativi alla richiesta e ottenimento di un pedigree. 
            Facendo un rapido calcolo il tutto ammonta a poche decine di euro.</p>

        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>    
</template>

<script>

    export default {
        name: 'PedigreePage',
        components:{
        },
        data() {
            return{
            }
        },
        created: function(){
        },
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .page-header{
        padding: 251px 20px;
        background-size: cover !important;
        background-position: center !important;
    }
    .page-header h2{
        font-size: 60px;
        line-height: 48px;
        color: #fff;
        text-align: left;
        font-weight: 700;
    }

    .page-body{
        background-color: #edeef0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
    }
    .page-body h2{
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body h4{
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body a{ 
        color: #b85b2d;
        text-decoration: none;
    }

    .image-quote{
        position: absolute;
        color: #fff;
    }

    html{
        background-color: #edeef0;
    }

    @media (max-width: 768px){
        .page-header h2{
            font-size: 30px;
        }
        .page-header{
            padding: 60px 20px;
        }
    }
</style>