<template>
    <div class="ps-card">
        <h2>Benvenuto, qui potrai vedere in futuro le statistiche del tuo account.</h2>
    </div>
</template>

<script>
    export default {
        name: 'WelcomePage',
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    html{
        background-color: #edeef0;
    }
</style>