<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->

  <nav class="navbar bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img src="../../assets/logo.jpg" height="35" alt="petshelter-logo"/> <span class="logo-span">PetShelter</span></a>
      <!--<UserboxView></UserboxView>-->
    </div>
  </nav>

  <div class="main-search-content">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9">
              <div class="wrap-search-content">
                <h2 class="search-title">Cosa cerchi?</h2>
              </div>
            </div>
            <div class="col-12 col-lg-9 search-bar-column" id="filters">
              <div class="wrap-search-content">
                <div class="ps-card search">
                  <div class="row">
                      <div class="col-12 col-md-6 position-div">
                            <GMapAutocomplete id="exampleFormControlInput1" class="form-control form-control-lg" 
                                                      placeholder="Inserisci il tuo indirizzo es. Via Rossi, 14"
                                                      @place_changed="setPlace" @change="placeOnInputDigit" 
                                                      ref="autocompleteInputRef" :value="searchFilters.searchAddress"
                                                      :options="{
                                                        types: ['geocode'],
                                                        componentRestrictions: {country: 'it'}
                                                      }">
                              </GMapAutocomplete>
                              <button class="calculate-position-btn" type="button" @click="getCurrentPosition">
                                <mdicon name="crosshairs" class="calculate-position"/>
                              </button>
                        </div>
                        <div class="col-12 col-md-3 animal-type-div">
                              <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" 
                                          v-model="searchFilters.anymalType" @change="loadRaces">
                                  <option selected>Seleziona la tipologia</option>
                                  <option value="Cane">Cane</option>
                                  <option value="Gatto">Gatto</option>
                                  <option value="Entrambi">Entrambi</option>
                              </select>
                        </div>
                        <div class="col-12 col-md-3 animal-type-div">
                              <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.animalRace">
                                  <option selected>Razza</option>
                                  <option v-for="(r,index) in raceList" v-bind:key="index">{{ r.raceName }}</option>            
                              </select>
                        </div>
                        <div class="col-12 col-md-12 search-btn-div d-xl-none d-md-block d-sm-block">
                            <button class="btn" type="button" @click="doSearch">
                                Cerca
                            </button>
                            <button class="btn" type="button" @click="showFilters">
                                <mdicon name="filter-cog-outline"/>Altri filtri
                            </button>
                            <!--<router-link class="btn back-btn" to="/">Torna alla homepage</router-link>-->
                        </div>                         
                    </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-9 order-2 order-lg-2">
                <div class="wrap-search-content">
                    <div class="row" id="search-view">
                        <router-view />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 order-1 order-lg-2 mb-5 d-none d-xl-block d-md-block"> <!--d-none d-xl-block d-md-block-->
                <div class="ps-card">
                    <h4>Filtri ricerca</h4>
                    <div class="row">
                      <div class="col-12 col-md-12 mt-2">
                          <label for="customRange2" class="form-label">Raggio Km</label>
                          <input v-model="searchFilters.radiusSearch" type="range" class="form-range" min="1" max="1000" id="customRange2">
                          <p>{{ searchFilters.radiusSearch }}</p>
                      </div>
                      <div class="col-12 col-md-12 mt-2 mb-2">
                          <label for="customRange2" class="form-label">Prezzo(€)</label>
                          <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.fromImport">
                              <option selected>da Qualsiasi</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                              <option value="1500">1500</option>
                              <option value="2000">2000</option> 
                              <option value="2500">2500</option>
                              <option value="3000">3000</option>
                              <option value="3500">3500</option>   
                          </select>
                          <select class="form-select form-select-lg mt-2" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.toImport">
                              <option selected>a Qualsiasi</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                              <option value="1500">1500</option>
                              <option value="2000">2000</option> 
                              <option value="2500">2500</option>
                              <option value="3000">3000</option>
                              <option value="3500">3500</option>           
                          </select>
                      </div>
                      <div class="col-12 col-md-12 mt-2 mb-5">
                          <label for="customRange2" class="form-label">Età</label>
                          <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.age">
                              <option value="" selected>Qualsiasi età</option>
                              <option value="Cucciolo (3 mesi)">Cucciolo (3 mesi)</option> 
                              <option value="Adulto (1 - 7 anni)">Adulto (1 - 7 anni)</option>   
                              <option value="Anziano (7+ anni)">Anziano (7+ anni)</option>   
                          </select>
                      </div>
                      <div class="col-12 col-md-12">
                            <button class="btn" type="button" @click="doSearch">
                                Cerca
                            </button>
                            <!--<router-link class="btn back-btn" to="/">Torna alla homepage</router-link>-->
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <!-- Modal -->
  <div>
      <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title">Filtri ricerca</h1>
            <button class="close-btn" type="button" @click="closeModal">
                  <mdicon name="window-close" class="calculate-position"/>
            </button>
          </div>
          <div class="container modal-body">
                    <div class="row">
                      <div class="col-12 col-md-12 mt-2">
                          <label for="customRange2" class="form-label">Raggio Km</label>
                          <input v-model="searchFilters.radiusSearch" type="range" class="form-range" min="1" max="1000" id="customRange2">
                          <p>{{ searchFilters.radiusSearch }}</p>
                      </div>
                      <div class="col-12 col-md-12 mt-2 mb-2">
                          <label for="customRange2" class="form-label">Prezzo(€)</label>
                          <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.fromImport">
                              <option value="" selected>da Qualsiasi</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                              <option value="1500">1500</option>
                              <option value="2000">2000</option> 
                              <option value="2500">2500</option>
                              <option value="3000">3000</option>
                              <option value="3500">3500</option>   
                          </select>
                          <select class="form-select form-select-lg mt-2" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.toImport">
                              <option value="" selected>a Qualsiasi</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                              <option value="1500">1500</option>
                              <option value="2000">2000</option> 
                              <option value="2500">2500</option>
                              <option value="3000">3000</option>
                              <option value="3500">3500</option>           
                          </select>
                      </div>
                      <div class="col-12 col-md-12 mt-2 mb-5">
                          <label for="customRange2" class="form-label">Età</label>
                          <select class="form-select form-select-lg" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="searchFilters.age">
                              <option value="" selected>Qualsiasi età</option>
                              <option value="Cucciolo (3 mesi)">Cucciolo (3 mesi)</option> 
                              <option value="Adulto (1 - 7 anni)">Adulto (1 - 7 anni)</option>   
                              <option value="Anziano (7+ anni)">Anziano (7+ anni)</option>   
                          </select>
                      </div>
                      <div class="col-12 col-md-12 search-btn-div">
                          <button class="btn" type="button" @click="doSearch">
                                  Vedi risultati
                          </button>   
                      </div>  
                  </div>        
          </div>        
      </vue-final-modal>
    </div>

  <FooterView></FooterView>
  
</template>

<script>

import {FirebaseDatabase} from "@/firebase";
//import UserboxView from '../../components/account/UserBoxView.vue';
import FooterView from '../../components/FooterPage.vue';
import VueCookies from 'vue-cookies';
import { VueFinalModal } from 'vue-final-modal';

export default {
  name: 'SearchIndexPage',
  data() {
     return{
        searchFilters : {
            searchAddress: null,
            radiusSearch: 15, //di default metto 15
            animalType: "Entrambi",
            animalRace: "Razza",
            lat: null,
            lng: null,
            fromImport: null,
            toImport: null,
            age: null
        },
        raceList: [],
        gmapsApiKey: "AIzaSyDPOTuP9waQYCayI0TJ30Bk94an6DSJHTU",
        showModal: false,
     }
  },
  components: {
    FooterView,
    VueFinalModal
    //UserboxView
  },
  created: function(){
    this.searchFilters.radiusSearch = VueCookies.get('srch_radius');
    this.searchFilters.searchAddress = VueCookies.get('srch_address_name');
    this.searchFilters.lat = VueCookies.get('srch_address_lat');
    this.searchFilters.lng = VueCookies.get('srch_address_lng');

    if(this.searchFilters.radiusSearch == undefined || this.searchFilters.radiusSearch == null){
        VueCookies.set('srch_radius' , "15", "1d");
        this.searchFilters.radiusSearch = 15;
    }
    this.searchFilters.anymalType = VueCookies.get('srch_animal_type');
    if(this.searchFilters.anymalType == undefined){
        VueCookies.set('srch_anymal_type' , "Entrambi", "1d");
        this.searchFilters.anymalType = "Entrambi";
    }else{
        this.loadRaces();
    }
    this.searchFilters.animalRace = VueCookies.get('srch_animal_race');
    if(this.searchFilters.animalRace == undefined){
      VueCookies.set('srch_anymal_type' , "Razza", "1d");
        this.searchFilters.animalRace = "Razza";
    }

    this.searchFilters.toImport = VueCookies.get('srch_toImport');
    if(this.searchFilters.toImport == undefined){
      VueCookies.set('srch_toImport' , "", "1d");
      this.searchFilters.toImport = "";
    }
    this.searchFilters.fromImport = VueCookies.get('srch_fromImport');
    if(this.searchFilters.fromImport == undefined){
      VueCookies.set('srch_fromImport' , "", "1d");
      this.searchFilters.fromImport = "";
    }
    this.searchFilters.age = VueCookies.get('srch_animal_eta');
    if(this.searchFilters.age == undefined){
      VueCookies.set('srch_animal_eta' , "", "1d");
      this.searchFilters.age = "";
    }
  },
  methods:{
    closeModal(){
        this.showModal = false;
    },
    showFilters(){
        this.showModal = true;
    },
    getCurrentPosition(){
      this.$getLocation()
      .then((coordinates) => {
        this.searchFilters.lat = coordinates.lat;
        this.searchFilters.lng = coordinates.lng;
        this.reverseGeocodeCurrentAddress(coordinates.lat,coordinates.lng);           
      })
      .catch((error) => {
        console.log(error);
      });  
      this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');     
    },
    reverseGeocodeCurrentAddress(lat,lng){
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng='+ lat +','+ lng +'&key=' + this.gmapsApiKey)
        .then(response => response.json())
        .then(data => (
            this.searchFilters.searchAddress = data.results[0].formatted_address
        ));        
    },
    placeOnInputDigit(){
        this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');
        this.searchFilters.searchAddress = this.$refs.autocompleteInputRef.$refs.input.value;
    },
    setPlace(){
        this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');
        this.searchFilters.searchAddress = this.$refs.autocompleteInputRef.$refs.input.value;

        if(this.searchFilters.searchAddress != ""){
            fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+ this.searchFilters.searchAddress +'&key=' + this.gmapsApiKey)
            .then(response => response.json())
            .then(data => (
                this.searchFilters.lat = data.results[0].geometry.location.lat,
                this.searchFilters.lng = data.results[0].geometry.location.lng
            ));
        }           
    },
    doSearch(){
        VueCookies.set('srch_radius' , this.searchFilters.radiusSearch, "1d");
        VueCookies.set('srch_animal_type' , this.searchFilters.anymalType, "1d");
        VueCookies.set('srch_animal_race' , this.searchFilters.animalRace, "1d");

        VueCookies.set('srch_toImport' , this.searchFilters.toImport, "1d");
        VueCookies.set('srch_fromImport' , this.searchFilters.fromImport, "1d");
        VueCookies.set('srch_animal_eta' , this.searchFilters.age, "1d");

        if(this.searchFilters.searchAddress != null && this.searchFilters.searchAddress != ""){
          this.$refs.autocompleteInputRef.$el.classList.remove('is-invalid');

          VueCookies.set('srch_address_lat' , this.searchFilters.lat, "1d");
          VueCookies.set('srch_address_lng' , this.searchFilters.lng, "1d");
          VueCookies.set('srch_address_name' , this.searchFilters.searchAddress, "1d");

          /*SALVO LA RICERCA EFFETTUATA*/
          var searchUid = this.$route.params.id;
          var searchRef = FirebaseDatabase.ref("/searchRequests");

          searchRef
          .orderByChild("uid")
          .equalTo(searchUid)
          .on('value' , snapshot => {
              if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                var key = Object.keys(snapshot.val())[0];
                searchRef.child(key).update({
                    uid : searchUid,
                    lat : this.searchFilters.lat,
                    lng : this.searchFilters.lng,
                    addressName : this.searchFilters.searchAddress,
                    animalRequestAge : this.searchFilters.age,
                    animalRequestType : this.searchFilters.anymalType,
                    animalRequestRace : this.searchFilters.animalRace,
                    toImport : this.searchFilters.toImport,
                    fromImport : this.searchFilters.fromImport,
                    emailContact : null

                })
                .then(() => {
                    this.$router.go();
                }).catch(() => { //error
                    this.$router.go();
                });
              }else{
                searchRef.push({
                    uid : searchUid,
                    lat : this.searchFilters.lat,
                    lng : this.searchFilters.lng,
                    addressName : this.searchFilters.searchAddress,
                    animalRequestAge : this.searchFilters.age,
                    animalRequestType : this.searchFilters.anymalType,
                    animalRequestRace : this.searchFilters.animalRace,
                    toImport : this.searchFilters.toImport,
                    fromImport : this.searchFilters.fromImport,
                    emailContact : null

                })
                .then(() => {
                    this.$router.go();
                }).catch(() => { //error
                    this.$router.go();
                });
              }
          });
          
        }else{
          this.$refs.autocompleteInputRef.$el.classList.add('is-invalid');
        }
        //var searchToken = Math.random().toString(16).slice(2);

        //this.$router.replace('/search/' + searchToken);
        
    },
    loadRaces(){
        var animalRacesRef = FirebaseDatabase.ref("/animalRaces");
        this.raceList.length = 0;

        if(this.searchFilters.anymalType == "Entrambi"){
          animalRacesRef
          .on('value' , snapshot => {
            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                snapshot.forEach(race => {
                    this.raceList.push(race.val());               
                });
            }     
          });
        }else{
          animalRacesRef
          .orderByChild("animalType")
          .equalTo(this.searchFilters.anymalType)
          .on('value' , snapshot => {
            if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                snapshot.forEach(race => {
                    this.raceList.push(race.val());               
                });

                this.raceList.sort(function (a, b) {
                                    if (a.raceName < b.raceName) {
                                        return -1;
                                    }
                                    if (a.raceName > b.raceName) {
                                        return 1;
                                    }
                                    return 0;
                                    });
            }     
          });
        }
    }
  }
}
</script>

<style scoped>
    .wrap-search-content{
        padding-left: 25px;
        padding-right: 25px;
    }
    .main-search-content{
        padding-top: 60px;
        background-color: #edeef0 !important;
        padding-bottom: 60px;
    }

    .ps-card{
        background-color: #fff;
        border-radius: 4px;
        z-index: 102;
        -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
        box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
    }
    .ps-card label{
      font-weight: 600;
      display: flex;
    }
    .ps-card .form-select, ::v-deep .modal-content .form-select{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 48px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px;
      border: 1px solid #e8ebeb;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      box-shadow: inset 0 2px 4px rgb(0 0 0 / 5%), inset 0 0 0 100px #fff;
      -webkit-transition-property: -webkit-box-shadow;
      transition-property: -webkit-box-shadow;
      transition-property: box-shadow;
      transition-property: box-shadow,-webkit-box-shadow;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    .ps-card.search {
        max-height: 180px; /*270px on mobile*/
        min-height: 100px;
        height: 100%;
        position: initial;
        width: 100%;
    }
    .ps-card.search h4{
        text-align: left;
    }
    .ps-card.search p{
        text-align: left;
        margin-top: 10px;
    }
    .ps-card.search .link-span{
      cursor: pointer;
      color: #b85b2d;
      text-decoration: none;
    }

    .ps-card.search .calculate-position-btn{
        bottom: 8px;
    }

    .search-btn-div{
      display: flex;
      gap: 10px;
    }
    .back-btn{
      background: #fae7be !important;
      color: #736c6c !important;
      border: 1px solid #ffe19d !important;
    }

    .search-bar-column{
      margin-bottom: 50px;
    }
    .search-title{
      font-weight: 700;
      font-size: 20px;
      text-align: left;
    }

    ::v-deep .modal-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ::v-deep .modal-content{
      display: flex;
      flex-direction: column;
      height: 100%;
      /*margin: 0 1rem;
      padding: 1rem;
      border: 1px solid #e2e8f0;
      border-radius: 0.25rem;
      max-width: 560px;*/
      background: #fff;
    }
    ::v-deep .modal-content label{
      display: flex;
      font-weight: 600;
    }

    .close-btn.focus, .close-btn:focus, .close-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #b85b2d;
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .modal-title{
        font-size: 16px;
        line-height: 22px;
        color: #2e3333;
        font-weight: bold;
        margin: auto;
    }
    .modal-header{
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 10px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-color: rgba(0,0,0,.08);
    }
    .modal-body{
        padding: 20px 20px;
    }

    .modal-content .btn{
      color: #fff;
      background: #b85b2d;
      min-height: 48px;
      padding: 12px 24px;
      border: none;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 4px;
      font-weight: 600;
      font-family: plex-sans,sans-serif;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      line-height: 24px;
      margin: 0;
      display: inline-block;
      cursor: pointer;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      -webkit-transition-property: background;
      transition-property: background;
      transition: 0.5s;
      width: 100%;
    }
    .modal-content .btn:hover{
      filter: brightness(85%);
      transition: 0.3s ease-in;
    }

    @media (max-width: 768px){
        .search-bar-column{
            padding: 0px;
            margin-bottom: 40px;
        }
        .ps-card.search{
            width: 100%;
            min-height: 275px;
            border-radius: 0px;
        }
        .wrap-search-content{
          padding: 0;
        }
        .favourite-btn[data-v-692c625b] {
            bottom: 0px;
        }
        .title-div{
          margin-top: 10px;
        }
        .ps-card.search .calculate-position-btn{
            bottom: 8px;
        }

  
    }
</style>
