<template>
    <div class="registration-div" v-if="registrationStep==1">
        <div class="row mt-3">
            <div class="col-12 col-md-12">
                <input type="text" placeholder="Email" v-model="email"
                :class="[isEmailValid == null ? inputDefaultClass : (isEmailValid ? invalidInputClass : validInputClass)]" 
                    @focusout="validateEmail(email)" @change="validateEmail(email)">
                <div class="invalid-feedback">
                                Inserire un indirizzo email valido.
                </div>
            </div>
            <div class="col-12 col-md-12 mt-3 password-div">
                    <input :type="passwordType1" placeholder="Password" v-model="password1" @change="validatePassword1(password1);validatePassword2(password2);"
                    @input="validatePassword1(password1);validatePassword2(password2);" :class="[isPassword1Valid == null ? inputDefaultClass : (isPassword1Valid ? invalidInputClass : validInputClass)]">
                    <mdicon name="eye" class="showPassword" v-if="!isPassword1Visible" @click="switchPasswordVisibility(1)"/>
                    <mdicon name="eye-off" class="showPassword" v-else @click="switchPasswordVisibility(1)"/>
                    <div class="invalid-feedback">
                        Inserire una password valida.
                    </div>
            </div>
            <div class="col-12 col-md-12 mt-3 password-div">
                    <input :type="passwordType2" placeholder="Conferma password" v-model="password2" @change="validatePassword2(password2)"
                    :class="[isPassword2Valid == null ? inputDefaultClass : (isPassword2Valid ? invalidInputClass : validInputClass)]">
                    <mdicon name="eye" class="showPassword" v-if="!isPassword2Visible" @click="switchPasswordVisibility(2)"/>
                    <mdicon name="eye-off" class="showPassword" v-else @click="switchPasswordVisibility(2)"/>
                    <div class="invalid-feedback">
                        La password non combacia.
                    </div>
            </div>
            <div class="col-12 col-md-12 mt-2">
                <datepicker placeholder="Data di nascita" class="mt-3" format="dd/MM/yyyy" inputFormat="yyyy-MM-dd" language="it" 
                @selected="calculateAge" v-bind:class="birthDayClass"></datepicker>
                <div class="invalid-feedback">
                    É necessario avere 18 anni per accedere al servizio.
                </div>
            </div>
            <div class="wrapper mt-3">
                <input type="radio" name="select" id="option-1" value="almn" v-model="userType" checked>
                <input type="radio" name="select" id="option-2" value="usr" v-model="userType">
                <label for="option-1" class="option option-1 noselect">
                    <mdicon name="paw" />
                    <span>Allevatore</span>
                </label>
                <label for="option-2" class="option option-2 noselect">
                    <mdicon name="account" />
                    <span>Utente</span>
                </label>
            </div>
            
            <div class="col-12 col-md-12 mt-2">
                <label class="pet-info">
                    Abbiamo aperto per ora le iscrizioni agli allevatori, ma rilasceremo presto il servizio anche a tutte le altre attività. 
                    Consigliamo a quest' ultimi di registrarsi come utenti, in quanto sarà possibile in seguito fare il passaggio di tipologia, 
                    avendo già una buona parte di dati registrati.
                </label>
            </div>

            <a href="https://www.iubenda.com/privacy-policy/23470730" class="passwordForget noselect" target="_blank">Privacy ...</a>
            <a href="https://www.iubenda.com/privacy-policy/23470730" class="passwordForget noselect" target="_blank">Termini e condizioni ...</a>
            <div class="col-12 col-md-12 mt-3 mb-3">
                <button type="button" class="btn btn-primary"
                :disabled="((isEmailValid == null || isEmailValid == false) || (isPassword1Valid == null || isPassword1Valid == false) || (isPassword2Valid == null || isPassword2Valid == false) 
                || (birthDateIsError == null || birthDateIsError == true))"
                @click="createUser">Registrati</button>
            </div>                        
        </div>
        <span>o</span>
        <br/>
        <span>registrati con</span>
        <br/>
        <br/>
        <div class="login-social-buttons">
                <div class="method">
                    <div class="method-control google">
                        <a href="#" class="method-action">
                            <img src="../../assets/g-logo.png" alt="Google-logo" width="18">
                            <span class="login-google-span">Registrati con Google</span>
                        </a>
                    </div>
                    <div class="method-control facebook">
                        <a href="#" class="method-action">
                            <mdicon name="facebook" class="ion ion-logo-facebook" />
                            <span>Registrati con Facebook</span>
                        </a>
                    </div>
                    <div class="method-control apple">
                        <a href="#" class="method-action">
                            <mdicon name="apple" class="ion ion-logo-apple" />
                            <span>Registrati con Apple</span>
                        </a>
                    </div>
                </div>
        </div>
        <p>Hai già un account? <router-link to="/account" class="passwordForget">Accedi</router-link></p>
    </div>
    <div class="row mt-3" v-else>
        <div class="col-12 col-md-12 mt-5 mb-3">
            <img src="../../assets/email-sent.jpg" alt="email-sent-picture" class="rounded-circle" width="250"/>
        </div>
        <div class="col-12 col-md-12">
            <h2><strong>Per favore controlla la tua casella di posta.</strong></h2>
            <p>Ti abbiamo inviato un link per poter confermare il tuo indirizzo di posta elettronica, se non dovessi trovarlo controlla anche lo spam.</p>
            <p>Una volta trovata, puoi chiudere questa finestra.</p>
        </div>    
    </div>
</template>

<script>

import {FirebaseAuth} from "@/firebase";
import {FirebaseDatabase} from "@/firebase";
import Datepicker from 'vuejs3-datepicker';
import { notify } from "@kyvg/vue3-notification";

export default {
  name: 'AccountRegisterPage',
  components: {
    Datepicker
  },
  data() {
    return{
        email: "",
        password1: "",
        password2: "",
        userType: "almn", //default type Allevatore
        passwordType1: "password",
        passwordType2: "password",
        isPassword1Visible: false,
        isPassword2Visible: false,
        isEmailValid: null,
        isPassword1Valid: null,
        isPassword2Valid: null,
        birthDateValue: null,
        age: 0,
        birthDateIsError: null,
        notificationTitle: "",
		notificationMessage: "",
        inputDefaultClass: 'form-control',
        validInputClass: 'form-control is-invalid',
        invalidInputClass: 'form-control is-valid',
        registrationStep: 1
    }
  },
  methods: {
    switchPasswordVisibility(password){
        if(password == 1){
            this.isPassword1Visible = !this.isPassword1Visible;
            if(this.isPassword1Visible){
                this.passwordType1 = "text";
            }else{
                this.passwordType1 = "password";
            }
        }else{
            this.isPassword2Visible = !this.isPassword2Visible;
            if(this.isPassword2Visible){
                this.passwordType2 = "text";
            }else{
                this.passwordType2 = "password";
            }
        }
        
    },
    validatePassword1(passwordToCheck) {

        var reg = /^[a-zA-Z0-9!@#$&()-`.+,/\"]{8,}$/; //eslint-disable-line

        if (reg.test(passwordToCheck)) {
          this.isPassword1Valid = true;
        } else {
          this.isPassword1Valid = false;
        }
    },
    validatePassword2(passwordToCheck) {

        if (passwordToCheck == this.password1) {
          this.isPassword2Valid = true;
        } else {
          this.isPassword2Valid = false;
        }
    },
    validateEmail(emailToCheck) {
        var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
        
        if (reg.test(emailToCheck)) {
            FirebaseAuth.fetchSignInMethodsForEmail(emailToCheck).then((result) => {
                this.isEmailValid = result.length > 0 ? false : true;
			}).catch(() => { //error
                this.isEmailValid = false;			
			});
        } else {
          this.isEmailValid = false;
        }

        
    },
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
    calculateAge(payload) {
        var today = new Date();
        var birthDate = new Date(payload);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        
        this.birthDateIsError = (age != null && age < 18) ? true : false;
        this.birthDateValue = this.formatDate(payload);
        this.age = age;
    },
    createUser() {
        let _this = this;

        FirebaseAuth.createUserWithEmailAndPassword(this.email,this.password1)
        .then((result) => {
            var usersRef = FirebaseDatabase.ref("/users");
            usersRef.push({
                userUid: result.user.uid,
                birthdayDate: this.birthDateValue,
                age: this.age,
                type: this.userType,
                userEmail: FirebaseAuth.currentUser.email,
                isEmailConfirmed: false,
                enabled: false //campo che serve per evitare allevamenti o utenti business creati a caso
            })
            .then(() => {
                _this.notificationTitle = "Successo";
                _this.notificationMessage = "Operazione avvenuta con successo!";

                notify({
                    type: 'success',
                    title: _this.notificationTitle,
                    text: _this.notificationMessage,
                });

                // now we have access to the signed in user
                ////const user = FirebaseAuth.currentUser; //SIMONE COMMENTATO MOMENTANEAMENTE
                // send the signed in user a verification email
                ////user.sendEmailVerification(); //SIMONE COMMENTATO MOMENTANEAMENTE

                this.registrationStep = 2;

            }).catch(() => { //error
                _this.notificationTitle = "Errore";
                _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

                notify({
                    type: 'error',
                    title: _this.notificationTitle,
                    text: _this.notificationMessage,
                }); 			
            });

		}).catch(() => { //error
            _this.notificationTitle = "Errore";
            _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

            notify({
                type: 'error',
                title: _this.notificationTitle,
                text: _this.notificationMessage,
            }); 			
		});
    }
  },
  computed: {
    birthDayClass() {
        if(this.birthDateIsError == null){
            return "form-control";
        }
        return (!this.birthDateIsError ? "form-control is-valid" : "form-control is-invalid");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .ion-logo-apple {
        /*font-size: 1.65rem;*/
        line-height: inherit;
        margin-right: 0.5rem;
        /*color: var(--color-black);*/
    }
    .ion-logo-facebook {
        /*font-size: 1.65rem;*/
        line-height: inherit;
        margin-right: 0.5rem;
        color: #1a73e8;
        /*color: var(--color-blue);*/
    }

    .login-social-buttons .method-control {
        margin-bottom: 1rem;
    }
    .login-social-buttons .method-action {
        font-family: inherit;
        /*font-size: 0.95rem;*/
        font-weight: 500;
        line-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0.35rem 1.25rem;
        outline: none;
        border: 2px solid black;
        border-radius: 2rem;
        color: var(--color-black);
        background: var(--color-white);
        text-rendering: optimizeLegibility;
        transition: all 0.35s ease;
        background-clip: padding-box;
        box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
        height: 40px;
    }
    .method-action{
        text-decoration: none;
    }
    .method-control.google{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.facebook{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.apple{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .login-google-span{
        margin-left: 24px;
    }
    .login-social-buttons .method-action:hover {
        background: var(--color-light);
    }


    .wrapper{
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 15px;
    }
    .wrapper .option{
        background: #fff;
        height: 100%;
        width: 100%;
        display: flex;
        /*align-items: center;*/
        justify-content: center;
        margin: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px 10px;
        border: 2px solid lightgrey;
        transition: all 0.3s ease;
    }
    .wrapper .option .dot{
        height: 20px;
        width: 20px;
        background: #d9d9d9;
        border-radius: 50%;
        position: relative;
    }
    .wrapper .option .dot::before{
        position: absolute;
        content: "";
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        background: #b85b2d;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.5);
        transition: all 0.3s ease;
    }
    input[type="radio"]{
        display: none;
    }
    #option-1:checked:checked ~ .option-1,
    #option-2:checked:checked ~ .option-2{
        border-color: #b85b2d;
        background: #b85b2d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }
    #option-1:checked:checked ~ .option-1 .dot,
    #option-2:checked:checked ~ .option-2 .dot{
        background: #fff;
    }
    #option-1:checked:checked ~ .option-1 .dot::before,
    #option-2:checked:checked ~ .option-2 .dot::before{
        opacity: 1;
        transform: scale(1);
    }
    .wrapper .option span{
        font-size: 20px;
        color: #808080;
        margin-right: 5px;
    }
    #option-1:checked:checked ~ .option-1 span,
    #option-2:checked:checked ~ .option-2 span{
        color: #fff;
    }

    .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

    .showPassword{
        position: absolute;
        right: 45px;
        bottom: 10px;
        color: #837575;
        cursor: pointer;
    }
    .form-control.is-invalid + .showPassword{
        position: absolute;
        right: 45px;
        bottom: 35px;
        color: #837575;
        cursor: pointer;
    }
    .password-div{
        position: relative;
    }

    .pet-info{
        background-color: transparent;
        border: 2px solid #d8d8d8;
        width: 100%;
        padding: 15px 25px;
        border-radius: 15px;
        color: #808080;
        box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
    }
</style>
