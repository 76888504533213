<template>
    <div class="ps-card">
        <EasyDataTable :headers="headers" :items="userList" :loading="loading" alternating>
            <template #loading>
                <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif" style="width: 100px; height: 80px;"/>
            </template>
            <template #item-nominative="{ nominative }">
                <div class="player-wrapper">
                    <img class="avator" :src="userImage" alt="user image" /> <!--:src="avator"-->
                    <span>{{ nominative }}</span>
                </div>
            </template>
            <template #item-userUid="{ userUid }">
                <div class="player-wrapper">
                    <router-link class="profile-link" target="_blank" :to="'/profilo/'+ userUid">{{ userUid }}</router-link>
                </div>
            </template>
            <template #item-isEmailConfirmed="{ isEmailConfirmed }">
                <div class="email-not-verified-wrapper" v-if="!isEmailConfirmed">
                    <button class="email-not-verified-btn" type="button" @click="sendEmailVerificationRequest">
                        <mdicon name="email-remove" class="calculate-position"/>
                    </button>
                </div>
                <div v-else>
                    <mdicon name="email-check" class="email-verified"/>
                </div>
            </template>
            <template #item-enabled="{ enabled, userUid }">
                <div class="email-not-verified-wrapper" v-if="!enabled">
                    <button class="user-status-btn not-verified" type="button" @click="verifyUser(userUid,enabled)">
                        <mdicon name="check-decagram" class="calculate-position"/>
                    </button>
                </div>
                <div class="email-not-verified-wrapper" v-else>
                    <button class="user-status-btn verified" type="button" @click="verifyUser(userUid,enabled)">
                        <mdicon name="check-decagram" class="calculate-position"/>
                    </button>
                </div>
            </template>
            <template #item-verificationStatus="{ verificationStatus }">
                <div class="email-not-verified-wrapper" v-if="!verificationStatus">
                    <button class="user-status-btn not-verified" type="button">
                        <mdicon name="alert-decagram" class="calculate-position"/>
                    </button>
                </div>
                <div class="email-not-verified-wrapper" v-else>
                    <button class="user-status-btn verified" type="button">
                        <mdicon name="alert-decagram" class="calculate-position"/>
                    </button>
                </div>
            </template>
        </EasyDataTable>
    </div>
</template>

<script>
    import { FirebaseDatabase } from '@/firebase'
    //import { FirebaseAuth } from '@/firebase';
    import { notify } from "@kyvg/vue3-notification";

    export default {
        name: 'UserListPage',
        data() {
            return{
                headers: [
                    { text: "NOMINATIVO", value: "nominative" },
                    { text: "UUID", value: "userUid"},
                    { text: "TIPOLOGIA", value: "type", sortable: true},
                    { text: "DATA REGISTRAZIONE", value: "registrationDate", sortable: true},
                    { text: "DATA DI NASCITA", value: "birthdayDate"},
                    { text: "ANNI", value: "age"},
                    { text: "EMAIL CONFERMATA", value: "isEmailConfirmed", sortable: true},
                    { text: "ABILITATO", value: "enabled", sortable: true},
                    { text: "RICHIESTA DI VERIFICA", value: "verificationStatus", sortable: true}
                ],
                items: [
                    {
                    id: 1,
                    player: "Stephen Curry",
                    page: "https://www.nba.com/player/201939/stephen-curry",
                    avator:
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ULbkSJGizsSKqowXW0aDlmtWdS-lSf-wdK0E5rQ&s",
                    teamName: "GSW",
                    teamUrl: "https://www.nba.com/team/1610612744/warriors",
                    number: 30,
                    position: "G",
                    info: {
                        height: "6-2",
                        weight: 185,
                    },
                    lastAttended: "Davidson",
                    country: "USA",
                    },
                    {
                    id: 2,
                    player: "Lebron James",
                    page: "https://www.nba.com/player/2544/lebron-james",
                    avator:
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ULbkSJGizsSKqowXW0aDlmtWdS-lSf-wdK0E5rQ&s",
                    teamName: "LAL",
                    teamUrl: "https://www.nba.com/team/1610612747/lakers",
                    number: 6,
                    position: "F",
                    info: {
                        height: "6-9",
                        weight: 250,
                    },
                    lastAttended: "St. Vincent-St. Mary HS (OH)",
                    country: "USA",
                    },
                    {
                    id: 3,
                    player: "Kevin Durant",
                    page: "https://www.nba.com/player/201142/kevin-durant",
                    avator:
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ULbkSJGizsSKqowXW0aDlmtWdS-lSf-wdK0E5rQ&s",
                    teamName: "BKN",
                    teamUrl: "https://www.nba.com/team/1610612751/nets",
                    number: 7,
                    position: "F",
                    info: {
                        height: "6-10",
                        weight: 240,
                    },
                    lastAttended: "Texas-Austin",
                    country: "USA",
                    },
                    {
                    id: 4,
                    player: "Giannis Antetokounmpo",
                    page: "https://www.nba.com/player/203507/giannis-antetokounmpo",
                    avator:
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ULbkSJGizsSKqowXW0aDlmtWdS-lSf-wdK0E5rQ&s",
                    teamName: "MIL",
                    teamUrl: "https://www.nba.com/team/1610612749/bucks",
                    number: 34,
                    position: "F",
                    info: {
                        height: "6-11",
                        weight: 242,
                    },
                    lastAttended: "Filathlitikos",
                    country: "Greece",
                    },
                ],
                userList: [],
                userImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ULbkSJGizsSKqowXW0aDlmtWdS-lSf-wdK0E5rQ&s" //provvisoria
            }
        },
        created: function(){
            var userListRef = FirebaseDatabase.ref("/users");
            this.userList.length = 0;

            userListRef
            .once('value', snapshot => {
                if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                    this.userList.length = 0;
                    snapshot.forEach(user => {
                        //console.log(user.val());
                        if(user.val().type == 'almn' || user.val().type == 'usr'){
                                this.userList.push(user.val());
                        }       
                    });
                }     
            });

            //detect new user added
            userListRef
            .on('value', snapshot => {
                if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                    this.userList.length = 0;
                    snapshot.forEach(user => {
                        //console.log(user.val());
                        if(user.val().type == 'almn' || user.val().type == 'usr'){
                                this.userList.push(user.val());
                        }       
                    });
                }     
            });
        },
        methods:{
            sendEmailVerificationRequest(){
                alert("Sicuro di voler rinviare la richiesta di verifica email?")
            },
            verifyUser(userUid,enabled){
                var question = "";
                question = !enabled ? "Sicuro di voler verificare questo account?" : "Sicuro di non voler verificare questo account?"
                var answer = window.confirm(question);
                let _this = this;

                if (answer) {
                    //parte la verifica account
                    var userRef = FirebaseDatabase.ref("/users");
                    userRef.orderByChild("userUid").equalTo(userUid)
                        .once("value",snapshot => {

                        if (snapshot.exists()){
                            var key = Object.keys(snapshot.val())[0];

                            userRef.child(key).update({
                                enabled: !enabled,
                                verificationStatus: !enabled ? "complete" : null
                            }).then(() => {

                                _this.notificationTitle = "Successo";
                                    _this.notificationMessage = "Operazione avvenuta con successo!";

                                notify({
                                        type: 'success',
                                        title: _this.notificationTitle,
                                        text: _this.notificationMessage,
                                });

                            }).catch(() => { //error
                                _this.notificationTitle = "Qualcosa è andato storto";
                                _this.notificationMessage = "Il link potrebbe essere scaduto, riprovare la procedura o contattare il supporto!";

                                notify({
                                    type: 'error',
                                    title: _this.notificationTitle,
                                    text: _this.notificationMessage,
                                });	
                            });
                        }
                            
                    });
                }
                else {
                    //non parte la verifica account
                }
            }
        }
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    html{
        background-color: #edeef0;
    }

    .player-wrapper {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-items: center;
    }
    .avator {
        margin-right: 10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    .profile-link{
      cursor: pointer;
      color: #b85b2d;
      text-decoration: none;
    }
    .profile-link:hover,.profile-link:active,.profile-link:focus{
      cursor: pointer;
      color: #b85b2d;
      text-decoration: underline;
    }

    .email-not-verified-btn:active, .email-not-verified-btn:focus, .email-not-verified-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .email-not-verified-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #ababab; /*#1c9cea*/ 
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .email-not-verified-wrapper{
        display: flex;
        justify-content: center;
    }
    .email-verified{
        color: #1c9cea;
        display: flex;
        justify-content: center;
    }

    .user-status-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #ababab; /*#1c9cea*/ 
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .user-status-btn:active, .user-status-btn:focus, .user-status-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .user-status-btn.verified{
        color: #f2740d;
    }
</style>