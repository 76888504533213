<template>
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
          <button class="hamburger hamburger-squeeze" type="button" data-toggle="dropdown" 
          aria-haspopup="true" aria-expanded="false" @click="toggleSidebar">
            <mdicon name="menu"/>
          </button>
          <a class="navbar-brand" href="/"><img src="../../assets/logo.jpg" height="35" alt="petshelter-logo"/> 
            <span class="logo-span">PetShelter</span>
          </a>
          <UserboxView></UserboxView>
      </div>
    </nav>
    <aside v-bind:class="toggleSidebarClass">
        <!-- .aside-content -->
        <div class="aside-content">
          <!-- .aside-header -->
          <header class="aside-header d-block d-md-none">
            <!-- .btn-account -->
            <button class="btn-account collapsed" type="button" data-toggle="collapse" data-target="#dropdown-aside" aria-expanded="false"><span class="user-avatar user-avatar-lg"><img src="assets/images/avatars/profile.jpg" alt=""></span> <span class="account-icon"><span class="fa fa-caret-down fa-lg"></span></span> <span class="account-summary"><span class="account-name">Beni Arisandi</span> <span class="account-description">Marketing Manager</span></span></button> <!-- /.btn-account -->
            <!-- .dropdown-aside -->
            <div id="dropdown-aside" class="dropdown-aside collapse" style="">
              <!-- dropdown-items -->
              <div class="pb-3">
                <a class="dropdown-item" href="user-profile.html"><span class="dropdown-icon oi oi-person"></span> Profile</a> <a class="dropdown-item" href="auth-signin-v1.html"><span class="dropdown-icon oi oi-account-logout"></span> Logout</a>
                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Help Center</a> <a class="dropdown-item" href="#">Ask Forum</a> <a class="dropdown-item" href="#">Keyboard Shortcuts</a>
              </div><!-- /dropdown-items -->
            </div><!-- /.dropdown-aside -->
          </header><!-- /.aside-header -->
          <!-- .aside-menu -->
          <div class="aside-menu overflow-hidden ps">
            <!-- .stacked-menu -->
            <nav id="stacked-menu" class="stacked-menu stacked-menu-has-collapsible">
              <!-- .menu -->
              <ul class="menu">
                <!-- .menu-item -->
                <li class="menu-item has-active">
                  <router-link to="dashboard" class="menu-link">
                    <mdicon name="cube" class="menu-icon"/> 
                    <span class="menu-text">Dashboard</span>
                  </router-link>
                </li>
                <!-- /.menu-item -->
                <!-- .menu-item -->
                <li class="menu-item">
                  <a href="dashboard" class="menu-link">
                    <mdicon name="archive-clock" class="menu-icon"/> 
                    <span class="menu-text">Annunci</span></a>
                </li>
                <!-- /.menu-item -->
                <!-- .menu-item -->
                <li class="menu-item">
                  <router-link to="profilo" class="menu-link">
                    <mdicon name="account-circle" class="menu-icon"/> 
                    <span class="menu-text">Profilo</span>
                    <span class="badge bg-secondary">New</span>
                  </router-link>
                </li>
                <!-- /.menu-item -->
                <!-- .menu-item -->
                <li class="menu-item">
                  <router-link to="/admin/utenti" class="menu-link">
                    <mdicon name="account-supervisor-circle" class="menu-icon"/> 
                    <span class="menu-text">Utenti</span>
                  </router-link>
                </li>
                <!-- /.menu-item -->
                 <!-- .menu-item -->
                 <li class="menu-item">
                  <router-link to="/admin/newsletter" class="menu-link">
                    <mdicon name="cube-send" class="menu-icon"/> 
                    <span class="menu-text">Newsletter</span>
                  </router-link>
                </li>
                <!-- /.menu-item -->
              </ul><!-- /.menu -->
            </nav><!-- /.stacked-menu -->
          <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
          </div>
          <div class="ps__rail-y" style="top: 0px; right: 0px;">
            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;">
            </div>
          </div>
          </div><!-- /.aside-menu -->
          <!-- Skin changer -->
          <footer class="aside-footer border-top p-2">
              <ul class="list-unstyled small text-muted">
                  <li class="mb-2">Made with ❤️ by Simone Besana.</li>
              </ul>
          </footer><!-- /Skin changer -->
        </div><!-- /.aside-content -->
    </aside>
    <main class="app-main">
        <router-view></router-view>
    </main>
</template>

<script>

import UserboxView from '../../components/account/UserBoxView.vue'

export default {
  name: 'DashboardIndex',
  data() {
    return{
      sidebarCollapsed : false
    }
  },
  components: {
    UserboxView
  },
  methods: {
    toggleSidebar(){
        this.sidebarCollapsed = !this.sidebarCollapsed;
        this.toggleSidebarClass;
    }
  },
  computed:{
    toggleSidebarClass() {
        return this.sidebarCollapsed ? "app-aside short app-aside-expand-md app-aside-light" : "app-aside app-aside-expand-md app-aside-light";
    } 
  }
}
</script>

<style scoped>
    .navbar{
      padding: 0px;
      border: none;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      background-color: #fff !important;
      position: fixed;
      top: 0;
      width: 100%;
    }
    nav .container-fluid{
      padding-right: 0px;
    }

    /* SIDEBAR */
    @media (min-width: 768px){
      .app-aside-light {
          border-right: 1px solid rgba(20,20,31,.05);
      }
    }
    .app-aside-light {
        color: inherit;
        background-color: #f6f7f9;
    }
    @media (min-width: 768px){
      .app-aside-expand-md {
          top: 3.5rem;
          width: 15rem;
          z-index: 6;
      }
    }
    .app-aside-expand-md {
        top: auto;
        transform: translateZ(0);
        z-index: 1030;
    }
    .app-aside {
        width: calc(100vw - 3.5rem);
        max-width: 16.5rem;
        z-index: 950;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform .2s ease-in-out;
        position: fixed;
        top: 57px;
        background-color: #fff;
    }  
    aside{
        display: block;
        height: 92vh;
    }

    .stacked-menu ul.menu {
        display: block;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    dl, ol, ul {
        margin-top: 0;
    }
    address, dl, ol, ul {
        margin-bottom: 1rem;
    }

    .stacked-menu .menu-item.has-active:not(.has-child)>.menu-link {
        font-weight: 600;
    }
    .stacked-menu>.menu>.menu-item.has-active>.menu-link {
        background-color: transparent;
        color: #346cb0;
    }
    .stacked-menu>.menu>.menu-item>.menu-link {
        color: #363642;
    }
    .stacked-menu .menu-item.has-active>.menu-link {
        background-color: transparent;
        color: #346cb0;
    }
    @media (min-width: 768px){
      .stacked-menu .menu-link {
          padding-top: 0.375rem;
          padding-bottom: 0.375rem;
          font-size: .875rem;
          line-height: 1.25rem;
      }
    }
    .stacked-menu .menu-link {
        font-size: 1rem;
        outline: 0;
    }
    .stacked-menu .menu-link, .stacked-menu .menu-subhead {
        padding-right: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .stacked-menu .menu-link {
        position: relative;
        display: block;
        color: #888c9b;
        text-decoration: none;
    }
    .stacked-menu .menu-link, .stacked-menu .menu-subhead {
        padding: 0.5rem 1rem;
        font-size: .875rem;
        line-height: 1.75em;
        overflow: hidden;
    }
    .stacked-menu a {
        color: #346cb0;
        text-decoration: none;
        background-color: transparent;
    }

    .stacked-menu .menu-item+.menu-item {
        margin-top: 0;
    }

    .stacked-menu .has-child {
        position: relative;
    }

    .stacked-menu-has-collapsible .has-child.has-open:not(.has-active)>.menu {
        height: auto;
    }
.stacked-menu .has-child.has-active:hover>.menu, .stacked-menu .has-child.has-open>.menu {
    overflow: visible;
}
.stacked-menu-has-collapsible .has-child>.menu {
    visibility: visible;
    opacity: 1;
    overflow: hidden;
}
.stacked-menu .has-child>.menu {
    height: 0;
    visibility: hidden;
    opacity: 0;
    background-color: transparent;
}
.stacked-menu ul.menu {
    display: block;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}


/* USER NAV BAR INFO */

.top-bar-item-right {
    margin-left: auto;
}
@media (min-width: 992px){
  .top-bar-item {
      padding-left: 2rem;
  }
}
@media (min-width: 768px){
  .top-bar-item {
      padding-right: 1rem;
      padding-left: 1rem;
  }
}
.top-bar-item {
    padding: 0;
}
.top-bar-item, .top-bar-list {
    display: flex;
    align-items: center;
}
.dropdown, .dropleft, .dropright, .dropup {
    position: relative;
}
.btn-account {
    color: #fff;
    border-left: 1px solid rgba(34,34,48,.12) !important;
}
.app-header .btn-account {
    padding: 0 1rem;
    min-width: 3.5rem;
    min-height: 3.5rem;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.btn-account {
    position: relative;
    display: flex;
    margin: 0;
    border: 0;
    flex-wrap: none;
    align-items: center;
    background: none;
    color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    transition: background-color .15s;
    outline: 0;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    border-radius: 0;
}

.btn-account>.has-badge, .btn-account>.user-avatar {
    margin-right: 0.75rem;
}
.user-avatar-md {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 2.25rem;
}
.user-avatar {
    position: relative;
    margin-bottom: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
    vertical-align: middle;
    border-radius: 4rem;
}

.user-avatar audio, .user-avatar canvas, .user-avatar img, .user-avatar video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4rem;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
}
img, svg {
    vertical-align: middle;
}

img {
    border-style: none;
}

.btn-account .account-summary {
    max-width: 10rem;
}
.btn-account .account-summary {
    margin-right: 0.75rem;
    display: block;
    text-align: left;
    flex: 1;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.btn-account .account-description, .btn-account .account-name {
    margin: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1rem;
}

.btn-account .account-description {
    font-size: .75rem;
    font-weight: 400;
    opacity: .7;
}
.btn-account .account-description, .btn-account .account-name {
    margin: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1rem;
}

.btn-account:hover {
    background-color: #f6edd92e;
}
.btn-account {
    padding: 0 1rem;
    min-width: 12.5rem;
    min-height: 3.5rem
}

.btn-account:focus, .btn-account:hover {
    color: inherit;
    text-decoration: none;
}

/* USER MENU */

.dropdown-menu.show {
    -webkit-animation-name: showDropdown;
    animation-name: showDropdown;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto;
}
@media (min-width: 768px){
  .dropdown-menu {
      font-size: .875rem;
  }
}
.dropdown-menu {
    margin-top: 10px !important;
    border: 0;
    font-weight: 400;
    box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 2px 7px 1px rgb(20 20 31 / 16%);
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #363642;
    text-align: left;
    list-style: none;
    background-color: hsla(0,0%,100%,.98);
    background-clip: padding-box;
    border: 1px solid rgba(20,20,31,.15);
    border-radius: 0.25rem;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: #346cb0;
}

.dropdown-menu a:hover {
    color: #234875;
    text-decoration: underline;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    clear: both;
    font-weight: 400;
    /*color: #191927;*/
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    color: #a6abbd;
}
.dropdown-arrow, .dropdown-arrow:before {
    display: block;
    position: absolute;
    left: 0.5rem;
    pointer-events: none;
}
.dropdown-arrow {
    top: 0;
    overflow: hidden;
    height: 1.25rem;
    width: 1.25rem;
    z-index: 1001;
    transform: translate3d(0,-100%,0);
}
.dropdown-arrow:before {
    content: "";
    bottom: -0.375rem;
    transform: rotate(45deg);
    height: 0.75rem;
    width: 0.75rem;
    margin-left: -5px;
    background-color: hsla(0,0%,100%,.98);
    box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 2px 7px 1px rgb(20 20 31 / 16%);
}
.dropdown-arrow, .dropdown-arrow:before {
    display: block;
    position: absolute;
    left: 0.5rem;
    pointer-events: none;
}

    .hamburger.focus, .hamburger:focus, .hamburger:hover {
        background-color: aliceblue;
        outline: 0;
    }
    .hamburger {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        cursor: pointer;
        height: 2.25rem;
        width: 2.25rem;
        transition: background-color .3s linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
    }
    .aside-menu {
        position: relative;
        flex: 1;
        overflow-y: auto;
    }
    .aside-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .stacked-menu {
        text-align: left;
        padding-top: 1rem;
        width: 100%;
        margin-bottom: 1rem;
    }
    .stacked-menu {
        position: relative;
        padding: 0.25rem 0;
        background-color: transparent;
    }
    .stacked-menu .menu-icon{
        width: 1.25em;
        margin-right: 0.5em;
        text-align: center;
        font-size: 1.125rem;
    }
    .menu-item.active, .menu-item:active, .menu-item:focus, .menu-item:hover {
        text-decoration: none;
        background-color: #346cb0;
    }
    .menu-item:active, .menu-item:focus, .menu-item:hover span {
        color: #fff;
    }
    .menu-item.has-active:active, .menu-item.has-active:focus, .menu-item.has-active:hover span {
        color: #fff;
    }
    .menu-item span{
      color: #525558;
    }
    .menu-item.has-active span{
      color: #346cb0;
    }

    .app-aside.short {
      transform: translate3d(-80%,0,0);
    }    
    .app-aside.short .menu {
      text-align: right;
    }
    .app-aside.short .menu-text {
      display: none;
    }
    .app-aside.short .menu-icon {
      margin-right: 0px;
    }   

    @media (min-width: 768px){
        .app-aside-expand-md+.app-main {
            padding-left: 25rem !important;
            padding-right: 10rem;
        }
    }
    .app-aside+.app-main {
        padding-left: 0;
    }
    .app-main {
        position: relative;
        padding-top: 3.5rem;
        padding-right: 0;
        padding-left: 0;
        overflow: hidden;
        display: block;
        background-color: #edeef0;
        margin-top: 20px;
    }

    .badge{
        margin-left: 5px;
        color: #fff !important;
    }

    .app-aside.short .badge{
        display: none;
    }
</style>