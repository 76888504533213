<template>
        <div class="row mt-3" v-if="stepStatus == 1">
            <div class="col-12 col-md-12">
                <input type="text" class="form-control" name="email" placeholder="Email" v-model="email" 
                :class="[isEmailValid == null ? inputDefaultClass : (isEmailValid ? invalidInputClass : validInputClass)]" 
                @focusout="validateEmail(email)" @change="validateEmail(email)">
                <div class="invalid-feedback">
                    Inserire un indirizzo email valido.
                </div>
            </div>
            <div class="col-12 col-md-12 mt-3 mb-3">
                <button type="button" class="btn btn-primary" @click="sendPasswordResetRequest"
                :disabled="((isEmailValid == null || isEmailValid == false))">Invia richiesta di reset password</button>
            </div>                        
        </div>
        <div class="row mt-3" v-else>
            <div class="col-12 col-md-12 mt-5 mb-3">
                <img src="../../assets/email-sent.jpg" alt="email-sent-picture" class="rounded-circle" width="250"/>
            </div>
            <div class="col-12 col-md-12">
                <h2><strong>Per favore controlla la tua casella di posta.</strong></h2>
                <p>Ti abbiamo inviato un link per poter reimpostare la password, se non dovessi trovarlo controlla anche lo spam.</p>
                <p>Una volta trovata, puoi chiudere questa finestra.</p>
            </div>
        </div>
</template>

<script>

import {FirebaseAuth} from "@/firebase";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: 'RecoverPasswordPage',
  components: {
  },
  data() {
    return{
        email: "",
        stepStatus: 1,
        notificationTitle: "",
		notificationMessage: "",
        isEmailValid: null,
        inputDefaultClass: 'form-control',
        validInputClass: 'form-control is-invalid',
        invalidInputClass: 'form-control is-valid'
    }
  },
  created: function() {
    let suggestedEmail = this.email;
    this.validateEmail(suggestedEmail);
  },
  methods: {
    validateEmail(emailToCheck) {
        var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line       

        if (reg.test(emailToCheck)) {
          this.isEmailValid = true;
        } else {
          this.isEmailValid = false;
        }
    },
    sendPasswordResetRequest: function () {
			let _this = this;
			FirebaseAuth.sendPasswordResetEmail(this.email).then(() => {
				//this.$router.replace('/ripristino-password-inviata'); // User logged
                this.stepStatus = 2;
			}).catch(() => { //error
				_this.notificationTitle = "Qualcosa è andato storto";
                _this.notificationMessage = "Controllare che email e password siano corretti!";

                notify({
                        type: 'error',
                        title: _this.notificationTitle,
                        text: _this.notificationMessage,
                    });
			});
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .method-action{
        text-decoration: none;
    }
    .method-control.google{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.facebook{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.apple{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .login-google-span{
        margin-left: 24px;
    }
    .login-social-buttons .method-action:hover {
        background: var(--color-light);
    }


    .wrapper{
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 15px;
    }
    .wrapper .option{
        background: #fff;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px 10px;
        border: 2px solid lightgrey;
        transition: all 0.3s ease;
    }
    .wrapper .option .dot{
        height: 20px;
        width: 20px;
        background: #d9d9d9;
        border-radius: 50%;
        position: relative;
    }
    .wrapper .option .dot::before{
        position: absolute;
        content: "";
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        background: #b85b2d;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.5);
        transition: all 0.3s ease;
    }
    input[type="radio"]{
        display: none;
    }
    #option-1:checked:checked ~ .option-1,
    #option-2:checked:checked ~ .option-2{
        border-color: #b85b2d;
        background: #b85b2d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }
    #option-1:checked:checked ~ .option-1 .dot,
    #option-2:checked:checked ~ .option-2 .dot{
        background: #fff;
    }
    #option-1:checked:checked ~ .option-1 .dot::before,
    #option-2:checked:checked ~ .option-2 .dot::before{
        opacity: 1;
        transform: scale(1);
    }
    .wrapper .option span{
        font-size: 20px;
        color: #808080;
        margin-right: 5px;
    }
    #option-1:checked:checked ~ .option-1 span,
    #option-2:checked:checked ~ .option-2 span{
        color: #fff;
    }

    .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

    .showPassword{
        position: absolute;
        right: 45px;
        bottom: 10px;
        color: #837575;
        cursor: pointer;
    }
    .form-control.is-invalid + .showPassword{
        position: absolute;
        right: 45px;
        bottom: 35px;
        color: #837575;
        cursor: pointer;
    }
    .password-div{
        position: relative;
    }
</style>
