<template>
        <div class="row mt-3" v-if="operationType == 1">
            <div class="col-12 col-md-12 mt-3 password-div">
                <input :type="passwordType1" class="form-control" placeholder="Password" v-model="password1" @change="validatePassword1(password1)"
                :class="[isPassword1Valid == null ? inputDefaultClass : (isPassword1Valid ? invalidInputClass : validInputClass)]">
                <mdicon name="eye" class="showPassword" v-if="!isPassword1Visible" @click="switchPasswordVisibility(1)"/>
                <mdicon name="eye-off" class="showPassword" v-else @click="switchPasswordVisibility(1)"/>
                <div class="invalid-feedback">
                    Inserire una password valida.
                </div>
            </div>
            <div class="col-12 col-md-12 mt-3 password-div">
                <input :type="passwordType2" class="form-control" placeholder="Conferma password" v-model="password2" @change="validatePassword2(password2)"
                :class="[isPassword2Valid == null ? inputDefaultClass : (isPassword2Valid ? invalidInputClass : validInputClass)]">
                <mdicon name="eye" class="showPassword" v-if="!isPassword2Visible" @click="switchPasswordVisibility(2)"/>
                <mdicon name="eye-off" class="showPassword" v-else @click="switchPasswordVisibility(2)"/>
                <div class="invalid-feedback">
                    La password non combacia.
                </div>
            </div>
            <div class="col-12 col-md-12 mt-3 mb-3">
                <button type="button" class="btn btn-primary" @click="sendPasswordResetRequest"
                :disabled="((isPassword1Valid == null || isPassword1Valid == false) || (isPassword2Valid == null || isPassword2Valid == false))">Invia reset password</button>
            </div>                        
        </div>
        <div class="row mt-3" v-else>
            <div class="col-12 col-md-12 mt-5 mb-3">
                <img src="../../assets/email-sent.jpg" alt="email-sent-picture" class="rounded-circle" width="250"/>
            </div>
            <div class="col-12 col-md-12">
                <h2><strong>Per favore controlla la tua casella di posta.</strong></h2>
                <p>RESULT OK.</p>
            </div>    
        </div>
</template>

<script>

import {FirebaseDatabase} from "@/firebase";
import {FirebaseAuth} from "@/firebase";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: 'ConfirmOperationPage',
  components: {
  },
  data() {
    return{
        password1: "",
        password2: "",
        passwordType1: "password",
        passwordType2: "password",
        isPassword1Visible: false,
        isPassword2Visible: false,
        notificationTitle: "",
		notificationMessage: "",
        isPassword1Valid: null,
        isPassword2Valid: null,
        inputDefaultClass: 'form-control',
        validInputClass: 'form-control is-invalid',
        invalidInputClass: 'form-control is-valid',
        operationType: 1
    }
  },
  created: function() {
    let oobCode = this.$route.query.oobCode;
    let mode = this.$route.query.mode
    let _this = this;

    if(oobCode == "" || (mode != "resetPassword" && mode != "verifyEmail")){
        this.$router.replace('/not-found');
    }else{
        
        if(mode == "resetPassword"){

            this.operationType = 1;

            FirebaseAuth.verifyPasswordResetCode(oobCode).then(function() {
            //alert(result);
            })
            .catch(() => { //error (auth/invalid-action-code)
                _this.notificationTitle = "Qualcosa è andato storto";
                _this.notificationMessage = "Il link potrebbe essere scaduto, riprovare la procedura o contattare il supporto!";

                notify({
                    type: 'error',
                    title: _this.notificationTitle,
                    text: _this.notificationMessage,
                });

                this.$router.replace('/not-found');
            });
        }else if(mode == "verifyEmail" && this.operationType != 2){
            this.operationType = 2;

            var userRef = FirebaseDatabase.ref("/users");
            userRef.orderByChild("userUid").equalTo(FirebaseAuth.currentUser.uid)
                .once("value",snapshot => {

                if (snapshot.exists()){
                    var key = Object.keys(snapshot.val())[0];

                    userRef.child(key).update({
                        isEmailConfirmed: true
                    }).then(() => {

                        FirebaseAuth.applyActionCode(oobCode).then(function() {
                            //alert(result);
                            this.$router.replace('/account');
                        })
                        .catch((error) => { //error (auth/invalid-action-code)

                            console.log(error);

                            _this.notificationTitle = "Qualcosa è andato storto";
                            _this.notificationMessage = "Il link potrebbe essere scaduto, riprovare la procedura o contattare il supporto!";

                            notify({
                                type: 'error',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            });

                            this.$router.replace('/not-found');
                        });

                    }).catch(() => { //error
                        _this.notificationTitle = "Qualcosa è andato storto";
                        _this.notificationMessage = "Il link potrebbe essere scaduto, riprovare la procedura o contattare il supporto!";

                        notify({
                            type: 'error',
                            title: _this.notificationTitle,
                            text: _this.notificationMessage,
                        });	
                    });
                }
                    
            });
        }   
    }
  },
  methods: {
    switchPasswordVisibility(password){
        if(password == 1){
            this.isPassword1Visible = !this.isPassword1Visible;
            if(this.isPassword1Visible){
                this.passwordType1 = "text";
            }else{
                this.passwordType1 = "password";
            }
        }else{
            this.isPassword2Visible = !this.isPassword2Visible;
            if(this.isPassword2Visible){
                this.passwordType2 = "text";
            }else{
                this.passwordType2 = "password";
            }
        }
        
    },
    validatePassword1(passwordToCheck) {
        //alert(passwordToCheck);
        var reg = /^[a-zA-Z0-9]{8,}$/; //eslint-disable-line

        if (reg.test(passwordToCheck)) {
          this.isPassword1Valid = true;
        } else {
          this.isPassword1Valid = false;
        }
    },
    validatePassword2(passwordToCheck) {

        if (passwordToCheck == this.password1) {
          this.isPassword2Valid = true;
        } else {
          this.isPassword2Valid = false;
        }
    },
    sendPasswordResetRequest: function () {
			let _this = this;
            let oobCode = this.$route.query.oobCode;
			FirebaseAuth.confirmPasswordReset(oobCode,this.password1).then(() => {
				this.$router.replace('/account'); // User logged
			}).catch(() => { //error
				_this.notificationTitle = "Qualcosa è andato storto";
                _this.notificationMessage = "Il link potrebbe essere scaduto, riprovare la procedura o contattare il supporto!";

                notify({
                        type: 'error',
                        title: _this.notificationTitle,
                        text: _this.notificationMessage,
                    });
			});
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .method-action{
        text-decoration: none;
    }
    .method-control.google{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.facebook{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .method-control.apple{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }
    .login-google-span{
        margin-left: 24px;
    }
    .login-social-buttons .method-action:hover {
        background: var(--color-light);
    }


    .wrapper{
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 15px;
    }
    .wrapper .option{
        background: #fff;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px 10px;
        border: 2px solid lightgrey;
        transition: all 0.3s ease;
    }
    .wrapper .option .dot{
        height: 20px;
        width: 20px;
        background: #d9d9d9;
        border-radius: 50%;
        position: relative;
    }
    .wrapper .option .dot::before{
        position: absolute;
        content: "";
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        background: #b85b2d;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.5);
        transition: all 0.3s ease;
    }
    input[type="radio"]{
        display: none;
    }
    #option-1:checked:checked ~ .option-1,
    #option-2:checked:checked ~ .option-2{
        border-color: #b85b2d;
        background: #b85b2d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }
    #option-1:checked:checked ~ .option-1 .dot,
    #option-2:checked:checked ~ .option-2 .dot{
        background: #fff;
    }
    #option-1:checked:checked ~ .option-1 .dot::before,
    #option-2:checked:checked ~ .option-2 .dot::before{
        opacity: 1;
        transform: scale(1);
    }
    .wrapper .option span{
        font-size: 20px;
        color: #808080;
        margin-right: 5px;
    }
    #option-1:checked:checked ~ .option-1 span,
    #option-2:checked:checked ~ .option-2 span{
        color: #fff;
    }

    .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

    .showPassword{
        position: absolute;
        right: 45px;
        bottom: 10px;
        color: #837575;
        cursor: pointer;
    }
    .form-control.is-invalid + .showPassword{
        position: absolute;
        right: 45px;
        bottom: 35px;
        color: #837575;
        cursor: pointer;
    }
    .password-div{
        position: relative;
    }
</style>
