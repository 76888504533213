<template>
    <div class="container work-with-us-container">
      <div class="row become-partner" id="workWithUs">
          <h2 class="title">Diventa nostro partner</h2>
          <!--Allevatori PARTNER-->
          <div class="col-12 col-md-4 mb-4">
              <div class="contact-crd">
                  <div class="contact-crd-img">
                    <div class="contact-crd-polygon">
                      <svg role="img" viewBox="0 0 235 25" preserveAspectRatio="xMinYMax slice" aria-label="" focusable="false">
                        <polygon points="235 0 235 25 0 25"></polygon>
                      </svg>
                    </div>
                  </div>
                  <div class="contact-crd-content mt-4">
                      <h2>Allevatori</h2>
                      <p>Entra a far parte dei nostri allevatori partner e fatti conoscere da migliaia di utenti.</p>
                      <p>Unirti a noi, come partner, è completamente gratuito.</p>
                      <button class="btn" type="button" @click="sendWhatsAppMessage()">
                        <mdicon :size="30" name="whatsapp" class="w-icon"/> <span class="btn-text">Contattaci e unisciti a noi</span>
                      </button>
                  </div>  
              </div>
          </div>

          <!--Canili/Gattili PARTNER-->
          <div class="col-12 col-md-4 mb-4">
              <div class="contact-crd coming-soon">
                  <div class="contact-crd-img cattery">
                    <div class="contact-crd-polygon">
                      <svg role="img" viewBox="0 0 235 25" preserveAspectRatio="xMinYMax slice" aria-label="" focusable="false">
                        <polygon points="235 0 235 25 0 25"></polygon>
                      </svg>
                    </div>
                  </div>
                  <div class="contact-crd-content mt-4">
                      <h2>Gattili e Canili</h2>
                      <p>Coming Soon...</p>
                      <button class="btn disabled" type="button">
                          Coming Soon
                      </button>
                  </div>  
              </div>
          </div>

          <!--Cat Hotels PARTNER-->
          <div class="col-12 col-md-4 mb-4">
              <div class="contact-crd coming-soon">
                  <div class="contact-crd-img animal-hotels">
                    <div class="contact-crd-polygon">
                      <svg role="img" viewBox="0 0 235 25" preserveAspectRatio="xMinYMax slice" aria-label="" focusable="false">
                        <polygon points="235 0 235 25 0 25"></polygon>
                      </svg>
                    </div>
                  </div>
                  <div class="contact-crd-content mt-4">
                      <h2>Hotel per Gatti e Cani</h2>
                      <p>Coming Soon...</p>
                      <button class="btn disabled" type="button">
                          Coming Soon
                      </button>
                  </div>  
              </div>
          </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'WorkWithUsPage',
  data() {
    return{
    }
  },
  components: {
  },
  methods: {
    sendWhatsAppMessage() {
        var messageToSend = `Salve, vorrei diventare un vostro Allevatore Partner.
                             Vi invio i dati necessari per la registrazione:
                                Dati Personali:
                                  - Nome (Obbligatorio)
                                  - Cognome (Obbligatorio)
                                  - Email (Obbligatorio)
                                  - Data di nascita (ci serve per capire se siete maggiorenni - Obbligatorio)
                                  - Numero di telefono (Opzionale)
                                Dati Allevamento:
                                  - Denominazione (Nome Allevamento - Obbligatorio)
                                  - Posizione geografica (Basta anche solo la città, serve sempre per dare più info sull' allevamento alla persona - Facoltativa)
                                  - Email (Serve ed apparirà sul sito per dare la possibilità all' utente di contattarvi - Facoltativo)
                                  - Numero di telefono (Serve ed apparirà sul sito per dare la possibilità all' utente di contattarvi - Facoltativo)
                                  - Tipologia di animale domestico che viene trattato dall' allevamento(Obbligatorio)
                                  - Razza (Obbligatorio)`;

        window.open("https://wa.me/+393920833493?text=" + encodeURIComponent(messageToSend), '_blank');
    }
  },
  created: function(){
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .contact-crd{
      background-color: #b85b2d;
      min-height: 580px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
    }
    .contact-crd-img{
      background-image: url(https://images.pexels.com/photos/12768746/pexels-photo-12768746.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
      min-height: 300px;
      background-size: cover;
      background-position: 50%;
      opacity: 1;
      position: relative;
    }
    .contact-crd-img.cattery{
      background-image: url(https://www.cleracres.co.uk/wp/wp-content/uploads/2018/11/Cleracres-happy-cats-08.jpg);
    }
    .contact-crd-img.animal-hotels{
      background-image: url(https://cdn.sortiraparis.com/images/80/96038/696814-le-moustache-pension-un-hotel-pour-chats-douillet-qui-accueille-vos-compagnons-a-paris.jpg);
    }

    .contact-crd-polygon{
      position: absolute;
      width: 100%;
      bottom: 0;
    }
    .contact-crd-polygon polygon{
        fill: #b85b2d;
    }

    .become-partner .title{
      font-size: 40px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 40px;
      margin-top: 40px;
    }

    .contact-crd-content{
      padding: 0px 40px 20px;
      position: relative;
      min-height: 230px;
    }
    .contact-crd-content h2{
      font-size: 40px;
      font-weight: 700;
      text-align: left;
      color: #fff;
    }
    .contact-crd-content p{
      text-align: left;
      color: #fff;
    }

    .contact-crd-content .btn:hover {
        filter: brightness(85%);
        transition: 0.3s ease-in;
    }
    .contact-crd-content .btn {
        display: flex;
        color: #fff;
        background: #ffc107;
        min-height: 48px;
        padding: 12px 24px;
        border: none;
        text-decoration: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 4px;
        font-weight: 600;
        font-family: plex-sans,sans-serif;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        line-height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        transition-property: background;
        transition: 0.5s;
    }
    .contact-crd-content .btn .btn-text{
      padding-top: 4px;
    }

    .coming-soon{
      box-shadow: none;
      filter: grayscale(80%);
    }

    .w-icon{
      margin-right: 5px;
    }

    @media only screen and (max-width: 480px) {
      .become-partner .title{
        font-size: 40px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 40px;
        margin-top: 40px;
      }

      .contact-crd-polygon{
          bottom: -0.5px;
      }

      .contact-crd-content .btn {
        display: inline-block;
      }
    } 

    @media (max-width: 767.98px) {
      .contact-crd-content{
        padding: 0px 20px 20px;
      }
    }

    @media (max-width: 922px) {
      .contact-crd-content .btn {
        display: inline-block;
      }
      
      .contact-crd-polygon{
          bottom: -0.5px;
      }
    }

    @media (max-width: 1399px) {
        .work-with-us-container{
            max-width: fit-content;
        }

        .contact-crd-polygon{
          bottom: -0.5px;
      }
    }
</style>
