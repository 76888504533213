<template>
    <div class="ps-card">
        <h2>Il tuo codice utente: {{currentLoggedUser.uid}}</h2>
        <div class="row mt-3">
            <div class="col-12 col-md-12">
                <div class="tabset">
                    <!-- Tab 1 -->
                    <input type="radio" :checked="!businessTabActive" name="tabset" id="tab1" aria-controls="marzen">
                    <label for="tab1" @click="loadProfileInfo">Profilo</label>
                    <!-- Tab 2 -->
                    <input type="radio" :checked="businessTabActive" name="tabset" id="tab2" aria-controls="rauchbier">
                    <label for="tab2" @click="loadBusinessInfo">Business Info</label>
                    <!-- Tab 3 
                    <input type="radio" name="tabset" id="tab3" aria-controls="dunkles">
                    <label for="tab3">Dunkles Bock</label>-->
                    
                    <div class="verification-panel" v-if="personalInformations.verificationStatus == 'requested'">
                        <img class="rounded-circle" src="../../assets/verification-waiting.gif" alt="verification waiting"/>
                        <div class="verification-text">
                            <h4>Stiamo verificando il tuo profilo</h4>
                            <span>I tempi di verifica possono variare</span>
                        </div>
                    </div>
                    <div class="tab-panels">
                        <section id="marzen" class="tab-panel">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Nome</label>
                                    <input type="text" class="form-control" id="userEmail" v-model="personalInformations.name">
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Cognome</label>
                                    <input type="text" class="form-control" id="userEmail" v-model="personalInformations.surname">
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="userEmail" v-model="personalInformations.email" disabled>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Numero di telefono</label>
                                    <input type="email" class="form-control" id="userEmail" v-model="personalInformations.cellphone">
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Data di nascita</label>
                                    <input type="text" class="form-control" id="userBithdayDate" v-model="personalInformations.birthdayDate" disabled>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">a</label>
                                    <input type="email" class="form-control" id="userEmail" v-model="personalInformations.cellphone">
                                </div>
                            </div>
                        </section>
                        <section id="rauchbier" class="tab-panel">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Denominazione</label>
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="es. Main Coon Cattery di Simone Rossi"
                                    v-model="businessInformations.businessName">
                                </div>
                                <div class="col-12 col-md-6 position-div">
                                    <label for="exampleFormControlInput1" class="form-label">Posizione</label>
                                    <GMapAutocomplete id="exampleFormControlInput1" class="form-control" 
                                                      placeholder="Inserisci il tuo indirizzo es. Via Rossi, 14" 
                                                      @place_changed="setPlace" @change="placeOnInputDigit"
                                                      :value="businessInformations.address" ref="autocompleteInputRef">
                                    </GMapAutocomplete>
                                    <button class="calculate-position-btn" type="button" @click="getCurrentPosition">
                                            <mdicon name="crosshairs" class="calculate-position"/>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="businessEmail" v-model="personalInformations.email">
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Numero di telefono</label>
                                    <input type="email" class="form-control" id="businessEmail" v-model="personalInformations.number">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-md-12">
                                    <input class="form-check-input" type="checkbox" value="" id="contactEqualCheck">
                                    <label class="form-check-label" for="contactEqualCheck">
                                        Corrispondono ai contatti della sezione profilo, (tenere presente che questi contatti, 
                                        verranno mostrati sulla scheda online, visibile pubblicamente da tutti gli utenti registrati sul sito).
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Tipologia Animale</label>
                                    <select class="form-select" aria-label="Default select example" v-model="businessInformations.animalType">
                                        <option value="Cane">Cane</option>
                                        <option value="Gatto">Gatto</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="exampleFormControlInput1" class="form-label">Razza</label>
                                    <input type="text" class="form-control" id="businessEmail" v-model="businessInformations.animalRace">
                                </div>
                            </div>
                        </section>
                        <!--<section id="dunkles" class="tab-panel">
                            <h2>6C. Dunkles Bock</h2>
                            <p><strong>Overall Impression:</strong> A dark, strong, malty German lager beer that emphasizes the malty-rich and somewhat toasty qualities of continental malts without being sweet in the finish.</p>
                            <p><strong>History:</strong> Originated in the Northern German city of Einbeck, which was a brewing center and popular exporter in the days of the Hanseatic League (14th to 17th century). Recreated in Munich starting in the 17th century. The name “bock” is based on a corruption of the name “Einbeck” in the Bavarian dialect, and was thus only used after the beer came to Munich. “Bock” also means “Ram” in German, and is often used in logos and advertisements.</p>
                        </section>-->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 animal-type-div">
                                <select class="form-select form-select-lg mb-3" id="tipologiaAnimaleSearch" aria-label=".form-select-lg example" v-model="insertModel.type">
                                    <option selected>Seleziona la tipologia</option>
                                    <option value="Cani">Cane</option>
                                    <option value="Gatti">Gatto</option>
                                </select>
                              </div>    
            <div class="col-12 col-md-3 animal-type-div">
                <input type="text" v-model="insertModel.text" />
            </div>
            <div class="col-12 col-md-12">
                <button type="button" class="btn btn-primary" @click="saveAnimalRace">Salva</button>
            </div>  
            <div class="col-12 col-md-12" v-if="businessTabActive">
                <button type="button" class="btn btn-primary" @click="saveUserInfo">Salva</button>
                <button type="button" class="btn btn-primary request-verification" @click="verificationRequest" :disabled="personalInformations.verificationStatus != null && personalInformations.verificationStatus == 'complete'"><mdicon name="check-decagram"/> {{ verificationButtonText }} </button>
            </div>
            <div class="col-12 col-md-12" v-else>
                <button type="button" class="btn btn-primary" @click="goToBusinessTab">Procedi</button>
            </div>
        </div>
    </div>

    <div class="row mt-5 mb-2" v-if="businessTabActive">
        <h2>Anteprima nei risultati:</h2>
        <div class="ps-card bsn">
            <div class="business-card">
                <img src="https://images.pexels.com/photos/617278/pexels-photo-617278.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" height="200" v-if="slide%2==0"/>
                <img src="https://images.pexels.com/photos/3299906/pexels-photo-3299906.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" height="200" v-else/>
                <h4>{{ businessInformations.businessName }}</h4> <!--<mdicon name="cat" class="account-verified"/>-->
                <p><span class="badge bg-warning">{{ businessInformations.animalType }}</span> <span class="badge bg-secondary">{{ businessInformations.animalRace }}</span></p>
                <p><mdicon :size="18" name="map-marker-radius" /> {{ businessInformations.address }}</p>
            </div>    
        </div>
    </div>
</template>

<script>
    import { FirebaseAuth } from '@/firebase';
    import {FirebaseDatabase} from "@/firebase";
    import { notify } from "@kyvg/vue3-notification";

    export default {
        name: 'ProfiloPage',
        components: {
        },
        data() {
            return{
                currentLoggedUser : {
                    uid : null,
                    userName : null,
                    type: null
                },
                businessInformations : {
                    businessName: null,
                    address: null,
                    lat: null,
                    lng: null,
                    cellphone: null,
                    animalType: null,
                    animalRace: null
                },
                personalInformations : {
                    name: null,
                    surname: null,
                    email: null,
                    cellphone: null,
                    birthdayDate: null,
                    type: null,
                    verificationStatus: null,
                    userFirebaseKey: null
                },
                insertModel:{
                    type: null,
                    text: null
                },
                gmapsApiKey: "AIzaSyDPOTuP9waQYCayI0TJ30Bk94an6DSJHTU",
                loadingBusinessInfoFirstTime: true,
                loadingPersonalInfoFirstTime: true,
                businessTabActive: false,
                verificationButtonText: "Richiedi verifica",
                paramUid: null
            }
        },
        created: function(){
            this.paramUid = this.$route.params.id;
            this.currentLoggedUser.uid = FirebaseAuth.currentUser.uid;

            //recupero le informazioni di base
            var usersRef = FirebaseDatabase.ref("/users");
            if(this.currentLoggedUser.uid == this.paramUid){
                usersRef
                .orderByChild('userUid')
                .equalTo(this.currentLoggedUser.uid)
                .on('value' , snapshot => {
                    if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                        this.personalInformations.userFirebaseKey = Object.keys(snapshot.val())[0];
                        snapshot.forEach(user => {
                            //user.val().type
                            this.personalInformations.type = user.val().type;
                            this.personalInformations.email = user.val().userEmail;
                            this.personalInformations.birthdayDate = user.val().birthdayDate;
                            this.personalInformations.name = user.val().name;
                            this.personalInformations.surname = user.val().surname;
                            this.personalInformations.nominative = user.val().nominative;
                            this.personalInformations.verificationStatus = user.val().verificationStatus;
                            this.verificationButtonText = ((user.val().enabled != false) ? "Verificato" : "Richiedi Verifica");
                        });
                    }
                });
            }else{
                usersRef
                .orderByChild('userUid')
                .equalTo(this.currentLoggedUser.uid)
                .on('value' , snapshot => {
                    if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                        snapshot.forEach(user => {
                            if(user.val().type == "admn"){
                                usersRef
                                .orderByChild('userUid')
                                .equalTo(this.paramUid)
                                .on('value' , snapshot => {
                                    if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                                        snapshot.forEach(user => {
                                            //user.val().type
                                            this.personalInformations.email = user.val().userEmail;
                                            this.personalInformations.birthdayDate = user.val().birthdayDate;
                                        });
                                    }
                                }); 
                            }
                        });
                    }
                });
            }
             
        },
        methods: {
            verificationRequest(){
                var answer = window.confirm("Sicuro di voler richiedere la verifica di questo account?");
                if(answer){
                    this.personalInformations.verificationStatus = "requested";
                    this.saveUserInfo();
                }
            },
            loadBusinessInfo(){
                this.businessTabActive = true;
                if(this.loadingBusinessInfoFirstTime == true){
                    //recupero le informazioni di base
                    var usersRef = FirebaseDatabase.ref("/usersBusinessInfo");
                    usersRef
                    .orderByChild('userUid')
                    .equalTo(this.currentLoggedUser.uid)
                    .on('value' , snapshot => {
                        if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                            snapshot.forEach(user => {
                                //user.val().type
                                console.log(user.val());
                                this.businessInformations.address = user.val().businessAddress;
                                this.businessInformations.lat = user.val().businessLat;
                                this.businessInformations.lng = user.val().businessLng;
                                this.businessInformations.animalType = user.val().buisnessAnimalType;
                                this.businessInformations.animalRace = user.val().buisnessAnimalRace;
                                this.businessInformations.businessName = user.val().businessName;
                            });
                        }
                        this.loadingBusinessInfoFirstTime = false;
                    });  
                }
            },
            loadProfileInfo(){
                this.businessTabActive = false;
                if(this.loadingPersonalInfoFirstTime == true){
                    //recupero le informazioni di base
                    var usersRef = FirebaseDatabase.ref("/users");
                    usersRef
                    .orderByChild('userUid')
                    .equalTo(this.currentLoggedUser.uid)
                    .on('value' , snapshot => {
                        if(snapshot.hasChildren() && snapshot.numChildren() > 0){
                            this.personalInformations.userFirebaseKey = Object.keys(snapshot.val())[0];
                            snapshot.forEach(user => {
                                //user.val().type
                                //console.log(user.val());
                                this.personalInformations.name = user.val().name;
                                this.personalInformations.surname = user.val().surname;
                                this.personalInformations.nominative = user.val().nominative;
                            });
                        }
                        this.loadingBusinessInfoFirstTime = false;
                    });  
                }
            },
            goToBusinessTab(){
                this.businessTabActive = true;
                this.loadBusinessInfo();
            },
            getCurrentPosition(){
                this.$getLocation()
                .then((coordinates) => {
                    //console.log(coordinates);
                    this.lat = coordinates.lat;
                    this.lng = coordinates.lng;
                    this.reverseGeocodeCurrentAddress(coordinates.lat,coordinates.lng);
                })
                .catch((error) => {
                    console.log(error);
                });
            },
            reverseGeocodeCurrentAddress(lat,lng){
                fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng='+ lat +','+ lng +'&key=' + this.gmapsApiKey)
                .then(response => response.json())
                .then(data => (
                    this.businessInformations.address = data.results[0].formatted_address
                ));
            },
            placeOnInputDigit(){
                this.businessInformations.address = this.$refs.autocompleteInputRef.$refs.input.value;
            },
            setPlace(){
                this.businessInformations.address = this.$refs.autocompleteInputRef.$refs.input.value;

                if(this.businessInformations.address != ""){
                    fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+ this.businessInformations.address +'&key=' + this.gmapsApiKey)
                    .then(response => response.json())
                    .then(data => (
                        this.businessInformations.lat = data.results[0].geometry.location.lat,
                        this.businessInformations.lng = data.results[0].geometry.location.lng
                    ));
                }           
            },
            saveUserInfo(){
                //alert(this.businessInformations.address);
                alert("AAAA");
                this.saveBusinessInfo();
                this.savePersonalInfo();
            },
            savePersonalInfo(){
                let _this = this;
                var userRef = FirebaseDatabase.ref("/users");

                this.personalInformations.verificationStatus = (this.personalInformations.verificationStatus == null ? "not-requested" : this.personalInformations.verificationStatus);

                if(this.personalInformations.userFirebaseKey != null){
                    userRef.child(this.personalInformations.userFirebaseKey).update({
                            birthdayDate: this.personalInformations.birthdayDate,
                            name: this.personalInformations.name,
                            surname: this.personalInformations.surname,
                            nominative: (this.personalInformations.surname + " " + this.personalInformations.name),
                            cellphone: this.personalInformations.cellphone,
                            verificationStatus: this.personalInformations.verificationStatus                       
                        })
                        .then(() => {

                            _this.notificationTitle = "Successo";
                            _this.notificationMessage = "Operazione avvenuta con successo!";

                            notify({
                                type: 'success',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            });

                        }).catch(() => { //error
                            _this.notificationTitle = "Errore";
                            _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

                            notify({
                                type: 'error',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            }); 			
                        });
                }else{
                    userRef.push({
                            
                        })
                        .then(() => {

                            _this.notificationTitle = "Successo";
                            _this.notificationMessage = "Operazione avvenuta con successo!";

                            notify({
                                type: 'success',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            });

                        }).catch(() => { //error
                            _this.notificationTitle = "Errore";
                            _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

                            notify({
                                type: 'error',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            }); 			
                        });
                }
            },
            saveBusinessInfo(){
                let _this = this;
                var userBusinessRef = FirebaseDatabase.ref("/usersBusinessInfo");

                userBusinessRef.orderByChild("userUid").equalTo(this.currentLoggedUser.uid)
                .once("value",snapshot => {
                    if (snapshot.exists()){
                        /*const userData = snapshot.val();
                        console.log("exists!", userData);
                        alert(snapshot.val());*/
                        var key = Object.keys(snapshot.val())[0];

                        userBusinessRef.child(key).update({
                            userUid: this.currentLoggedUser.uid,
                            businessName: this.businessInformations.businessName,
                            businessAddress: this.businessInformations.address,
                            businessLat: this.businessInformations.lat,
                            businessLng: this.businessInformations.lng,
                            buisnessAnimalType: this.businessInformations.animalType,
                            buisnessAnimalRace: this.businessInformations.animalRace
                        })
                        .then(() => {

                            _this.notificationTitle = "Successo";
                            _this.notificationMessage = "Operazione avvenuta con successo!";

                            notify({
                                type: 'success',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            });

                        }).catch(() => { //error
                            _this.notificationTitle = "Errore";
                            _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

                            notify({
                                type: 'error',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            }); 			
                        });
                    }else{
                        userBusinessRef.push({
                            userUid: this.currentLoggedUser.uid,
                            businessName: this.businessInformations.businessName,
                            businessAddress: this.businessInformations.address,
                            businessLat: this.businessInformations.lat,
                            businessLng: this.businessInformations.lng,
                            buisnessAnimalType: this.businessInformations.animalType,
                            buisnessAnimalRace: this.businessInformations.animalRace
                        })
                        .then(() => {

                            _this.notificationTitle = "Successo";
                            _this.notificationMessage = "Operazione avvenuta con successo!";

                            notify({
                                type: 'success',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            });

                        }).catch(() => { //error
                            _this.notificationTitle = "Errore";
                            _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

                            notify({
                                type: 'error',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            }); 			
                        });
                    }
                });
            },
            saveAnimalRace(){
                let _this = this;
                var racesRef = FirebaseDatabase.ref("/animalRaces");

                racesRef.push({
                    animalType : this.insertModel.type,
                    raceName : this.insertModel.text
                })
                .then(() => {

                            _this.notificationTitle = "Successo";
                            _this.notificationMessage = "Operazione avvenuta con successo!";

                            notify({
                                type: 'success',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            });

                }).catch(() => { //error
                            _this.notificationTitle = "Errore";
                            _this.notificationMessage = "Errore durante la registrazione, contattare il supporto!";

                            notify({
                                type: 'error',
                                title: _this.notificationTitle,
                                text: _this.notificationMessage,
                            }); 			
                });
            }
        }
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .ps-card.bsn{
        border: none;
        padding: 1px 15px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
        width: 324px;
    }
    html{
        background-color: #edeef0;
    }
    .tabset > input[type="radio"] {
        position: absolute;
        left: -200vw;
    }

    .tabset .tab-panel {
        display: none;
    }

    .tabset{
        position: relative;
    }

    .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
    .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
    .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
    .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
    .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
    .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
        display: block;
    }
    .tabset > label {
        position: relative;
        display: inline-block;
        padding: 15px 15px 25px;
        border: 1px solid transparent;
        border-bottom: 0;
        cursor: pointer;
        font-weight: 600;
    }

    .tabset > label::after {
        content: "";
        position: absolute;
        left: 15px;
        bottom: 10px;
        width: 22px;
        height: 4px;
        background: #8d8d8d;
    }

    .tabset > label:hover,
    .tabset > input:focus + label {
        color: #06c;
    }

    .tabset > label:hover::after,
    .tabset > input:focus + label::after,
    .tabset > input:checked + label::after {
        background: #06c;
    }

    .tabset > input:checked + label {
        border-color: #ccc;
        border-bottom: 1px solid #fff;
        margin-bottom: -1px;
    }

    .tab-panel {
        padding: 30px 0;
        border-top: 1px solid #ccc;
    }

    .position-div{
        position: relative;
    }
    .calculate-position-btn.focus, .calculate-position-btn:focus, .calculate-position-btn:hover {
        background-color: #bd633621;
        outline: 0;
    }
    .calculate-position-btn {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        cursor: pointer;
        height: 2.05rem;
        width: 2.05rem;
        transition: background-color .3s linear;
        font: inherit;
        color: #b85b2d;
        text-transform: none;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        margin: 0;
        margin-right: 5px;
        overflow: visible;
        outline: 0;
        bottom: 3px;
        right: 25px;
    }

    .business-card img{
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      width: 100%;
    }
    .business-card h4{
        margin-top: 5px;
        text-align: left;
    }
    .business-card p>span{
        font-size: 13px;
    }
    .business-card p{
        font-size: 14px;
        text-align: left;
    }

    .account-verified{
        color: #1c9cea;
        vertical-align: text-top;
    }

    .ps-card .btn{
      color: #fff;
      background: #b85b2d;
      min-height: 48px;
      padding: 12px 24px;
      border: none;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 4px;
      font-weight: 600;
      font-family: plex-sans,sans-serif;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      line-height: 24px;
      margin: 0;
      display: inline-block;
      cursor: pointer;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      -webkit-transition-property: background;
      transition-property: background;
      transition: 0.5s;
    }
    .ps-card .btn:hover{
      filter: brightness(85%);
      transition: 0.3s ease-in;
    }

    .request-verification{
        margin-left: 5px !important;
    }

    .verification-panel{
        width: 100%;
        background-color: #e6e6e64a;
        height: 100%;
        position: absolute;
        z-index: 100;
    }
    .verification-panel img{
        width: 300px;
    }
    .verification-text{
        padding: 5px 10px;
        background-color: #b85b2dd9;
        color: #fff;
    }
</style>