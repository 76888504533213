<template>
        <div class="page-header">
            <div class="container">
                <h2>Termini & Condizioni</h2>
            </div>
        </div>

        <div class="page-body">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="ps-card">
                            <h2>Termini e condizioni di Petshelter</h2>
                            <p>Ti diamo il benvenuto su Petshelter! I termini e le condizioni seguenti indicano regole e normative per l'utilizzo del sito di Petshelter, 
                                all'indirizzo (https://petshelter.it).</p>
                            <p>Ti preghiamo di leggere attentamente queste <b>Condizioni Generali</b> prima di usufruire del nostro sito. In caso di domande relative alle presenti Condizioni Generali contattare 
                                Petshelter all'indirizzo di posta elettronica info.petshelter@gmail.com prima di usufruire dai servizi offerti dal nostro sito.</p>
                            
                            <h4>1. Informazioni Generali</h4>
                            <p>Petshelter è gestito da Simone Besana (Codice Fiscale BNSMSN98A79A4R) con sede in Via Mario Pavoni,9, 24031 Bergamo. 
                                È possibile contattarci all'indirizzo di posta elettronica info.petshelter@gmail.com.</p>

                            <h4>2. Scopo</h4>
                            <p>Il nostro obiettivo è metterti in contatto con gli allevamenti con cui collaboriamo ("<b>Allevamenti partner</b>") 
                                e consentire di contattarli tramite telefono/email e di visualizzare le loro informazioni, quali (Posizione, tipologia di animale domestico trattato) (il nostro "<b>Servizio</b>").</p>
                            <p>Daltra parte diamo la possibilità ad Allevamenti Partner di mettersi in risalto e trovare clienti mirati.</p>
                            <p>Qual'ora non trovassi partner che soddisfano la tua ricerca, ci occuperemo di trovare noi un match alla tua ricerca e a ricontattarti 
                                se avrai dato il consenso a farlo.</p>
                            
                            <h4>3. Account</h4>
                            <p>Prima di poter di poter usufruire di determinati servizi offerti dal nostro sito, è necessario aprire un account di Petshelter ("<b>Account</b>"). 
                                Quando apri un Account, devi creare una password o un altro metodo di accesso sicuro. 
                                È necessario mantenere qualsiasi password che si crea, o altro metodo di accesso sicuro, segreto e impedire ad altri di accedere al proprio Account, e-mail. 
                                Se un'altra persona utilizza questi metodi per accedere al tuo Account, sarai ritenuto esclusivamente 
                                e personalmente responsabile per ogni azione che svolgerai sulla piattaforma e, 
                                conseguentemente, non potremo essere ritenuti responsabili per eventuali azioni dannose che svolgerai.</p>
                            <p>Puoi chiudere il tuo Account in qualsiasi momento contattandoci utilizzando i contatti presenti nel sito. 
                                Potremmo sospendere l'accesso al tuo Account o chiuderlo definitivamente se riteniamo che il tuo Account sia stato utilizzato 
                                da qualcun altro. 
                                Potremmo anche chiudere il tuo Account se a nostro avviso non lo stai utilizzando secondo le presenti Condizioni Generali 
                                (ad esempio, facendo ripetuti reclami irragionevoli, maltrattare il nostro staff, o qualsiasi altra ragione).</p>

                            <h4>4. Limiti di età</h4>
                            <p>Per poter registrare un account e quindi usufruire di determinati servizi, sia per gli Allevamenti partner, che per i consumatori, si richiede di avere un età uguale o manggiore a 18 anni. 
                               Qual'ora riscontreremo che un account è stato registrato da un soggeto con un età minore di 18 anni, ci riserveremo il diritto di sospendere l'accesso al tuo Account o chiuderlo definitivamente.</p>
                            
                            <h4>5. Informativa Sui Dati Personali</h4>
                            <p>Petshelter tratterà i tuoi dati personali in conformità con la nostra Informativa 
                                sulla privacy che puoi trovare al link https://www.iubenda.com/privacy-policy/23470730.</p>

                            <h2>Condizioni per l'utilizzo del sito Petshelter</h2>
                            <p>Questa pagina descrive le condizioni generali per l'utilizzo del sito web petshelter.it (di seguito il "Sito") 
                               che Petshelter mette a disposizione degli utenti, in qualità di ospiti o di utenti registrati. 
                               Si raccomanda di leggere attentamente le presenti condizioni generali di utilizzo prima di iniziare ad utilizzare il Sito. 
                               Con l'accesso al Sito l'utente dichiara di accettare le presenti condizioni generali e si impegna a rispettarle. 
                               In caso di disaccordo con quanto previsto nelle presenti condizioni generali si raccomanda di non navigare nel Sito.</p>
                            
                            <h4>1. Uso Consentito</h4>
                            <p>Il Sito può essere utilizzato esclusivamente per finalità conformi alla legge. 
                               Il Sito non può essere utilizzato con modalità che costituiscano una violazione di leggi o regolamenti a livello locale, 
                               nazionale o internazionale né possono essere utilizzati per inviare, ricevere consapevolmente, caricare (upload), scaricare (download), 
                               utilizzare o riutilizzare qualsiasi materiale non conforme agli standard previsti da Petshelter relativamente ai contenuti di cui al successivo articolo 2. 
                               L'utente inoltre si impegna a non accedere al Sito senza averne il diritto, a non interferire con il Sito, a non danneggiare o manomettere, 
                               neppure in parte, il Sito né qualsiasi rete o attrezzatura utilizzata nella fornitura di quest'ultimo.</p>

                            <h4>2. Standard Di Contenuto</h4>
                            <p>Gli standard di contenuto di cui al presente articolo si applicano ad ogni e qualsiasi materiale con cui gli utenti arricchiscono il nostro Servizio (i "Contributi"), 
                                nonché agli eventuali servizi interattivi ad esso associati. 
                                L'utente è tenuto a rispettare lo spirito, oltre che la lettera, degli standard di contenuto di seguito indicati. 
                                Gli standard si applicano ad ogni singola parte di ciascun Contributo nonché al Contributo stesso complessivamente considerato. 
                                I Contributi devono essere fedeli al vero (con riferimento ai fatti riportati), resi con sincerità (con riferimento alle opinioni riportate) 
                                e conformi alla normativa applicabile in Italia, nonché nel paese da cui proviene il post pubblicato online.</p>
                            <p>I Contributi inoltre non devono:</p>
                            <ul>
                                <li>contenere materiali che diffamino un qualsiasi individuo o siano osceni, offensivi, provocatori o tali da suscitare avversione, 
                                    promuovano materiale sessualmente esplicito, violenza o discriminazione sulla base di razza, sesso, religione, nazionalità, disabilità, orientamento sessuale o età;</li>
                                <li>violare diritti d'autore, diritti su database, marchi o diritti di proprietà intellettuale appartenenti a qualsiasi altro soggetto;</li>
                                <li>essere tali da indurre probabilmente in inganno altri né violare obblighi esistenti nei confronti di soggetti terzi, 
                                    quali ad esempio obblighi contrattuali o di riservatezza, né comunque promuovere attività illecite di qualsiasi tipo;</li>
                                <li>essere minatori, ingiuriosi o invadere la sfera privata di altri, né provocare disturbo, disagio o preoccupazioni evitabili, 
                                    né essere tali da costituire verosimilmente molestia, turbamento, allarme o da infastidire qualsiasi altra persona;</li>
                                <li>essere utilizzati per impersonare un altro soggetto o per fornire informazioni distorte riguardo alla propria identità o ad eventuali rapporti 
                                    con altri soggetti né dare l'impressione di provenire da Petshelter ove così non fosse;</li>
                                <li>sostenere, promuovere o favorire atti illeciti di qualsiasi tipo quali (a titolo esemplificativo e non esaustivo) la violazione di diritti d'autore 
                                    o l'uso improprio di computer.</li>
                            </ul>

                            <h4>3. Sospensione E Risoluzione</h4>
                            <p>Il mancato rispetto dell'articolo 1 (Uso consentito) e/o dell'articolo 2 (Standard di contenuto) delle presenti condizioni generali costituisce grave inadempimento 
                                e può comportare il ricorso, da parte di Petshelter, ad una o più misure tra quelle di seguito indicate:</p>
                            <ul>
                                <li>immediata inibitoria, in via temporanea o permanente, della facoltà di accesso al Sito;</li>
                                <li>rimozione immediata, in via temporanea o permanente, di qualsiasi post o materiale trasmesso in upload al Servizio da parte dell'utente;</li>
                                <li>invio di un richiamo all'utente;</li>
                                <li>azione giudiziale nei confronti dell'utente, comprensiva della richiesta di rimborso di tutti i costi (ivi inclusi, a titolo esemplificativo e non esaustivo, 
                                    le ragionevoli spese amministrative e legali) derivanti dalla violazione;</li>
                                <li>trasmissione di una comunicazione all'autorità pubblica competente ad imporre il rispetto della normativa applicabile 
                                    che fornisca le informazioni che Petshelter ritenga, a suo giudizio, ragionevolmente necessario comunicare.</li>
                            </ul>
                            <p>La lista delle azioni descritte nel presente articolo non è esaustiva e Petshelter può adottare qualsiasi altra azione 
                                che ritenga ragionevolmente appropriata.</p>

                            <h4>4. Diritti Di Proprietà Intellettuale</h4>
                            <p>Tutti i diritti di proprietà intellettuale relativi al Sito, nonché a tutti i materiali in essi pubblicati (con l'esclusione dei Contributi degli utenti), 
                                appartengono a Petshelter o sono stati ad essa concessi in licenza. Tali opere sono tutelati dalla normativa in materia di diritto d'autore 
                                e proprietà intellettuale vigente a livello nazionale e internazionale. Tutti i predetti diritti di proprietà intellettuale sono riservati. 
                                E' fatto divieto all'utente di copiare, riprodurre, ripubblicare, scaricare, pubblicare online (“postare”), diffondere, trasmettere, 
                                mettere a disposizione del pubblico o altrimenti utilizzare qualsiasi contenuto presente sul Sito, con qualsiasi modalità, salvo che per uso personale 
                                e non commerciale.</p>
                            
                            <h4>5. Aggiornamenti</h4>
                            <p>Petshelter si propone di aggiornare il Sito e il Servizio con regolarità ed ha il diritto di modificarne i contenuti in qualsiasi momento. 
                                In caso di necessità, Petshelter può sospendere l'accesso al Sito e al Servizio o chiudere entrambi a tempo indeterminato. 
                                In ogni caso, i materiali presenti sul Sito e nel Servizio potrebbero risultare, in un qualsiasi momento, 
                                non aggiornati senza che Petshelter assuma alcun obbligo di aggiornamento a riguardo.</p>
                            
                            <h4>6. Link Di Terzi</h4>
                            <p>I collegamenti (link) eventualmente presenti sul Sito che puntano ad altri siti e risorse messi a disposizione da soggetti terzi vengono forniti esclusivamente 
                                per finalità di informazione. 
                                Petshelter non ha alcun controllo su tali siti o risorse e non assume pertanto alcuna responsabilità riguardo ad essi o relativamente ad eventuali perdite 
                                o danni che possano derivare agli utenti a seguito del loro utilizzo.</p>

                            <h4>7. Modifiche</h4>
                            <p>Petshelter ha il diritto di modificare le presenti condizioni generali in qualsiasi momento apportando la modifica in questione direttamente 
                                al testo riportato nella presente pagina web.
                                Si raccomanda agli utenti di controllare periodicamente questa pagina al fine di prendere nota delle modifiche eventualmente apportate 
                                in quanto si tratta di previsioni vincolanti per gli utenti.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'WelcomePage',
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .page-header{
        padding: 251px 20px;
        /* background-color: #b85b2d; */
        /*background: linear-gradient(0deg, #b85b2dd4, #0000006b), 
        url(https://images.pexels.com/photos/4587965/pexels-photo-4587965.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);*/
        background: linear-gradient(0deg, #b85b2dd4, #f6edd930), 
        url(https://images.pexels.com/photos/4587965/pexels-photo-4587965.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
        background-size: cover;
        background-position: center;
    }
    .page-header h2{
        font-size: 60px;
        line-height: 48px;
        color: #fff;
        text-align: left;
        font-weight: 700;
    }

    .page-body{
        background-color: #edeef0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
    }
    .page-body h2{
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body h4{
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }

    html{
        background-color: #edeef0;
    }

    @media (max-width: 768px){
        .page-header h2{
            font-size: 30px;
        }
        .page-header{
            padding: 60px 20px;
        }
    }
</style>