<template>
    <div class="top-bar-item top-bar-item-right px-0 d-none d-sm-flex" @click="clickOnUserBox">
          <div class="dropdown d-none d-md-flex">
            <button class="btn-account" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="user-avatar user-avatar-md">
                    <img src="../../assets/default-user.png" alt="user-image">
                  </span> 
                  <span class="account-summary pr-lg-4 d-none d-lg-block"><span class="account-name">{{ currentLoggedUser.uid ?? "Accedi" }}</span> 
                  <span class="account-description">{{ currentLoggedUser.userName ?? "o Registrati" }}</span>
                </span>
            </button>
            <div v-bind:class="menuSwitchClass">
                  <div class="dropdown-arrow d-lg-none" x-arrow="" style="left: 110px;"></div>
                  <div class="dropdown-arrow ml-3 d-none d-lg-block"></div>
                  <h6 class="dropdown-header d-none d-md-block d-lg-none"> Beni Arisandi </h6>
                  <router-link class="dropdown-item" :to="'profilo/' + currentLoggedUser.uid">
                    <mdicon name="account" /> Profilo
                  </router-link> 
                  <a class="dropdown-item" href="" @click="doLogOut">
                    <mdicon name="logout" /> Logout
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Help Center</a> 
                  <a class="dropdown-item" href="#">Ask Forum</a> 
                  <a class="dropdown-item" href="#">Keyboard Shortcuts</a>
              </div><!-- /.dropdown-menu -->
          </div>
      </div>
</template>

<script>
import router from '../../router'
import {FirebaseAuth} from "@/firebase";
import {FirebaseDatabase} from "@/firebase";

export default {
  name: 'UserBoxView',
  data() {
    return{
      currentLoggedUser : {
        uid : null,
        userName : null
      },
      isUserLogged : false,
      isUserMenuOpen : false
    }
  },
  components: {
  },
  methods: {
    goToAccountPage() {
      router.push({ path: '/account' });
    },
    clickOnUserBox() {
      if(this.isUserLogged){
        this.isUserMenuOpen = !this.isUserMenuOpen;
      }else{
        router.push({ path: '/account' });
      }
    },
    doLogOut: function () {
        FirebaseAuth.signOut();
        this.$router.replace('/');
    },
    getUserTypeDescription(userTypeCode){
      switch (userTypeCode) {
        case 'almn':
          return "Allevatore"
        case 'usr':
          return "Utente"
        case 'admn':
          return "Admin"
        default:
          return "Utente"
      }
    }
  },
  created: function(){
    let curUser = FirebaseAuth.currentUser;
    if(curUser != null){
      this.isUserLogged = true;
      let uid = FirebaseAuth.currentUser.uid;
      this.currentLoggedUser.uid = uid;
      var usersRef = FirebaseDatabase.ref("/users");
      usersRef
      .orderByChild('userUid')
      .equalTo(this.currentLoggedUser.uid)
      .on('value' , snapshot => {
          if(snapshot.hasChildren() && snapshot.numChildren() > 0){
            snapshot.forEach(user => {
              this.currentLoggedUser.userName = this.getUserTypeDescription(user.val().type)
            });
          }else{
            this.currentLoggedUser.userName = "Utente";
          }
       });  
    }
  },
  computed:{
    menuSwitchClass() {
        return (this.isUserMenuOpen ? "dropdown-menu show" : "dropdown-menu");
    }
  }
}
</script>