<template>
        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>     

        <div class="row">
            <div class="col-12 col-lg-6">
                <h2>Che cos'è?</h2>
                <p>L'Associazione nazionale felina italiana, in acronimo ANFI, è un'associazione italiana felinofila. Nata nel 1934, 
                    è stata autorizzata alla gestione del libro genealogico del gatto di razza dallo Stato italiano con decreto ministeriale del 9 giugno 2005.</p>
            </div>
            <div class="col-12 col-lg-6 text-center">
                <a href="https://www.anfitalia.it"><img src="https://www.anfitalia.it/site/images/logo.png" alt="enci-logo"></a>
            </div>
        </div>

        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>

        <h2>Le Razze Riconosciute</h2>
        <p>L'ANFI segue il sistema EMS (Easy Mind System), il codice di identificazione delle razze feline elaborato della FIFe, che riconosce 48 razze, suddivise in 4 categorie</p>

        <div class="row">
            <div class="col-12 col-lg-12 table-column">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">Categoria</th>
                        <th scope="col">Razza</th>
                        <th scope="col">Anno di riconoscimento</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Esotico</td>
                            <td>1983</td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Persiano</td>
                            <td>1991</td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Sacro di Birmania</td>
                            <td>1949</td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Turco Van</td>
                            <td>1960</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>American Curl a pelo lungo</td>
                            <td>2003</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>American Curl a pelo corto</td>
                            <td>2003</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>LaPerm a pelo lungo</td>
                            <td>2015</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>LaPerm a pelo corto</td>
                            <td>2015</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Maine Coon</td>
                            <td>1982</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Neva Masquerade</td>
                            <td>2011</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Gatto delle foreste norvegesi</td>
                            <td>1977</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Gatto siberiano</td>
                            <td>1997</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Angora turco</td>
                            <td>1988</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Bengala</td>
                            <td>1999</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>British a pelo lungo</td>
                            <td>2017</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Burmilla</td>
                            <td>1995</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>British shorthair</td>
                            <td>1960/1977/1981</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Burmese</td>
                            <td>1960</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Certosino</td>
                            <td>1949/1977</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Cymric</td>
                            <td>2006</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Europeo</td>
                            <td>1949/1981</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Kurilean Bobtail a pelo lungo</td>
                            <td>2004</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Kurilean Bobtail a pelo corto</td>
                            <td>2004</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Gatto Korat</td>
                            <td>1980</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Gatto dell'Isola di Man</td>
                            <td>1949</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Mau egiziano</td>
                            <td>1992</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Ocicat</td>
                            <td>1992</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Singapura</td>
                            <td>2014</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Snowshoe</td>
                            <td>2004</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Sokoke</td>
                            <td>1994</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Selkirk Rex a pelo lungo</td>
                            <td>2017</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Selkirk Rex a pelo corto</td>
                            <td>2017</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Abissino</td>
                            <td>1949</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Balinese</td>
                            <td>1983</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Cornish Rex</td>
                            <td>1968</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Devon Rex</td>
                            <td>1968</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Don Sphynx</td>
                            <td>2011</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>German Rex</td>
                            <td>1982</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Bobtail giapponese</td>
                            <td>1989</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Oriental a pelo lungo</td>
                            <td>1985</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Oriental a pelo corto</td>
                            <td>1959</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Gatto Peterbald</td>
                            <td>2012</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Blu di Russia</td>
                            <td>1949</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Siamese</td>
                            <td>1949</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Somalo</td>
                            <td>1981</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Sphynx</td>
                            <td>2002</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Thai</td>
                            <td>2017</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-3 mb-3">
            <ins class="adsbygoogle" 
                style="display:block" 
                data-ad-client="ca-pub-5987414288118965" 
                data-ad-slot="4452898670" 
                data-ad-format="auto" 
                data-full-width-responsive="true"></ins>
        </div>
</template>

<script>

    export default {
        name: 'EnciPage',
        components:{
        },
        data() {
            return{
            }
        },
        created: function(){
        },
    }
</script>

<style>
    .ps-card{
        border: none;
        padding: 32px 24px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: none;
    }
    .page-header{
        padding: 251px 20px;
        background-size: cover !important;
        background-position: center !important;
    }
    .page-header h2{
        font-size: 60px;
        line-height: 48px;
        color: #fff;
        text-align: left;
        font-weight: 700;
    }

    .page-body{
        background-color: #edeef0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
    }
    .page-body h2{
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body h4{
        line-height: 36px;
        font-weight: 700;
        text-align: left;
    }
    .page-body a{ 
        color: #b85b2d;
        text-decoration: none;
    }

    .image-quote{
        position: absolute;
        color: #fff;
    }

    html{
        background-color: #edeef0;
    }

    @media (max-width: 768px){
        .page-header h2{
            font-size: 30px;
        }
        .page-header{
            padding: 60px 20px;
        }
        table{
            display: grid;
        }
        .table-column{
            text-align: center;
        }
    }
</style>