import { createRouter, createWebHashHistory } from 'vue-router'
import { FirebaseAuth } from '../firebase';

import Index from '../components/Index.vue'
import AccountIndex from "../components/account/Index.vue";
import DashboardIndex from "../components/dashboard/Index.vue";
import SearchIndex from "../components/search/Index.vue";
import SearchResultPage from "../components/search/SearchResultPage.vue";
import WelcomePage from "../components/dashboard/WelcomePage.vue";
import TermsAndConditionPage from "../components/TermsAndConditionPage.vue";
import CategoryIndexPage from "../components/categories/Index.vue";
import UserListPage from "../components/admin/UserListPage.vue";
import NewsletterListPage from "../components/admin/NewsletterListPage.vue";
import ProfiloPage from "../components/account/ProfiloPage.vue";
import HomePage from '../components/HomePage.vue'
import AccountLoginPage from '../components/account/AccountLoginPage.vue'
import AccountRegisterPage from '../components/account/AccountRegisterPage.vue'
import RecoverPasswordRequestPage from '../components/account/RecoverPasswordPage.vue'
import ConfirmOperationPage from '../components/account/ConfirmOperationPage.vue'
import NotFoundPage from '../components/account/NotFoundPage.vue'

//categories
import PedigreePage from "../components/categories/PedigreePage.vue";
import EnciPage from "../components/categories/EnciPage.vue";
import AnfiPage from "../components/categories/AnfiPage.vue";

const routes = [{
        path: '/',
        name: 'index',
        component: Index,
        children: [{
            path: '',
            name: 'home',
            component: HomePage,
        }]
    },
    {
        path: '/account',
        name: 'account-index',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: AccountIndex,
        children: [{
                path: '',
                name: 'login',
                component: AccountLoginPage,
            },
            {
                path: '/registrati',
                name: 'registrazione',
                component: AccountRegisterPage,
            },
            {
                path: '/ripristino-password-richiesta',
                name: 'ripristino password richiesta',
                component: RecoverPasswordRequestPage,
            },
            {
                path: '/conferma-operazione',
                name: 'ripristino password | conferma email',
                component: ConfirmOperationPage,
            },
            {
                path: '/not-found',
                name: 'not found account page',
                component: NotFoundPage,
            }
        ]
    },
    {
        path: '/dashboard',
        name: 'dashboard-index',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: DashboardIndex,
        children: [{
            path: '',
            name: 'dashboard-home',
            component: WelcomePage,
        }]
    },
    {
        path: '/admin',
        name: 'admin-index',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: DashboardIndex,
        children: [{
                path: 'utenti',
                name: 'users-list',
                component: UserListPage,
            },
            {
                path: 'newsletter',
                name: 'newsletter-list',
                component: NewsletterListPage,
            }
        ]
    },
    {
        path: '/profilo/:id',
        name: 'profilo-index',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: DashboardIndex,
        children: [{
            path: '',
            name: 'edit-profilo',
            component: ProfiloPage,
        }]
    },
    {
        path: '/search',
        name: 'search-index',
        meta: {
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: SearchIndex,
        children: [{
            path: ':id',
            name: 'search-results',
            component: SearchResultPage,
        }]
    },
    {
        path: '/informazioni-legali',
        name: 'legal-info-index',
        meta: {
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: Index,
        children: [{
            path: 'termini-e-condizioni',
            name: 'termini & condizioni',
            component: TermsAndConditionPage,
        }]
    },
    {
        path: '/categories',
        name: 'categories-info',
        meta: {
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () =>
        //    import ( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        component: CategoryIndexPage,
        children: [{
                path: 'pedigree',
                name: 'pedigree',
                component: PedigreePage,
            },
            {
                path: 'enci',
                name: 'enci',
                component: EnciPage,
            },
            {
                path: 'anfi',
                name: 'anfi',
                component: AnfiPage,
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    let currentUser = FirebaseAuth.currentUser;
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) next('/account');
    else next();
});

export default router