export default {
    config: {
        apiKey: "AIzaSyCaxDZidVhcWEb3ANwqXzFS-q212WTr7XI",
        authDomain: "petshelter-5fd27.firebaseapp.com",
        storageBucket: "petshelter-5fd27.appspot.com",
        databaseURL: "https://petshelter-5fd27-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "petshelter-5fd27",
        messagingSenderId: "788210590555",
        appId: "1:788210590555:web:16f13b4f63c4d844bc707e"
    }
}