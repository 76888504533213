<template>
    <div class="container-fluid main-login">
        <div class="row">
            <div class="col-12 col-md-7 img-container d-none d-lg-block">
            </div>
            <div class="col-12 col-md-5 login-form-container">
                <div class="login-form">
                    <a class="navbar-brand" href="/"><img src="../../assets/logo.jpg" height="55" alt="petshelter-logo"/> <span class="logo-span-login">PetShelter</span></a>
                    <!--<div class="wrapper mt-3">
                        <input type="radio" name="select" id="option-1" checked>
                        <input type="radio" name="select" id="option-2">
                        <label for="option-1" class="option option-1 noselect">
                            <mdicon name="key-variant" />
                            <span>Accedi</span>
                        </label>
                        <label for="option-2" class="option option-2 noselect">
                            <mdicon name="account" />
                            <span>Registrati</span>
                        </label>
                    </div>-->
                    <router-view></router-view>
                    <div class="login-footer">
                        <ul class="list-unstyled small text-muted">
                            <li class="mb-2">Made with ❤️ by Simone Besana.</li>
                            <li class="mb-2">©{{new Date().getFullYear()}} Simone Besana. Tutti i diritti riservati.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'AccountIndex',
  components: {
  },
  methods: {
  }
}
</script>

<style>
    .main-login{
        height: 100vh !important;
    }
    .login-form-container{
        display: flex;
        align-items: center;
        overflow-y: scroll;
    }
    .main-login .login-form-container,.img-container{
        height: 100vh !important;
    }
    .logo-span-login{
        font-size: 55px;
        font-family: 'Lobster', cursive;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: middle;
        font-weight: normal;
    }
    .img-container{
        background-color: royalblue;
        background: url(https://images.pexels.com/photos/5270678/pexels-photo-5270678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        transition: 0.3s;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-right: 1px solid #dddddd;
    }

    .login-form{
        max-width: 450px;
        margin: auto;
        position: relative;
        /*height: 860px;*/
        height: inherit;
    }
    .login-footer{
        /*position: absolute;
        bottom: 0;
        left: 0;
        right: 0;*/
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .login-form .btn{
        height: 45px;
        border: 2px solid #b85b2d;
        background-color: #b85b2d;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        border-radius: 22.5px;
        padding: 0px 25px;
        transition: .3s all ease;
        line-height: 41px;
    }
    .login-form .btn:hover,.registration-form .btn:active{
      background-color: transparent;
      color: #b85b2d;
      border: 1px solid #b85b2d;
      border-radius: 20px;
    }
    .login-form .btn:disabled{
      pointer-events: auto;
      cursor: not-allowed;
    }
    .login-form .form-control{
        position: relative;
        outline: 0;
        border-radius: 25px;
        line-height: 45px;
        padding: 0 25px;
        height: 45px;
        width: 100%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }
    .vuejs3-datepicker__value {
        min-width: 200px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        border-radius: 5px;
        padding: 0px !important;
        cursor: pointer;
        border: none !important;
    }
    .vuejs3-datepicker #calendar-div {
        background-color: transparent !important;
        border-radius: 0px !important;
        height: 45px !important;
        border: none !important;
        color: #747474;
        text-align: left;
    }
    .login-form .passwordForget{
        margin-top: 20px;
        color: #b85b2d;
        text-align: center;
    }
</style>